<!-- Header Component (TableHeader.vue) -->
<script setup lang="ts">
import { h, onBeforeUnmount } from 'vue';
import { defineProps } from 'vue';
import { FlexRender } from '@tanstack/vue-table';

// Accept the table model as a prop
// const props = defineProps({
//   table: {
//     type: Object,
//     required: true,
//   },
// });

const columnSizing = defineModel('columnSizing');

const props = defineProps({
  table: {
    type: Object,
    required: true,
  },
});

// Variables for tracking resizing
let resizingColumnId: string | null = null;
let startX = 0;
let startWidth = 0;

// Function to start resizing
const startResize = (event: MouseEvent | TouchEvent, header) => {
  startX = 'clientX' in event ? event.clientX : event.touches[0].clientX;
  startWidth = header.getSize();

  resizingColumnId = header.column.id;

  // Add listeners to track the resizing process
  document.addEventListener('mousemove', handleResize);
  document.addEventListener('touchmove', handleResize);
  document.addEventListener('mouseup', stopResize);
  document.addEventListener('touchend', stopResize);
};

// Function to handle the resize
const handleResize = (event: MouseEvent | TouchEvent) => {
  if (resizingColumnId) {
    const currentX = 'clientX' in event ? event.clientX : event.touches[0].clientX;
    const deltaX = currentX - startX;
    const newWidth = Math.max(startWidth + deltaX, 50); // Ensure a minimum width of 50px

    columnSizing.value[resizingColumnId] = newWidth;
  }
};

// Function to stop resizing
const stopResize = () => {
  resizingColumnId = null;
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
};

// Cleanup listeners
onBeforeUnmount(() => {
  document.removeEventListener('mousemove', handleResize);
  document.removeEventListener('touchmove', handleResize);
  document.removeEventListener('mouseup', stopResize);
  document.removeEventListener('touchend', stopResize);
});
</script>

<template>
  <tr v-for="headerGroup in table.getHeaderGroups()" :key="headerGroup.id" class="">
    <th
      v-for="(header, index) in headerGroup.headers"
      :key="header.id"
      :colSpan="header.colSpan"
      class="px-2 pb-3 mb-2 group relative text-start font-normal size-px text-sm text-gray-500 rounded-md dark:text-neutral-500"
      :style="{ width: header.getSize() + 'px' }"
    >
      <div class="uppercase tracking-wide text-xs" :style="{ width: header.getSize() + 'px' }">
        <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
        <div
          @mousedown="(event) => startResize(event, header)"
          @touchstart.passive="(event) => startResize(event, header)"
          class="border-e border-gray-200 absolute top-0 end-0 w-4 h-6 pb-2"
          :class="{ 'cursor-col-resize': header.column.getCanResize() }"
        ></div>
      </div>
    </th>
  </tr>

  <!--        <div
            class="inline-flex h-full justify-between"
            :style="{ width: header.getSize() + 'px' }"
        >
          <div v-if="!header.isPlaceholder" class="items-center whitespace-nowrap">
            <FlexRender :render="header.column.columnDef.header" :props="header.getContext()"/>
            {{ header.getSize()}}
          </div>
          &lt;!&ndash; Resize handle &ndash;&gt;
          <div
              v-if="header.column.getCanResize()"
              @mousedown="header.getResizeHandler()?.($event)"
              @touchstart="header.getResizeHandler()?.($event)"
              class="border-e border-gray-200 w-2 h-full cursor-col-resize"
          >
          </div>
        </div>-->
</template>
