import { defineStore } from 'pinia';
import { CourseInteraction, Course, Chapter, ChapterInteraction, SectionInteraction } from '@/apiclient';

import { getApiClient } from '@/apiclient/client';
import { getStreamingClient } from '@/apistreamer/streamingclient';
import { useCourseStore } from '@/stores/course.store';
import { useAlertStore } from '@/stores/alert.store';
import { useAuthStore } from '@/stores/auth.store';

export const useCourseInteractionStore = defineStore({
  id: 'courseInteraction',
  state: () => ({
    currentCourseInteractionTree: null as any | null,
    currentChapterIndex: null as number | null,
    numSectionsPerChapter: [] as number[],
    numSectionsStartedPerChapter: [] as number[],
    numSectionsCompletedPerChapter: [] as number[],
    fetchingNumCompleted: 0 as number,
    fetchingNumTotal: 0 as number,
  }),
  getters: {
    isEnrolled: (state) => !!state.currentCourseInteractionTree,
    currentCourseInteractionId: (state) =>
      state.currentCourseInteractionTree ? state.currentCourseInteractionTree.id : null,
    numChaptersCompleted(state) {
      // use of state.currentChapter.sections ensures that only accomplishments of the current chapters of the course
      // are considered. This means that if the course is changed and e.g. a chapter dropped, accomplishments for
      // this chapter will not be counted anymore.
      let counter = 0;
      if (!state.currentCourseInteractionTree) {
        return 0;
      }
      for (let i = 0; i < state.currentCourseInteractionTree.chapterInteractions.length; i++) {
        if (state.currentCourseInteractionTree.chapterInteractions[i]?.completed_at) {
          counter++;
        }
      }
      return counter;
    },
    currentChapterInteraction: (state) => {
      return state.currentChapterIndex != null
        ? state.currentCourseInteractionTree?.chapterInteractions[state.currentChapterIndex]
        : null;
    },
    fetchingProgress: (state) => {
      return state.fetchingNumTotal === 0 ? 0 : state.fetchingNumCompleted / state.fetchingNumTotal;
    },
    fetchCompleted: (state) => {
      return state.currentCourseInteractionTree != null;
    },
    fetchCompletedAndChapterSet: (state) => {
      return state.currentCourseInteractionTree != null && state.currentChapterIndex != null;
    },
  },
  actions: {
    async setChapterInteraction(chapterInteraction: ChapterInteraction) {
      // TODO: refine what has to be loaded/ inited at course load and what at chapter load! (e.g. sectionInteractions and sectionContentItemInteractions)
      throw new Error('Not implemented');
    },
    async setCourseInteraction(courseInteraction: CourseInteraction) {
      let courseStore = useCourseStore();
      if (!courseInteraction) {
        console.log('setCourseInteraction: No course interaction provided');
        return;
      }
      if (!!this.currentCourseInteractionTree && this.currentCourseInteractionTree.id === courseInteraction.id) {
        console.log(
          'CourseInteraction ' + courseInteraction.id + ' already set. No need to rebuild CourseInteraction tree.',
        );
        return this.currentCourseInteractionTree;
      }
      console.debug('Setting new course interaction ' + JSON.stringify(courseInteraction));

      if (!courseStore.currentCourse || courseStore.currentCourse.id !== courseInteraction.course_id) {
        await courseStore.setCurrentCourse(courseInteraction.course_id);
      }
      console.debug(
        'Set course interaction ' + courseInteraction.id + ' for course ' + courseStore.currentCourse?.id + '.',
      );

      let temp = await this._fetchOrCreateIndexedInteractions();

      let indexedSectionContentItemInteractions = temp?.indexedSectionContentItemInteractions;
      let indexedSectionInteractions = temp?.indexedSectionInteractions;
      let indexedChapterInteractions = temp?.indexedChapterInteractions;

      courseInteraction = await this._buildCourseInteractionTree(
        courseInteraction,
        indexedChapterInteractions,
        indexedSectionInteractions,
        indexedSectionContentItemInteractions,
      );

      console.log('courseInteraction', courseInteraction);

      for (let i = 0; i < courseInteraction.chapterInteractions?.length; i++) {
        if (!courseInteraction.chapterInteractions[i]) {
          this.numSectionsPerChapter[i] = 0;
          this.numSectionsStartedPerChapter[i] = 0;
          this.numSectionsCompletedPerChapter[i] = 0;
          continue;
        }
        this.numSectionsPerChapter[i] = courseInteraction.chapterInteractions[i].sectionInteractions.length;
        this.numSectionsStartedPerChapter[i] = 0;
        this.numSectionsCompletedPerChapter[i] = 0;
        for (let j = 0; j < courseInteraction.chapterInteractions[i].sectionInteractions.length; j++) {
          if (!courseInteraction.chapterInteractions[i].sectionInteractions[j]) {
            continue;
          }
          if (courseInteraction.chapterInteractions[i].sectionInteractions[j].started_at) {
            this.numSectionsStartedPerChapter[i] += 1;
          }
          if (courseInteraction.chapterInteractions[i].sectionInteractions[j].completed_at) {
            this.numSectionsCompletedPerChapter[i] += 1;
          }
        }
      }
      this.currentCourseInteractionTree = courseInteraction;
      // TODO computation of this is expensive. We should hold it in the local storage.
      return courseInteraction;
    },
    async _fetchOrCreateIndexedInteractions() {
      let indexedChapterInteractions = [];
      let indexedSectionInteractions = [];
      let indexedSectionContentItemInteractions = [];
      let courseStore = useCourseStore();

      if (!courseStore.currentCourse) {
        console.warn('No course set');
        return;
      }

      this.fetchingNumTotal = courseStore.currentCourse.chapters?.length || 0;

      // for (let chapter of courseStore.currentCourse.chapters || []) {
      //   this.fetchingNumCompleted++;
      //   await this._handleChapterInteraction(chapter, indexedChapterInteractions, indexedSectionInteractions);
      // }

      this.fetchingNumTotal = courseStore.currentCourse.chapters?.length || 0;

      const chapters = courseStore.currentCourse.chapters || [];

      // Temporarily store chapter interactions and section interactions
      const chapterInteractionResults = [];
      const sectionInteractionResults = [];
      const indexedSectionContentItemInteractionsResults = [];

      const chapterPromises = chapters.map(async (chapter) => {
        const indexedChapterInteraction = [];
        const indexedSectionInteraction = [];
        const indexedSectionContentItemInteraction = [];

        await this._handleChapterInteraction(
          chapter,
          indexedChapterInteraction,
          indexedSectionInteraction,
          indexedSectionContentItemInteraction,
        );

        // Collect results instead of directly mutating arrays
        chapterInteractionResults.push(...indexedChapterInteraction);
        sectionInteractionResults.push(...indexedSectionInteraction);
        indexedSectionContentItemInteractionsResults.push(...indexedSectionContentItemInteraction);

        this.fetchingNumCompleted++;
      });

      // Wait for all promises to complete
      await Promise.all(chapterPromises);

      // Safely assign the results after all promises have resolved
      indexedChapterInteractions.push(...chapterInteractionResults);
      indexedSectionInteractions.push(...sectionInteractionResults);
      indexedSectionContentItemInteractions.push(...indexedSectionContentItemInteractionsResults);

      // console.log(JSON.stringify(indexedChapterInteractions));
      // console.log(JSON.stringify(indexedSectionInteractions));

      return {
        indexedChapterInteractions: indexedChapterInteractions,
        indexedSectionInteractions: indexedSectionInteractions,
        indexedSectionContentItemInteractions: indexedSectionContentItemInteractions,
      };
    },
    async _handleChapterInteraction(
      chapter,
      indexedChapterInteractions,
      indexedSectionInteractions,
      indexedSectionContentItemInteractions,
    ) {
      let chapterIndex = chapter.index;
      let chapterInteraction = await (await getApiClient()).chapters.fetchOrCreateChapterInteraction(chapter.id);
      // console.log(JSON.stringify(chapterInteraction));
      indexedChapterInteractions.push({
        chapterIndex: chapterIndex,
        chapterInteraction: chapterInteraction,
      });

      await this._handleSectionInteractions(
        chapter,
        chapterIndex,
        indexedSectionInteractions,
        indexedSectionContentItemInteractions,
      );
    },
    // async _handleSectionInteractions(chapter, chapterIndex, indexedSectionInteractions) {
    //     for (let section of chapter.sections || []) {
    //         let sectionIndex = section.index;
    //         let sectionInteraction = await (await getApiClient()).sections.fetchOrCreateSectionInteraction(section.id);
    //         indexedSectionInteractions.push({
    //             chapterIndex: chapterIndex,
    //             sectionIndex: sectionIndex,
    //             sectionInteraction: sectionInteraction,
    //         });
    //     }
    // },
    async _handleSectionInteractions(
      chapter,
      chapterIndex,
      indexedSectionInteractions,
      indexedSectionContentItemInteractions,
    ) {
      const sections = chapter.sections || [];

      // Create an array of promises for each section interaction
      const sectionPromises = sections.map(async (section) => {
        let sectionIndex = section.index;

        // Fetch or create section interaction
        let sectionInteraction = await (await getApiClient()).sections.fetchOrCreateSectionInteraction(section.id);

        // Push the result after the interaction is fetched
        indexedSectionInteractions.push({
          chapterIndex: chapterIndex,
          sectionIndex: sectionIndex,
          sectionInteraction: sectionInteraction,
        });

        await this._handleSectionContentItemInteractions(
          section,
          chapterIndex,
          sectionIndex,
          indexedSectionContentItemInteractions,
        );
      });

      // Wait for all section interactions to complete in parallel
      await Promise.all(sectionPromises);
    },
    async _handleSectionContentItemInteractions(
      section,
      chapterIndex,
      sectionIndex,
      indexedSectionContentItemInteractions,
    ) {
      const sectionContentItems = section.section_content_items || [];
      // console.log('sectionContentItems', sectionContentItems);

      // Create an array of promises for each section content item interaction
      const sectionContentItemPromises = sectionContentItems.map(async (sectionContentItem) => {
        // console.log('building section content item interaction for section content item id ', sectionContentItem.id);

        let sectionContentItemIndex = sectionContentItem.index;

        // Fetch or create section content item interaction
        let sectionContentItemInteraction = await (
          await getApiClient()
        ).courseSectionItems.fetchOrCreateSectionContentItemInteraction(sectionContentItem.id);

        // Push the result after the interaction is fetched
        indexedSectionContentItemInteractions.push({
          chapterIndex: chapterIndex,
          sectionIndex: sectionIndex,
          sectionContentItemIndex: sectionContentItemIndex,
          sectionContentItemInteraction: sectionContentItemInteraction,
        });
      });

      // Wait for all section content item interactions to complete in parallel
      await Promise.all(sectionContentItemPromises);
    },
    async _buildCourseInteractionTree(
      courseInteraction: CourseInteraction,
      chapterInteractions: any[],
      sectionInteractions: any[],
      indexedSectionContentItemInteractions: any[],
    ) {
      let courseStore = useCourseStore();
      let course = courseStore.currentCourse;
      if (!course) {
        console.warn('No course set');
        return;
      }
      let courseInteractionTree = {
        ...courseInteraction,
        chapterInteractions: [],
      };
      // Map chapters to the tree
      chapterInteractions.forEach((chapter) => {
        const chapterIndex = chapter.chapterIndex;
        if (!courseInteractionTree.chapterInteractions[chapterIndex]) {
          courseInteractionTree.chapterInteractions[chapterIndex] = {
            ...chapter.chapterInteraction,
            sectionInteractions: [],
          };
        }
      });
      // Map sections to the corresponding chapters in the tree
      sectionInteractions.forEach((section) => {
        const chapterIndex = section.chapterIndex;
        const sectionIndex = section.sectionIndex;

        if (!courseInteractionTree.chapterInteractions[chapterIndex].sectionInteractions[sectionIndex]) {
          courseInteractionTree.chapterInteractions[chapterIndex].sectionInteractions[sectionIndex] = {
            ...section.sectionInteraction,
          };
        }
      });
      indexedSectionContentItemInteractions.forEach((sectionContentItemInteraction) => {
        const chapterIndex = sectionContentItemInteraction.chapterIndex;
        const sectionIndex = sectionContentItemInteraction.sectionIndex;
        const sectionContentItemIndex = sectionContentItemInteraction.sectionContentItemIndex;

        if (
          !courseInteractionTree.chapterInteractions[chapterIndex].sectionInteractions[sectionIndex]
            .sectionContentItemInteractions
        ) {
          courseInteractionTree.chapterInteractions[chapterIndex].sectionInteractions[
            sectionIndex
          ].sectionContentItemInteractions = [];
        }

        courseInteractionTree.chapterInteractions[chapterIndex].sectionInteractions[
          sectionIndex
        ].sectionContentItemInteractions[sectionContentItemIndex] = {
          ...sectionContentItemInteraction.sectionContentItemInteraction,
        };
      });

      return courseInteractionTree;
    },
    async setCourseInteractionForCurrentCourseIfEnrolled() {
      let courseStore = useCourseStore();
      if (!courseStore.currentCourse) {
        console.warn('No current course set');
        return;
      }
      let courseInteraction = await (
        await getApiClient()
      ).courses.tryGetCourseInteraction(courseStore.currentCourse.id);
      if (!courseInteraction) {
        console.log('Not enrolled for course ' + courseStore.currentCourse.id);
        await this.reset();
        return;
      }
      if (!!this.currentCourseInteractionTree && this.currentCourseInteractionTree.id !== courseInteraction.id) {
        console.log('Course interaction tree for other course found. Reset and load new tree.');
        await this.reset();
      }
      console.log(
        'Found course interaction ' + courseInteraction.id + ' for course ' + courseStore.currentCourse.id + '.',
      );
      return await this.setCourseInteraction(courseInteraction);
    },
    async enrollForCurrentCourse(t: any = null) {
      let courseStore = useCourseStore();
      if (this.isEnrolled) {
        return;
      }
      if (!courseStore.currentCourse?.id) {
        return;
      }
      let response = null;
      let courseInteraction = await (
        await getApiClient()
      ).courses.tryGetCourseInteraction(courseStore.currentCourse.id);
      if (!courseInteraction) {
        response = await (await getApiClient()).courses.createCourseInteraction(courseStore.currentCourse.id);
        courseInteraction = response['course_interaction'];
      }
      await this.setCourseInteraction(courseInteraction);

      let authStore = useAuthStore();
      if (!!response && !!response['notifications'] && !!t) {
        const alertStore = useAlertStore();
        for (const notification of response['notifications']) {
          if (notification.type === 'XP') {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          }
        }
      }
      await authStore.fetchUserXp(); // update xp
    },
    // async setCourseInteractionById(id: string) {
    //   // fetch patient interaction from API
    //   const courseInteraction = await (await getApiClient()).courseInteractions.getCourseInteraction(id, true);
    //   await this.setCourseInteraction(courseInteraction);
    // },
    async startChapterByIndex(index: number) {
      let courseStore = useCourseStore();
      let courseInteractionStore = useCourseInteractionStore();
      if (!courseInteractionStore.currentCourseInteractionTree || !courseStore.currentCourse) {
        const alertStore = useAlertStore();
        alertStore.error('Kein Kurs oder nicht eingeschrieben');
        console.warn('No current course interaction or course set');
        console.log('currentCourseInteraction', courseInteractionStore.currentCourseInteractionTree);
        console.log('currentCourse', courseStore.currentCourse);
        return;
      }
      this.currentChapterIndex = index;
      courseInteractionStore = useCourseInteractionStore();
      await courseStore.setCurrentChapterByIndex(index);

      console.log(JSON.stringify(courseInteractionStore.currentCourseInteractionTree));
      let chapterInteraction = courseInteractionStore.currentCourseInteractionTree?.chapterInteractions[index];
      console.log('chapterInteraction', chapterInteraction);
      console.log('chapterInteraction.started_at', chapterInteraction?.started_at);
      if (!chapterInteraction.started_at) {
        chapterInteraction.started_at = new Date().toISOString();
        await (
          await getApiClient()
        ).chapterInteractions.updateChapterInteraction(chapterInteraction.id, chapterInteraction);
      }
      await this.startSectionIfNotAlready(0);
    },
    async startSectionIfNotAlready(sectionIndex: number) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      console.log('currentChapterInteraction', courseInteractionStore.currentChapterInteraction);
      console.log('section interactions', courseInteractionStore.currentChapterInteraction?.sectionInteractions);
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      console.log('sectionInteraction', sectionInteraction);
      console.log('sectionInteraction.started_at', sectionInteraction?.started_at);
      if (!!sectionInteraction?.started_at) {
        // already started
        return;
      }
      this.numSectionsStartedPerChapter[courseInteractionStore.currentChapterIndex] += 1;
      sectionInteraction.started_at = new Date().toISOString();
      await (
        await getApiClient()
      ).sectionInteractions.updateSectionInteraction(sectionInteraction.id, sectionInteraction);
    },
    async startSectionContentItemIfNotAlready(sectionIndex: number, sectionContentItemId: string) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      let sectionContentItemInteraction = sectionInteraction.sectionContentItemInteractions.find(
        (sci) => sci.section_content_item_id === sectionContentItemId,
      );
      if (sectionContentItemInteraction.started_at != null) {
        // already started
        return;
      }
      sectionContentItemInteraction.started_at = new Date().toISOString();
      return (await getApiClient()).sectionItemInteractions.updateSectionContentItemInteraction(
        sectionContentItemInteraction.id,
        sectionContentItemInteraction,
      );
    },
    async evaluateFormStructuredExercise(sectionIndex: number, sectionContentItemId: string, exerciseData: any) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      let sectionContentItemInteraction = sectionInteraction.sectionContentItemInteractions.find(
        (sci) => sci.section_content_item_id === sectionContentItemId,
      );
      return (await getApiClient()).sectionItemInteractions.evaluateFormStructuredExerciseItem(
        sectionContentItemInteraction.id,
        exerciseData,
      );
    },
    async evaluateMcQuestion(
      sectionIndex: number,
      sectionContentItemId: string,
      mcQuestionId: string,
      answers: Object[],
    ) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      let sectionContentItemInteraction = sectionInteraction.sectionContentItemInteractions.find(
        (sci) => sci.section_content_item_id === sectionContentItemId,
      );
      console.log(answers);
      return (await getApiClient()).sectionItemInteractions.evaluateMcQuestion(sectionContentItemInteraction.id, {
        mc_question_id: mcQuestionId,
        answers: answers,
      });
    },
    async evaluateOpenQuestion(
      sectionIndex: number,
      sectionContentItemId: string,
      resourceId: string,
      questionId: string,
      answer: string,
    ) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      let sectionContentItemInteraction = sectionInteraction.sectionContentItemInteractions.find(
        (sci) => sci.section_content_item_id === sectionContentItemId,
      );
      return (await getApiClient()).sectionItemInteractions.evaluateOpenQuestion(sectionContentItemInteraction.id, {
        resource_with_questions_id: resourceId,
        open_question_id: questionId,
        answer: answer,
      });
    },
    async setSectionContentItemCompletedIfNotAlready(
      sectionIndex: number,
      sectionContentItemId: string,
      forceReopen: boolean = false,
    ) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      let sectionContentItemInteraction = sectionInteraction.sectionContentItemInteractions.find(
        (sci) => sci.section_content_item_id === sectionContentItemId,
      );
      console.log(sectionContentItemInteraction);
      if (
        sectionContentItemInteraction.completed_at != null &&
        sectionContentItemInteraction.reopened_at == null &&
        !forceReopen
      ) {
        // already completed and not reopened
        console.log('Already completed');
        return;
      }
      sectionContentItemInteraction.completed_at = new Date().toISOString();
      sectionContentItemInteraction.reopened_at = null;
      console.log('send completed');
      return (await getApiClient()).sectionItemInteractions.updateSectionContentItemInteraction(
        sectionContentItemInteraction.id,
        sectionContentItemInteraction,
      );
    },
    async getSectionContentItemInteractionState(sectionIndex: number, sectionContentItemId: string) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      return sectionInteraction.sectionContentItemInteractions.find(
        (sci) => sci.section_content_item_id === sectionContentItemId,
      );
    },
    async reopenSectionContentItem(sectionIndex: number, sectionContentItemId: string) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      let sectionContentItemInteraction = sectionInteraction.sectionContentItemInteractions.find(
        (sci) => sci.section_content_item_id === sectionContentItemId,
      );
      if (sectionContentItemInteraction.completed_at == null || sectionContentItemInteraction.reopened_at != null) {
        // not completed or already reopened
        return;
      }
      sectionContentItemInteraction.reopened_at = new Date().toISOString();
      return sectionContentItemInteraction;
    },
    async markSectionCompleted(sectionIndex: number, t: any = null) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      if (!!sectionInteraction?.completed_at) {
        return; // already completed
      } else {
        sectionInteraction.completed_at = new Date().toISOString();
        this.numSectionsCompletedPerChapter[courseInteractionStore.currentChapterIndex] += 1;
      }
      let response = await (
        await getApiClient()
      ).sectionInteractions.updateSectionInteraction(sectionInteraction.id, sectionInteraction, true);
      await this.checkCurrentChapterCompletion(t);
      let newSectionInteraction = response['section_interaction'];
      // start next section, if any
      if (
        newSectionInteraction.completed_at &&
        sectionIndex < courseInteractionStore.currentChapterInteraction?.sectionInteractions?.length - 1
      ) {
        await this.startSectionIfNotAlready(sectionIndex + 1);
      }

      let authStore = useAuthStore();
      if (!!response['notifications'] && !!t) {
        const alertStore = useAlertStore();
        for (const notification of response['notifications']) {
          if (notification.type === 'XP') {
            alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
          }
        }
      }
      await authStore.fetchUserXp(); // update xp
    },
    async checkCurrentChapterCompletion(t: any = null) {
      let courseInteractionStore = useCourseInteractionStore();
      if (courseInteractionStore.currentChapterIndex == null) {
        console.warn('No current chapter set');
        return;
      }
      let chapterInteraction = courseInteractionStore.currentChapterInteraction;
      if (!chapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      let chapterCompleted = true;
      for (let sectionInteraction of chapterInteraction.sectionInteractions) {
        if (!sectionInteraction) {
          console.warn('Null section interaction for chapter interaction: ', JSON.stringify(chapterInteraction));
          continue;
        }
        if (!sectionInteraction.completed_at) {
          console.log('Section not completed: ', JSON.stringify(sectionInteraction));
          chapterCompleted = false;
          break;
        }
      }
      if (chapterCompleted) {
        console.log('Chapter completed');
        chapterInteraction.completed_at = new Date().toISOString();
        let response = await (
          await getApiClient()
        ).chapterInteractions.updateChapterInteraction(chapterInteraction.id, chapterInteraction);
        let authStore = useAuthStore();
        if (!!response['notifications'] && !!t) {
          const alertStore = useAlertStore();
          for (const notification of response['notifications']) {
            if (notification.type === 'XP') {
              alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
            }
          }
        }
        await authStore.fetchUserXp(); // update xp
        return;
      }
      chapterInteraction.completed_at = null;
      await (
        await getApiClient()
      ).chapterInteractions.updateChapterInteraction(chapterInteraction.id, chapterInteraction);
    },
    async trackFirstCaseStarted(sectionIndex: number) {
      let courseInteractionStore = useCourseInteractionStore();
      let sectionInteraction = courseInteractionStore.currentChapterInteraction?.sectionInteractions[sectionIndex];
      if (!sectionInteraction) {
        console.warn('Section interaction not found for section index ' + sectionIndex);
        return;
      }
      if (!courseInteractionStore.currentChapterInteraction) {
        console.warn('No current chapter interaction found');
        return;
      }
      if (!!sectionInteraction?.first_case_started_at) {
        return;
      }
      sectionInteraction.first_case_started_at = new Date().toISOString();
      await (
        await getApiClient()
      ).sectionInteractions.updateSectionInteraction(sectionInteraction.id, sectionInteraction);
    },
    sectionStarted(sectionIndex: number) {
      return this.currentChapterInteraction?.sectionInteractions[sectionIndex].started_at != null;
    },
    sectionCompleted(sectionIndex: number) {
      return this.currentChapterInteraction?.sectionInteractions[sectionIndex].completed_at != null;
    },
    sectionFirstCaseStarted(sectionIndex: number) {
      return this.currentChapterInteraction?.sectionInteractions[sectionIndex].first_case_started_at != null;
    },
    async reset() {
      this.currentCourseInteractionTree = null;
      this.currentChapterIndex = null;
      this.numSectionsPerChapter = [];
      this.numSectionsStartedPerChapter = [];
      this.numSectionsCompletedPerChapter = [];
      this.fetchingNumTotal = 0;
      this.fetchingNumCompleted = 0;
    },
  },
});
