<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { router } from '@/router';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';
import BreadcrumbElement from '@/components/breadcrumbs/BreadcrumbElement.vue';
import { debounce } from 'lodash';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { headingToId } from '@/helper';
import CoursePage from '@/views/courses/CoursePage.vue';
import BreadcrumbSeparator from '@/components/breadcrumbs/BreadcrumbSeparator.vue';
import EditCourseSectionHeader from '@/views/courses/EditCourseSectionHeader.vue';
import EditCoursePage from '@/views/courses/EditCoursePage.vue';
import { HSDropdown } from 'preline';
import ChapterBreadcrumbElementWithDropdown from '@/components/breadcrumbs/ChapterBreadcrumbElementWithDropdown.vue';
import SectionBreadcrumbElementWithDropdown from '@/components/breadcrumbs/SectionBreadcrumbElementWithDropdown.vue';

const courseStore = useCourseStore();
const { currentCourse, currentChapter, currentChapterSortedSections, currentChapterTitle } = storeToRefs(courseStore);
const authStore = useAuthStore();
const { userId, isAdmin: userIsAdmin } = storeToRefs(authStore);

const courseInteractionStore = useCourseInteractionStore();

const alertStore = useAlertStore();
const isEditing = ref(false);
const isLoading = ref(false);
const innerHeader = ref(null);
const sectionId = ref('');
const pageTransitionName = ref('slide-right');
const sectionTransitionName = ref('slide-right');
const appendDummyPage = ref(false);

const chapterId = ref('');
const fullWidth = ref(1024);
const sectionScrollContainer = ref(null);
const coursePageLoaded = ref(false);
const currentScrollHeight = ref(0);
const previousScrollHeight = ref(0);
const headerHeightReducedBy = ref(0);
const currentlyViewingPageWithIndex = ref(0);
const addingNewPage = ref(false);

const showNative = ref(false);
const someDropdownOpen = ref(false);

const headerIsCollapsed = ref(false);
const headerFollowsComputedCollapseTimeout = ref(0);

const props = defineProps({
  outerHeaderHeight: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['transition-direction']);

const currentSection = computed(() => {
  return currentChapterSortedSections.value.find((section) => section.id === sectionId.value);
});

type SectionContentItem = {
  page_index: number; // Replace with the actual type or key that corresponds to 'page_index'
  [key: string]: any; // Represents other properties in the object
};

function sectionContentItemsByPage(sectionContentItems: SectionContentItem[]): SectionContentItem[][] {
  const groupedItems: { [key: number]: SectionContentItem[] } = {};

  sectionContentItems.forEach((item) => {
    if (!groupedItems[item.page_index]) {
      groupedItems[item.page_index] = [];
    }
    groupedItems[item.page_index].push(item);
  });

  return Object.values(groupedItems);
}

const currentSectionPages = computed(() => {
  if (!currentSection.value) {
    return null;
  }
  let pages = sectionContentItemsByPage(currentSection.value.section_content_items || []);
  if (appendDummyPage.value) {
    pages.push([]);
  }
  return pages;
});

const innerHeaderHeight = computed(() => {
  return innerHeader.value ? innerHeader.value.offsetHeight : 0;
});

const currentCourseTitleShortened = computed(() => {
  return currentCourse.value.title.length > 12
    ? currentCourse.value.title.substring(0, 12) + '...'
    : currentCourse.value.title;
});

const currentChapterTitleShortened = computed(() => {
  return currentChapter.value.title.length > 10
    ? currentChapter.value.title.substring(0, 10) + '...'
    : currentChapter.value.title;
});

const currentSectionTitleShortened = computed(() => {
  if (!currentSection.value) {
    return '';
  }
  return currentSection.value.title.length > 20
    ? currentSection.value.title.substring(0, 20) + '...'
    : currentSection.value.title;
});

const currentSectionActivePageTitleShortened = computed(() => {
  // TODO implement
  return '';
});

const currentChapterSortedVisibleSections = computed(() => {
  if (isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return currentChapterSortedSections.value;
  }
  let visible = currentChapterSortedSections.value.filter((section) => section.published_at != null);
  console.log('#Visible sections: ' + visible.length);
  return visible;
});

const getWidth = () => {
  const screenWidth = window.innerWidth;
  console.log('screenWidth: ' + screenWidth);
  if (screenWidth < 640) {
    fullWidth.value = screenWidth;
    return;
  }
  let factor = showNative.value ? 0.9 : 0.75;
  fullWidth.value = Math.round(screenWidth * factor);
};

const debounceGetWidth = debounce(getWidth, 100);

watch(showNative, () => {
  getWidth();
});

const mountingHelper = async () => {
  if (!storeLoaded.value) {
    // go one step back
    router.back();
    return;
  }

  // get editing state from local storage
  let courseId = courseStore.currentCourse.id;
  const savedState = localStorage.getItem(`course-editing-${courseId}`);
  if (savedState !== null && isOwnerOrEditorOfParentCourseOrAdmin.value) {
    isEditing.value = JSON.parse(savedState); // Restore the saved state if edit permissions
  }

  isLoading.value = false;

  await nextTick();
  HSDropdown.autoInit();
};

onBeforeMount(() => {
  sectionId.value = router.currentRoute.value.params.sectionId;
});

onMounted(async () => {
  isLoading.value = true;
  await nextTick(async () => {
    await mountingHelper();
  });

  getWidth();
  window.addEventListener('resize', debounceGetWidth);

  const dvhSupported = window.CSS?.supports?.('height: 100dvh');
  const root = document.documentElement;

  console.log('dvhSupported: ', dvhSupported);

  if (dvhSupported) {
    root.style.setProperty('--fallback-viewport-height', '100dvh');
  }

  emit('transition-direction', ''); // reset router transition
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', debounceGetWidth);
});

const pageId = () => {
  return 'chapter-' + courseStore.currentChapterId;
};

const pageHeading = () => {
  return courseStore.currentChapterTitle;
};

const hashtagedId = () => {
  return '#' + pageId();
};

const storeLoaded = computed(() => {
  return currentCourse.value !== null && currentChapter.value !== null && currentChapterSortedSections.value !== null;
});

const isOwnerOrEditorOfParentCourseOrAdmin = computed(() => {
  return (
    currentCourse.value &&
    (courseStore.isOwnerOfCurrentCourse(userId.value) ||
      courseStore.isEditorOfCurrentCourse(userId.value) ||
      userIsAdmin.value)
  );
});

function scrollToSection(sectionTitle: string) {
  // TODO this needs to become scrollToPage !
  console.log('Scrolling to section: ' + sectionTitle);
  let id = headingToId(sectionTitle);
  const element = document.getElementById(id);
  console.log('Element: ' + element);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}

const goToChapter = async (chapterIndex: number) => {
  await router.isReady();
  await router.push('/chapter/' + courseStore.currentCourse.chapters[chapterIndex].id);
};

const goToSection = async (index: number) => {
  if (index === currentSection.value.index) {
    return;
  }
  await router.isReady();
  // Determine the transition direction
  const direction = index < currentSection.value.index ? 'slide-right' : 'slide-left';
  // Emit the direction to the parent
  emit('transition-direction', direction);
  await router.push('/section/' + courseStore.currentChapterSortedSections[index].id);
};

const goToPage = async (newPageIndex: number) => {
  if (newPageIndex < 0 || newPageIndex >= currentSectionPages.value.length) {
    return;
  }
  if (newPageIndex === currentlyViewingPageWithIndex.value) {
    return;
  }
  currentlyViewingPageWithIndex.value = newPageIndex;
};

watch(
  () => currentlyViewingPageWithIndex.value,
  (newIndex, oldIndex) => {
    pageTransitionName.value = newIndex > oldIndex ? 'slide-left' : 'slide-right';
  },
);

const toggleIsEditing = (isEditingNow: boolean) => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  isEditing.value = isEditingNow;
  localStorage.setItem(`course-editing-${courseStore.currentCourse.id}`, JSON.stringify(isEditingNow));
};

const addPage = async () => {
  if (!isOwnerOrEditorOfParentCourseOrAdmin.value) {
    return;
  }
  if (!storeLoaded.value) {
    return;
  }
  addingNewPage.value = true;
  appendDummyPage.value = true;
  setTimeout(() => {
    const lastPage = currentSectionPages.value.length - 1;
    goToPage(lastPage);
  }, 200);
};

const handleCoursePageLoaded = async (pageIndex: number, loaded: boolean, hasContent: boolean) => {
  if (!loaded || !sectionScrollContainer.value) return;

  if (pageIndex === currentlyViewingPageWithIndex.value) {
    coursePageLoaded.value = true;
  }

  addingNewPage.value = !hasContent;
  console.log('newPage: ' + addingNewPage.value);

  if (!hasContent) {
    // new page added
    setTimeout(() => {
      scrollToBottom();
    }, 700); // delay: let slide animation finish first
    // new section added & load complete => scroll to bottom
    return;
  } else if (!!currentSectionPages.value && pageIndex === currentSectionPages.value.length - 1) {
    addingNewPage.value = false; // last page has content, so adding new page is finished
    appendDummyPage.value = false;
    return;
  }

  await nextTick();
  sectionScrollContainer.value.scrollTop = 0; // Keep scroll at the top if switching pages and between edit/non-edit mode
};

watch(
  () => currentSectionPages.value?.length,
  (newLength, oldLength) => {
    if (newLength > oldLength && !addingNewPage.value) {
      appendDummyPage.value = false;
    }
  },
);

const scrollToBottom = () => {
  if (!sectionScrollContainer.value) return;
  console.log('scrolling to bottom');
  sectionScrollContainer.value.scroll({
    top: sectionScrollContainer.value.scrollHeight,
    behavior: 'smooth',
  });
};

const editCourseSectionHeader = ref(null);

const minHeaderHeight = computed(() => {
  if (!editCourseSectionHeader.value?.minHeight) return 64;
  return editCourseSectionHeader.value.minHeight;
});

const collapsableHeaderHeight = computed(() => {
  if (!editCourseSectionHeader.value?.fullHeight) return null;
  return editCourseSectionHeader.value.fullHeight - minHeaderHeight.value;
});

const computedHeaderHeight = computed(() => {
  if (!editCourseSectionHeader.value?.fullHeight) return null;
  if (!collapsableHeaderHeight.value) return null;

  const fullHeight = editCourseSectionHeader.value.fullHeight;

  // Start collapsing only after scrolling past the difference
  const collapsibleHeight = fullHeight - minHeaderHeight.value;
  const remainingHeight =
    minHeaderHeight.value + Math.max(0, collapsableHeaderHeight.value - headerHeightReducedBy.value);

  return remainingHeight;
});

watch(computedHeaderHeight, (newHeight) => {});

const computedStyleScrollContainer = computed(() => {
  return {
    flex: '1 1 auto',
    overflowY: 'auto',
    minHeight: 0,
  };
});

const computedStyleMain = computed(() => {
  return {
    height: `calc(100vh - ${props.outerHeaderHeight}px)`,
  };
});

const headerShallCollapse = computed(() => {
  return computedHeaderHeight.value === minHeaderHeight.value;
});

// hysteresis: headerisCollapsed changes if headerShallCollapse is the same for 50 ms
watch(
  () => headerShallCollapse.value,
  (newVal) => {
    // if (headerFollowsComputedCollapseTimeout.value) {
    //   clearTimeout(headerFollowsComputedCollapseTimeout.value);
    // }
    // headerFollowsComputedCollapseTimeout.value = setTimeout(() => {
    //   headerIsCollapsed.value = newVal;
    // }, 50);
    headerIsCollapsed.value = newVal; // directly adjust w/o hysteresis
  },
);

const headerIsAtFullHeight = computed(() => {
  return computedHeaderHeight.value === editCourseSectionHeader.value.fullHeight - 30;
});

// const handleScroll = (event: Event) => {
//   if (!sectionScrollContainer.value) return;
//   if (!coursePageLoaded.value) return;
//   let currentScroll = sectionScrollContainer.value.scrollTop;
//   console.log(sectionScrollContainer.value.scrollTop);
//   const scrollDelta = currentScroll - previousScrollHeight.value;

//   if (scrollDelta > 0 && !headerShallCollapse.value && !screenIsMdOrLarger.value) {
//     // dont scroll but collapse header
//     currentScroll = 5;
//     sectionScrollContainer.value.scrollTop = 5;
//     headerHeightReducedBy.value += scrollDelta;
//   } else if (scrollDelta < 0 && currentScroll < 10 && !headerIsAtFullHeight.value && !screenIsMdOrLarger.value) {
//     // dont scroll but expand header
//     headerHeightReducedBy.value = Math.max(0, headerHeightReducedBy.value - Math.abs(scrollDelta));
//     sectionScrollContainer.value.scrollTop = 5;
//     currentScroll = 5;
//   }

//   currentScrollHeight.value = currentScroll;
//   previousScrollHeight.value = currentScroll;
// };

const screenIsMdOrLarger = computed(() => {
  return window.innerWidth > 768;
});

const lastScrollAmount = ref(0);

const handleScrollBy = async (distance: number) => {
  let scrollPositionBefore = sectionScrollContainer.value?.scrollTop;
  sectionScrollContainer.value?.scrollBy(0, distance);
  sectionScrollContainer.value?.addEventListener(
    'scrollend',
    () => {
      let scrollPositionAfter = sectionScrollContainer.value?.scrollTop;
      lastScrollAmount.value = scrollPositionAfter - scrollPositionBefore;
    },
    { once: true },
  );
};

const handleUndoLastScroll = () => {
  if (lastScrollAmount.value) {
    sectionScrollContainer.value?.scrollBy(0, -lastScrollAmount.value);
    lastScrollAmount.value = 0;
  }
};

// refs and constants for handling header expand/collapse by dragging and scrolling
const isDraggingHeader = ref(false);
const startY = ref(0);
const currentY = ref(0);
const HEADER_DRAG_THRESHOLD = 5;
const WHEEL_SENSITIVITY = 0.5;

// Replace handleScroll with these event handlers
const handleWheel = (event: WheelEvent) => {
  if (!coursePageLoaded.value || screenIsMdOrLarger.value) return;

  const deltaY = event.deltaY * WHEEL_SENSITIVITY;

  if (deltaY > 0 && !headerIsCollapsed.value) {
    // Scrolling down - collapse header first
    event.preventDefault();
    headerHeightReducedBy.value = Math.min(collapsableHeaderHeight.value || 0, headerHeightReducedBy.value + deltaY);
  } else if (deltaY < 0 && sectionScrollContainer.value?.scrollTop === 0) {
    // Scrolling up at top - expand header
    event.preventDefault();
    headerHeightReducedBy.value = Math.max(0, headerHeightReducedBy.value + deltaY);
  }
  // neither nor, so let event pass through (=scroll happens)
};

const handleTouchStart = (event: TouchEvent) => {
  if (!coursePageLoaded.value || screenIsMdOrLarger.value) return;
  startY.value = event.touches[0].clientY;
  currentY.value = startY.value;
  isDraggingHeader.value = true;
};

const handleTouchMove = (event: TouchEvent) => {
  // TODO not yet seamlessly transitions between scrolling and collapsing/expanding header. Have to re-start dragging to switch between them.
  // TODO improve this
  // TODO also try to mimick the scroll behavior of the native touches, i.e. continue over touchmove if quick enough
  if (!isDraggingHeader.value) return;

  currentY.value = event.touches[0].clientY;
  const deltaY = startY.value - currentY.value;

  if (!headerIsCollapsed.value) {
    event.preventDefault();

    // Calculate new header height
    const newHeaderHeight = Math.min(collapsableHeaderHeight.value || 0, headerHeightReducedBy.value + deltaY);

    // Calculate excess movement that should become scroll
    const excess = deltaY - (collapsableHeaderHeight.value || 0 - headerHeightReducedBy.value);

    // Apply header collapse
    headerHeightReducedBy.value = newHeaderHeight;

    // If we have excess movement and header is now fully collapsed, convert to scroll
    if (excess > 0 && headerIsCollapsed.value && sectionScrollContainer.value) {
      // TODO this does not work
      sectionScrollContainer.value.scrollTop = excess;
    }
  } else if (sectionScrollContainer.value?.scrollTop === 0 && deltaY < -HEADER_DRAG_THRESHOLD) {
    // At top and pulling down - expand header
    // TODO need to consider if scrolling but pulling over the top (seamlessly start expanding header)
    event.preventDefault();
    headerHeightReducedBy.value = Math.max(0, headerHeightReducedBy.value + deltaY);
  }

  startY.value = currentY.value;
};

const handleTouchEnd = () => {
  isDraggingHeader.value = false;
};
</script>

<template>
  <transition :name="sectionTransitionName" :mode="'out-in'">
    <div class="flex flex-col min-w-full overflow-hidden" :style="computedStyleMain">
      <header class="flex min-w-full overflow-visible">
        <!-- Navigation -->
        <h2
          ref="innerHeader"
          :class="{
            'overflow-visible': someDropdownOpen,
            'overflow-y-visible overflow-x-scroll': !someDropdownOpen,
          }"
          class="w-full sticky top-0 z-40 text-base text-gray-800 dark:text-gray-200 bg-white dark:bg-neutral-900 pb-4 justify-center flex"
        >
          <div class="w-full mx-auto px-2 md:px-6 lg:px-8 inline-flex justify-between">
            <ol class="inline-flex overflow-visible items-center whitespace-nowrap w-full">
              <!-- level 1 breadcrump: all courses -->
              <BreadcrumbElement class="hidden md:inline-flex" label="Kurse" to="/home" />
              <!-- level 2 breadcrump: current course -->
              <BreadcrumbElement
                class="hidden md:inline-flex"
                :label="currentCourse.title"
                :to="'/course/' + currentCourse.id"
              />
              <BreadcrumbElement
                class="inline-flex md:hidden"
                :label="currentCourseTitleShortened"
                :to="'/course/' + currentCourse.id"
              />
              <!-- level 3 breadcump: current chapter -->
              <ChapterBreadcrumbElementWithDropdown
                :current-chapter="currentChapter"
                :current-chapter-title="currentChapterTitle"
                :current-chapter-title-shortened="currentChapterTitleShortened"
                :chapters="currentCourse.chapters"
                id-prefix="course-section"
                @dropdownOpen="someDropdownOpen = $event"
                @goToChapter="goToChapter"
              />
              <BreadcrumbSeparator />

              <!-- level 4 breadcump: current section -->
              <SectionBreadcrumbElementWithDropdown
                :current-section="currentSection"
                :current-section-title="currentSection.title"
                :current-section-title-shortened="currentSectionTitleShortened"
                :sections="currentChapterSortedVisibleSections"
                id-prefix="course-section"
                @goToSection="goToSection"
                @dropdownOpen="someDropdownOpen = $event"
              />
            </ol>
          </div>
        </h2>
      </header>

      <main
        ref="sectionScrollContainer"
        v-if="storeLoaded"
        :id="pageId()"
        class="flex flex-col min-w-full pb-4 scroll-smooth"
        :style="computedStyleScrollContainer"
        @wheel="handleWheel"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
      >
        <div
          class="flex z-30 mx-auto transition-[width] duration-500"
          :style="{
            height: computedHeaderHeight ? computedHeaderHeight + 'px' : 'auto',
            position: headerIsCollapsed ? 'fixed' : 'static',
            width: `${fullWidth}px`,
          }"
        >
          <EditCourseSectionHeader
            ref="editCourseSectionHeader"
            :chapter="currentChapter"
            :section="currentSection"
            :computed-height="computedHeaderHeight"
            :numVisibleSections="currentChapterSortedVisibleSections.length"
            :allow-editing="isEditing"
            :allow-toggle-editing="isOwnerOrEditorOfParentCourseOrAdmin"
            @onToggleIsEditing="toggleIsEditing"
            @onAddPage="addPage"
            @scrollTo="(sectionTitle) => scrollToSection(sectionTitle)"
            @toPreviousSection="goToSection(currentSection.index - 1)"
            @toNextSection="goToSection(currentSection.index + 1)"
            @toPreviousPage="goToPage(currentlyViewingPageWithIndex - 1)"
            @toNextPage="goToPage(currentlyViewingPageWithIndex + 1)"
            :pageIndex="currentlyViewingPageWithIndex"
            :numPages="currentSectionPages?.length"
          />
        </div>

        <div>
          <div
            class="w-full"
            :style="{
              height: headerShallCollapse ? minHeaderHeight + 'px' : '0px',
            }"
          ></div>
        </div>

        <div
          v-for="(page, pageIndex) in currentSectionPages"
          :key="`page-${pageIndex}-${page[0]?.id || ''}`"
          class="relative"
        >
          <transition :name="pageTransitionName" :mode="'out-in'">
            <div v-show="pageIndex === currentlyViewingPageWithIndex" class="pt-4">
              <EditCoursePage
                v-if="isEditing"
                :pageContents="page"
                :pageIndex="pageIndex"
                :sectionIndex="currentSection.index"
                :sectionId="currentSection.id"
                :chapterId="chapterId"
                :chapterIndex="currentChapter.index"
                :show-native="showNative"
                :full-width="fullWidth"
                :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                :current-scroll-height="currentScrollHeight"
                @scrollToBottom="scrollToBottom"
                @scrollTo="
                  (scrollHeight) => {
                    console.log('scrolling to ', scrollHeight);
                    sectionScrollContainer.scroll({
                      top: scrollHeight,
                      behavior: 'smooth',
                    });
                  }
                "
                @pageLoaded="handleCoursePageLoaded"
              />
              <CoursePage
                v-else
                :pageContents="page"
                :pageIndex="pageIndex"
                :sectionIndex="currentSection.index"
                :sectionId="currentSection.id"
                :chapterId="chapterId"
                :chapterIndex="currentChapter.index"
                :show-native="showNative"
                :full-width="fullWidth"
                :outer-header-height="props.outerHeaderHeight + innerHeaderHeight"
                @showNativeToggled="showNative = $event"
                @scrollBy="handleScrollBy"
                @undoLastScroll="handleUndoLastScroll"
                @pageLoaded="handleCoursePageLoaded"
              />
            </div>
          </transition>
        </div>
      </main>
      <div v-else class="min-w-full mx-auto flex">
        <div class="pt-28 w-full flex mx-auto justify-center items-center h-full">
          <LoadingSpinnerLarge />
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
/* Slide Left */
.slide-left-enter-active,
.slide-left-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
}

.slide-left-enter-from {
  transform: translateX(105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-left-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-left-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-left-leave-to {
  transform: translateX(-105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}

/* Slide Right */
.slide-right-enter-active,
.slide-right-leave-active {
  transition:
    transform 0.5s ease,
    opacity 0.5s ease;
  position: absolute;
  /* Ensure no overlap */
  top: 4;
  left: 0;
  width: 100%;
  height: 100%;
}

.slide-right-enter-from {
  transform: translateX(-105%);
  /* Start slightly further out for a gap */
  opacity: 0.2;
}

.slide-right-enter-to {
  transform: translateX(0%);
  /* Slide into position */
  opacity: 1;
}

.slide-right-leave-from {
  transform: translateX(0%);
  /* Start at original position */
  opacity: 1;
}

.slide-right-leave-to {
  transform: translateX(105%);
  /* Slide slightly further out for a gap */
  opacity: 0.2;
}
</style>
