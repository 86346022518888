/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_append_section_media_item_course_section_items_section_media__section_id__post } from '../models/Body_append_section_media_item_course_section_items_section_media__section_id__post';
import type { Body_upload_media_item_for_use_in_exercise_course_section_items_exercise_media__content_item_id__post } from '../models/Body_upload_media_item_for_use_in_exercise_course_section_items_exercise_media__content_item_id__post';
import type { CategorizationExercise_Output } from '../models/CategorizationExercise_Output';
import type { CategorizationExerciseCategory_Output } from '../models/CategorizationExerciseCategory_Output';
import type { CategorizationExerciseCategoryCreate } from '../models/CategorizationExerciseCategoryCreate';
import type { CategorizationExerciseCategoryUpdate } from '../models/CategorizationExerciseCategoryUpdate';
import type { CategorizationExerciseCategoryUpdateResponse } from '../models/CategorizationExerciseCategoryUpdateResponse';
import type { CategorizationExerciseCreate } from '../models/CategorizationExerciseCreate';
import type { CategorizationItem_Output } from '../models/CategorizationItem_Output';
import type { CategorizationItemCreate } from '../models/CategorizationItemCreate';
import type { CategorizationItemUpdate } from '../models/CategorizationItemUpdate';
import type { ClozeCreate } from '../models/ClozeCreate';
import type { ClozeUpdate } from '../models/ClozeUpdate';
import type { FormCaseCreate } from '../models/FormCaseCreate';
import type { FormCaseUpdate } from '../models/FormCaseUpdate';
import type { FormStructuredExerciseCreate } from '../models/FormStructuredExerciseCreate';
import type { FormStructuredExerciseUpdate } from '../models/FormStructuredExerciseUpdate';
import type { McQuestionCreate } from '../models/McQuestionCreate';
import type { McQuestionCreateResponse } from '../models/McQuestionCreateResponse';
import type { McQuestionUpdate } from '../models/McQuestionUpdate';
import type { McQuestionUpdateResponse } from '../models/McQuestionUpdateResponse';
import type { MediaItemCreateResponse } from '../models/MediaItemCreateResponse';
import type { OpenQuestionCreate } from '../models/OpenQuestionCreate';
import type { OpenQuestionCreateResponse } from '../models/OpenQuestionCreateResponse';
import type { OpenQuestionUpdate } from '../models/OpenQuestionUpdate';
import type { OpenQuestionUpdateResponse } from '../models/OpenQuestionUpdateResponse';
import type { PairOfTermsGameCreate } from '../models/PairOfTermsGameCreate';
import type { PairOfTermsGameUpdate } from '../models/PairOfTermsGameUpdate';
import type { ResourceWithQuestionsCreate } from '../models/ResourceWithQuestionsCreate';
import type { ResourceWithQuestionsUpdate } from '../models/ResourceWithQuestionsUpdate';
import type { SectionContentItem_Output } from '../models/SectionContentItem_Output';
import type { SectionContentItemCreateResponse } from '../models/SectionContentItemCreateResponse';
import type { SectionContentItemInteraction } from '../models/SectionContentItemInteraction';
import type { SectionContentItemUpdateResponse } from '../models/SectionContentItemUpdateResponse';
import type { SectionContentItemVocabResponse } from '../models/SectionContentItemVocabResponse';
import type { TextItemCreate } from '../models/TextItemCreate';
import type { TextItemUpdate } from '../models/TextItemUpdate';
import type { VocabItemCreate } from '../models/VocabItemCreate';
import type { VocabItemUpdate } from '../models/VocabItemUpdate';
import type { VocabItemUpdateResponse } from '../models/VocabItemUpdateResponse';
import type { VocabListDetails } from '../models/VocabListDetails';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CourseSectionItemsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Append Section Content Item With Text Content
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithTextContent(
    sectionId: string,
    requestBody: TextItemCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/text/{section_id}',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Pair Of Terms Game
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithPairOfTermsGame(
    sectionId: string,
    requestBody: PairOfTermsGameCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/pair-of-terms-game/{section_id}',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Section Content Item With Pair Of Terms Game
   * :param database_client:
   * :param section_content_item_id:
   * :param pair_of_terms_game: list with all item tuples.
   * :param user:
   * :param purge_unlisted: Existing tuples without an entry in the list will be deleted.
   * :return:
   * @param sectionContentItemId
   * @param requestBody
   * @param purgeUnlisted
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public updateSectionContentItemWithPairOfTermsGame(
    sectionContentItemId: string,
    requestBody: PairOfTermsGameUpdate,
    purgeUnlisted: boolean = true,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/pair-of-terms-game/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      query: {
        purge_unlisted: purgeUnlisted,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Section Content Item With Text Content
   * @param sectionContentItemId
   * @param requestBody
   * @returns SectionContentItem_Output Successful Response
   * @throws ApiError
   */
  public updateSectionContentItemWithTextContent(
    sectionContentItemId: string,
    requestBody: TextItemUpdate,
  ): CancelablePromise<SectionContentItem_Output> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/course-section-items/text/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Section Content Item
   * @param sectionContentItemId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteSectionContentItem(sectionContentItemId: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/course-section-items/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Upload Media Item For Use In Exercise
   * @param contentItemId
   * @param formData
   * @param description
   * @returns MediaItemCreateResponse Successful Response
   * @throws ApiError
   */
  public uploadMediaItemForUseInExercise(
    contentItemId: string,
    formData: Body_upload_media_item_for_use_in_exercise_course_section_items_exercise_media__content_item_id__post,
    description?: string | null,
  ): CancelablePromise<MediaItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/exercise-media/{content_item_id}',
      path: {
        content_item_id: contentItemId,
      },
      query: {
        description: description,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Media Item
   * @param sectionId
   * @param pageIndex
   * @param formData
   * @param description
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionMediaItem(
    sectionId: string,
    pageIndex: number | null,
    formData: Body_append_section_media_item_course_section_items_section_media__section_id__post,
    description?: string | null,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/section-media/{section_id}',
      path: {
        section_id: sectionId,
      },
      query: {
        page_index: pageIndex,
        description: description,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Section Media Item Description
   * @param sectionContentItemId
   * @param description
   * @returns any Successful Response
   * @throws ApiError
   */
  public updateSectionMediaItemDescription(sectionContentItemId: string, description: string): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/media/{section_content_item_id}/description',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      query: {
        description: description,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Case
   * @param sectionId
   * @param caseId
   * @param pageIndex
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithCase(
    sectionId: string,
    caseId: string,
    pageIndex: number | null,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/cases/{section_id}',
      path: {
        section_id: sectionId,
      },
      query: {
        case_id: caseId,
        page_index: pageIndex,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Emtpy Vocab List
   * @param sectionId
   * @param pageIndex
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithEmtpyVocabList(
    sectionId: string,
    pageIndex: number | null,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/vocab-list/{section_id}/empty',
      path: {
        section_id: sectionId,
      },
      query: {
        page_index: pageIndex,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Form Case Exercise
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithFormCaseExercise(
    sectionId: string,
    requestBody: FormCaseCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/form-case/{section_id}/empty',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Form Case Exercise Item
   * @param sectionContentItemId
   * @param requestBody
   * @returns SectionContentItemUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateFormCaseExerciseItem(
    sectionContentItemId: string,
    requestBody: FormCaseUpdate,
  ): CancelablePromise<SectionContentItemUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/form-case/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Form Structured Exercise
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithFormStructuredExercise(
    sectionId: string,
    requestBody: FormStructuredExerciseCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/form-structured-exercise/{section_id}/empty',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Form Structured Exercise Item
   * @param sectionContentItemId
   * @param requestBody
   * @returns SectionContentItemUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateFormStructuredExerciseItem(
    sectionContentItemId: string,
    requestBody: FormStructuredExerciseUpdate,
  ): CancelablePromise<SectionContentItemUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/form-structured-exercise/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Generate Dialog For Form Structured Exercise Item
   * @param sectionContentItemId
   * @returns string Successful Response
   * @throws ApiError
   */
  public generateDialogForFormStructuredExerciseItem(sectionContentItemId: string): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/form-structured-exercise/{section_content_item_id}/generate-dialog',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Vocab List Extracting From String
   * @param sectionId
   * @param textToExtractFrom
   * @param pageIndex
   * @param includeVocab
   * @param includePhrases
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithVocabListExtractingFromString(
    sectionId: string,
    textToExtractFrom: string,
    pageIndex: number | null,
    includeVocab: boolean = true,
    includePhrases: boolean = false,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/vocab-list/{section_id}/extract',
      path: {
        section_id: sectionId,
      },
      query: {
        text_to_extract_from: textToExtractFrom,
        page_index: pageIndex,
        include_vocab: includeVocab,
        include_phrases: includePhrases,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add More Vocab Of Same Context To Vocab List
   * @param sectionContentItemId
   * @param context
   * @param includeVocab
   * @param includePhrases
   * @returns SectionContentItemVocabResponse Successful Response
   * @throws ApiError
   */
  public addMoreVocabOfSameContextToVocabList(
    sectionContentItemId: string,
    context: string,
    includeVocab: boolean = true,
    includePhrases: boolean = false,
  ): CancelablePromise<SectionContentItemVocabResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/vocab-list/{section_content_item_id}/more',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      query: {
        context: context,
        include_vocab: includeVocab,
        include_phrases: includePhrases,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Vocab Item To Content Item With Vocab List
   * Note: the path over the content item rather than the vocab list directly allows to properly check
   * for editing rights to the section when deciding if allowed to add, edit a vocab item or not etc.
   * :param database_client:
   * :param section_content_item_id:
   * :param user:
   * :param vocab_item:
   * :return:
   * @param sectionContentItemId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public addVocabItemToContentItemWithVocabList(
    sectionContentItemId: string,
    requestBody: VocabItemCreate,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/vocab-list/{section_content_item_id}/single',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Vocab Item In Content Item With Vocab List
   * @param sectionContentItemId
   * @param vocabItemId
   * @param requestBody
   * @returns VocabItemUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateVocabItemInContentItemWithVocabList(
    sectionContentItemId: string,
    vocabItemId: string,
    requestBody: VocabItemUpdate,
  ): CancelablePromise<VocabItemUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/vocab-list/{section_content_item_id}/single/{vocab_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        vocab_item_id: vocabItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Vocab List For Content Item
   * @param sectionContentItemId
   * @returns VocabListDetails Successful Response
   * @throws ApiError
   */
  public getVocabListForContentItem(sectionContentItemId: string): CancelablePromise<VocabListDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/course-section-items/vocab-list/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Empty Categorization Exercise
   * Creates an empty categorization exercise within the specified section.
   *
   * :param database_client: The database client instance.
   * :param section_id: The UUID of the section where the exercise will be added.
   * :param user: The current authenticated user.
   * :return: A SectionContentItemCreateResponse containing details of the created categorization exercise.
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithEmptyCategorizationExercise(
    sectionId: string,
    requestBody: CategorizationExerciseCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/categorization-exercise/{section_id}/empty',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Categorization Exercise For Content Item
   * Retrieves a full categorization exercise, including its categories and
   * items.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param user: The current authenticated user (or None if unauthenticated).
   * :return: A CategorizationExercise model with details of the exercise.
   * @param sectionContentItemId
   * @returns CategorizationExercise_Output Successful Response
   * @throws ApiError
   */
  public getCategorizationExerciseForContentItem(
    sectionContentItemId: string,
  ): CancelablePromise<CategorizationExercise_Output> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Category To Categorization Exercise
   * Adds a new category to an existing categorization exercise within a
   * section content item.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param user: The current authenticated user.
   * :param category: The CategorizationExerciseCategoryCreate model with category details.
   * :return: The created CategorizationExerciseCategory model.
   * @param sectionContentItemId
   * @param requestBody
   * @returns CategorizationExerciseCategory_Output Successful Response
   * @throws ApiError
   */
  public addCategoryToCategorizationExercise(
    sectionContentItemId: string,
    requestBody: CategorizationExerciseCategoryCreate,
  ): CancelablePromise<CategorizationExerciseCategory_Output> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/category',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Category In Categorization Exercise
   * Updates an existing category within a categorization exercise.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param category_id: The UUID of the category to update.
   * :param user: The current authenticated user.
   * :param category: The CategorizationExerciseCategoryUpdate model with updated details.
   * :return: The updated CategorizationExerciseCategory model.
   * @param sectionContentItemId
   * @param requestBody
   * @returns CategorizationExerciseCategoryUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateCategoryInCategorizationExercise(
    sectionContentItemId: string,
    requestBody: CategorizationExerciseCategoryUpdate,
  ): CancelablePromise<CategorizationExerciseCategoryUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/category/{category_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Category From Categorization Exercise
   * Deletes a category from a categorization exercise.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param category_id: The UUID of the category to delete.
   * :param user: The current authenticated user.
   * :return: The UUID of the deleted category.
   * @param sectionContentItemId
   * @param categoryId
   * @returns string Successful Response
   * @throws ApiError
   */
  public deleteCategoryFromCategorizationExercise(
    sectionContentItemId: string,
    categoryId: string,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/category/{category_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        category_id: categoryId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Categories For Categorization Exercise
   * Retrieves all categories within a categorization exercise.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param user: The current authenticated user (or None if unauthenticated).
   * :return: A list of CategorizationExerciseCategory models.
   * @param sectionContentItemId
   * @returns CategorizationExerciseCategory_Output Successful Response
   * @throws ApiError
   */
  public getCategoriesForCategorizationExercise(
    sectionContentItemId: string,
  ): CancelablePromise<Array<CategorizationExerciseCategory_Output>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/categories',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Item To Category In Categorization Exercise
   * Adds a new item to a specified category within a categorization
   * exercise.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param user: The current authenticated user.
   * :param categorization_item: The CategorizationItemCreate model with item details.
   * :return: The created CategorizationItem model.
   * @param sectionContentItemId
   * @param requestBody
   * @returns CategorizationItem_Output Successful Response
   * @throws ApiError
   */
  public addItemToCategoryInCategorizationExercise(
    sectionContentItemId: string,
    requestBody: CategorizationItemCreate,
  ): CancelablePromise<CategorizationItem_Output> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/item',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Item In Category In Categorization Exercise
   * Updates an existing item within a specific category in a categorization
   * exercise.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param item_id: The UUID of the item to update.
   * :param user: The current authenticated user.
   * :param categorization_item: The CategorizationItemUpdate model with updated details.
   * :return: The updated CategorizationItem model.
   * @param sectionContentItemId
   * @param requestBody
   * @returns CategorizationItem_Output Successful Response
   * @throws ApiError
   */
  public updateItemInCategoryInCategorizationExercise(
    sectionContentItemId: string,
    requestBody: CategorizationItemUpdate,
  ): CancelablePromise<CategorizationItem_Output> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/item/{item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Categorization Exercise Item
   * Deletes an item from a specific category in a categorization exercise.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param item_id: The UUID of the item to delete.
   * :param user: The current authenticated user.
   * :return: The UUID of the deleted item.
   * @param sectionContentItemId
   * @param itemId
   * @returns string Successful Response
   * @throws ApiError
   */
  public deleteCategorizationExerciseItem(sectionContentItemId: string, itemId: string): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/item/{item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        item_id: itemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Items For Category In Categorization Exercise
   * Retrieves all items within a specific category in a categorization
   * exercise.
   *
   * :param database_client: The database client instance.
   * :param section_content_item_id: The UUID of the section content item containing the exercise.
   * :param user: The current authenticated user (or None if unauthenticated).
   * :return: A list of CategorizationItem models.
   * @param sectionContentItemId
   * @returns CategorizationItem_Output Successful Response
   * @throws ApiError
   */
  public getItemsForCategoryInCategorizationExercise(
    sectionContentItemId: string,
  ): CancelablePromise<Array<CategorizationItem_Output>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/course-section-items/categorization-exercise/{section_content_item_id}/items',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Fetch Or Create Section Content Item Interaction
   * @param sectionContentItemId
   * @returns SectionContentItemInteraction Successful Response
   * @throws ApiError
   */
  public fetchOrCreateSectionContentItemInteraction(
    sectionContentItemId: string,
  ): CancelablePromise<SectionContentItemInteraction> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/course-section-items/interact/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Cloze
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithCloze(
    sectionId: string,
    requestBody: ClozeCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/cloze/{section_id}/empty',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Cloze Item
   * @param sectionContentItemId
   * @param requestBody
   * @returns SectionContentItemUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateClozeItem(
    sectionContentItemId: string,
    requestBody: ClozeUpdate,
  ): CancelablePromise<SectionContentItemUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/cloze/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Append Section Content Item With Resource With Questions
   * Appends an empty resource with optional initial questions to a section.
   *
   * :param database_client: The database client instance.
   * :param section_id: The UUID of the section to which the resource is being added.
   * :param user: The current authenticated user.
   * :param resource: The ResourceWithQuestionsCreate model with optional question flags.
   * :return: The created SectionContentItem.
   * @param sectionId
   * @param requestBody
   * @returns SectionContentItemCreateResponse Successful Response
   * @throws ApiError
   */
  public appendSectionContentItemWithResourceWithQuestions(
    sectionId: string,
    requestBody: ResourceWithQuestionsCreate,
  ): CancelablePromise<SectionContentItemCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/resource/{section_id}/empty',
      path: {
        section_id: sectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Resource With Questions Item
   * @param sectionContentItemId
   * @param requestBody
   * @returns SectionContentItemUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateResourceWithQuestionsItem(
    sectionContentItemId: string,
    requestBody: ResourceWithQuestionsUpdate,
  ): CancelablePromise<SectionContentItemUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/resource/{section_content_item_id}',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Mc Question To Content Item With Resource
   * @param sectionContentItemId
   * @param requestBody
   * @returns McQuestionCreateResponse Successful Response
   * @throws ApiError
   */
  public addMcQuestionToContentItemWithResource(
    sectionContentItemId: string,
    requestBody: McQuestionCreate,
  ): CancelablePromise<McQuestionCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/resource/{section_content_item_id}/mc',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Mc Question In Content Item With Resource
   * @param sectionContentItemId
   * @param mcQuestionId
   * @param requestBody
   * @returns McQuestionUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateMcQuestionInContentItemWithResource(
    sectionContentItemId: string,
    mcQuestionId: string,
    requestBody: McQuestionUpdate,
  ): CancelablePromise<McQuestionUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/resource/{section_content_item_id}/mc/{mc_question_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        mc_question_id: mcQuestionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Mc Question From Item With Resource
   * @param sectionContentItemId
   * @param mcQuestionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public deleteMcQuestionFromItemWithResource(
    sectionContentItemId: string,
    mcQuestionId: string,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/course-section-items/resource/{section_content_item_id}/mc/{mc_question_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        mc_question_id: mcQuestionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Add Open Question To Content Item With Resource
   * @param sectionContentItemId
   * @param requestBody
   * @returns OpenQuestionCreateResponse Successful Response
   * @throws ApiError
   */
  public addOpenQuestionToContentItemWithResource(
    sectionContentItemId: string,
    requestBody: OpenQuestionCreate,
  ): CancelablePromise<OpenQuestionCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/course-section-items/resource/{section_content_item_id}/open',
      path: {
        section_content_item_id: sectionContentItemId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Open Question In Content Item With Resource
   * @param sectionContentItemId
   * @param openQuestionId
   * @param requestBody
   * @returns OpenQuestionUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateOpenQuestionInContentItemWithResource(
    sectionContentItemId: string,
    openQuestionId: string,
    requestBody: OpenQuestionUpdate,
  ): CancelablePromise<OpenQuestionUpdateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/course-section-items/resource/{section_content_item_id}/open/{open_question_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        open_question_id: openQuestionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Open Question From Item With Resource
   * @param sectionContentItemId
   * @param openQuestionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public deleteOpenQuestionFromItemWithResource(
    sectionContentItemId: string,
    openQuestionId: string,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/course-section-items/resource/{section_content_item_id}/open/{open_question_id}',
      path: {
        section_content_item_id: sectionContentItemId,
        open_question_id: openQuestionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
