<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { updateMinTextareaHeight } from '@/helper';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps(nodeViewProps);

const hintTextarea = ref(null);
const isMaximized = ref(false);
const rootContainer = ref(null);
const placeholder = ref('Gesucht');
const attemptOrSolutionInput = ref(null);
const isSolved = ref(false);
const uuid = uuidv4();

onMounted(async () => {
  if (!props.editor.isEditable) placeholder.value = '_'.repeat(props.node.attrs.solutionText.length);

  if (!!attemptOrSolutionInput.value) {
    await nextTick();
    attemptOrSolutionInput.value.focus();
  }

  props.editor.emit('nodeSolved', uuid, isSolved.value);

  await nextTick();
  isMaximized.value = false;
});

watch(
  () => props.node.attrs.attemptText,
  (newVal) => {
    if (props.editor.isEditable) return;
    let oldIsSolved = isSolved.value;
    isSolved.value = newVal === props.node.attrs.solutionText;
    if (oldIsSolved !== isSolved.value) props.editor.emit('nodeSolved', uuid, isSolved.value);
  },
  { immediate: true },
);

const delayedPositionClasses = ref('relative');
const appearAtOnceDisappearDelayed = ref(false);
const handleClickOutside = (event: Event) => {
  if (!rootContainer.value) return;
  let rect = rootContainer.value.getBoundingClientRect();
  isMaximized.value =
    event.clientX >= rect.left &&
    event.clientX <= rect.right &&
    event.clientY >= rect.top &&
    event.clientY <= rect.bottom;
};

const debounceHandleClickOutside = debounce(handleClickOutside, 200);

watch(
  () => isMaximized.value,
  async (nowMaximized) => {
    if (nowMaximized) {
      // Switch to absolute for symmetric opening
      window.addEventListener('click', debounceHandleClickOutside);
      delayedPositionClasses.value = 'absolute top-0 border-2';
      appearAtOnceDisappearDelayed.value = true;
      return;
    }

    window.removeEventListener('click', debounceHandleClickOutside);
    // Wait for collapse animation to complete (height transition)
    appearAtOnceDisappearDelayed.value = true;
    delayedPositionClasses.value = 'absolute -top-[9px] left-0 border-2';
    await new Promise((resolve) => setTimeout(resolve, 100));

    // After collapse finishes, switch back to relative to reflow inline
    delayedPositionClasses.value = 'relative bg-transparent border-none';
    appearAtOnceDisappearDelayed.value = false;
  },
  { immediate: true },
);

const solutionOrAttempt = computed({
  get() {
    return props.editor.isEditable ? props.node.attrs.solutionText : props.node.attrs.attemptText;
  },
  set(value) {
    if (props.editor.isEditable) {
      props.node.attrs.solutionText = value;
    } else {
      props.node.attrs.attemptText = value;
    }
  },
});
</script>

<template>
  <node-view-wrapper
    class="relative inline-flex w-1 items-center align-middle -translate-y-[2px] -my-1"
    as="span"
    :class="{
      'z-[10]': isMaximized,
      'z-1': !isMaximized,
    }"
    @click="isMaximized = true"
    :style="{
      verticalAlign: 'center',
      lineHeight: 'inherit',
      width: 0.9 * Math.max(solutionOrAttempt.length + 2, 10) + 'ch',
    }"
  >
    <!-- Placeholder to maintain space when absolute/ maxed -->
    <span
      v-if="appearAtOnceDisappearDelayed"
      :style="{
        width: 0.9 * Math.max(solutionOrAttempt.length + 2, 10) + 'ch',
      }"
    ></span>

    <span
      ref="rootContainer"
      :class="[
        'transition-all duration-100 transform',
        isMaximized ? 'top-0 left-0 rounded-lg ' : '',
        isSolved && !props.editor.isEditable ? 'border-teal-500 bg-teal-50' : 'border-blue-600 bg-white',
        delayedPositionClasses,
      ]"
      :style="{
        transform: isMaximized ? 'translateY(-36px)' : 'translateY(0)',
      }"
    >
      <label
        class="text-white w-full px-1 transition-all duration-100 transform overflow-hidden text-xs font-bold rounded-b-none rounded-t-md flex justify-between items-center"
        :class="{
          'py-1 ': isMaximized,
          'bg-blue-600': !isSolved || props.editor.isEditable,
          'bg-teal-500': isSolved && !props.editor.isEditable,
        }"
        contenteditable="false"
        :style="{ height: isMaximized ? '16px' : '0', lineHeight: isMaximized ? '16px' : '0' }"
        >Lücke
        <span
          v-show="props.editor.isEditable"
          draggable="true"
          data-drag-handle
          translate="no"
          class="pl-1 material-symbols-outlined no-translate text-lg cursor-move"
        >
          drag_indicator
        </span>
      </label>

      <span class="flex-col flex transition-all duration-100 transform" :class="{ 'my-1 gap-y-1': isMaximized }">
        <input
          @focusin="isMaximized = true"
          @focusout="
            () => {
              if (!props.editor.isEditable) isMaximized = false;
            }
          "
          ref="attemptOrSolutionInput"
          contenteditable="true"
          class="mx-1 my-0 px-1 border transition-all duration-100 transform border-gray-300 rounded-md text-xs md:text-sm"
          :class="{
            'focus:border-blue-600 focus:ring-blue-600 bg-gray-50': props.editor.isEditable || !isSolved,
            'focus:border-teal-600 focus:ring-teal-600 bg-teal-50': isSolved && !props.editor.isEditable,
            'py-1 ': isMaximized,
            'py-0': !isMaximized,
          }"
          :style="{
            width: 0.9 * Math.max(solutionOrAttempt.length + 1, 9) + 'ch',
          }"
          :placeholder="placeholder"
          v-model="solutionOrAttempt"
        />
        <span
          class="flex bg-gray-50 mx-auto overflow-hidden t transition-all duration-100 transform fallback-break"
          :style="{
            height: isMaximized ? hintTextarea.scrollHeight + 'px' : '0',
            width: 0.9 * Math.max(solutionOrAttempt.length + 1, 9) + 'ch',
          }"
        >
          <textarea
            ref="hintTextarea"
            contenteditable="true"
            class="px-1 resize-none rounded-md flex w-full text-gray-800 focus:ring-blue-600 text-xs"
            :class="{
              'bg-gray-50 border border-gray-300 focus:border-blue-600': props.editor.isEditable,
              'bg-white border-none': !props.editor.isEditable && !isSolved,
              'bg-teal-50 border-none': isSolved && !props.editor.isEditable,
            }"
            placeholder="Hinweis"
            rows="1"
            v-model="props.node.attrs.hintText"
            @input="updateMinTextareaHeight($event)"
            :disabled="!props.editor.isEditable"
          />
        </span>
      </span>
    </span>
  </node-view-wrapper>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
