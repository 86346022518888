<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import DOMPurify from 'dompurify';
import IconWithUnreadMark from '@/components/IconWithUnreadMark.vue';
import { nextTick } from 'vue';

const emit = defineEmits(['onClick']);
const props = defineProps({
  header: {
    default: '',
    type: String,
  },
  description: {
    default: '',
    type: String,
  },
  icon: {
    default: '',
    type: String,
  },
  markUnread: {
    default: false,
    type: Boolean,
  },
  thumbnail: {
    default: '',
    type: String,
  },
  maxIconAndThumbnailHeight: {
    default: '24',
    type: String,
  },
  maxIconAndThumbnailWidth: {
    default: '24',
    type: String,
  },
  iconTextSize: {
    default: '',
    type: String,
  },
  centerItems: {
    default: false,
    type: Boolean,
  },
  limitedDescriptionWidth: {
    default: false,
    type: Boolean,
  },
});
const thumbnailImg = ref(null);

function onClick() {
  emit('onClick');
}

const description = computed(() => {
  return DOMPurify.sanitize(props.description.replace(/casuu/g, "<span style='color: rgb(37 99 235);'>casuu</span>"));
});

onMounted(async () => {
  if (props.thumbnail) {
    await nextTick();
    console.log(`@/assets/images/thumbnails-and-icons/${props.thumbnail}`);
    import(`@/assets/images/thumbnails-and-icons/${props.thumbnail}.png`).then((img) => {
      thumbnailImg.value = img.default;
    });
  }
});
</script>

<template>
  <a
    class="group flex gap-x-5 text-gray-800 hover:bg-gray-50 rounded-md p-1.5 pl-6 md:p-4 dark:text-gray-200 dark:hover:bg-gray-900"
    :class="{
      'items-center': props.centerItems,
      'items-start': !props.centerItems,
    }"
    @click.stop="onClick"
    href="#"
  >
    <div
      v-if="!!props.icon"
      class="flex items-center justify-center pt-0.5"
      :style="{ width: `${props.maxIconAndThumbnailWidth}px`, height: `${props.maxIconAndThumbnailHeight}px` }"
    >
      <IconWithUnreadMark
        :icon="props.icon"
        :icon-size="iconTextSize"
        :markUnread="props.markUnread"
        :animatePing="true"
      />
    </div>

    <div
      v-if="!!thumbnailImg"
      class="flex-shrink-0 flex items-center justify-center overflow-hidden"
      :style="{ height: `${props.maxIconAndThumbnailHeight}px` }"
    >
      <img :src="thumbnailImg" alt="" :style="{ width: `${props.maxIconAndThumbnailWidth}px` }" />
    </div>

    <div class="grow">
      <p class="text-sm md:text-base font-semibold md:font-medium text-gray-500 md:text-gray-800 dark:text-gray-200">
        {{ props.header }}
      </p>
      <p
        class="text-sm max text-gray-500 group-hover:md:text-gray-800 dark:group-hover:text-gray-200"
        :class="{ 'w-[120px] md:w-[200px] xl:w-[400px]': props.limitedDescriptionWidth }"
        v-html="description"
      ></p>
    </div>
  </a>
</template>

<style scoped></style>
