import { LoggedInRoot } from '@/views/root';
import { OrganizationDashboard } from '@/views/dashboard';
import CreateUser from '@/views/dashboard/CreateUser.vue';
import ListUsers from '@/views/dashboard/ListUsers.vue';
import Subscription from '@/views/dashboard/Subscription.vue';
import Dashboard from '@/views/dashboard/Dashboard.vue';

export default {
  path: '/dashboard',
  component: LoggedInRoot,
  meta: { forceNewRouterKey: 'dashboard-subs' },
  children: [
    {
      path: '/dashboard',
      component: OrganizationDashboard,
      children: [
        { path: '', component: Dashboard },
        { path: 'subscription', component: Subscription },
        { path: 'users/add', component: CreateUser },
        { path: 'users', component: ListUsers },
      ],
    },
  ],
};
