<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import IngameProgressBadge from '@/views/home/IngameProgressBadge.vue';

const props = defineProps({
  image: {
    type: String,
    default: null,
  },
  firstName: {
    type: String,
    default: null,
  },
  lastName: {
    type: String,
    default: null,
  },
  initials: {
    type: String,
    default: null,
  },
  cacheImage: {
    type: Boolean,
    default: true,
  },
  showInitials: {
    type: Boolean,
    default: true,
  },
  showIngameLevel: {
    type: Boolean,
    default: false,
  },
  includeIngameProgressCardAsTooltip: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: '2.375rem',
  },
});

const initials = ref('');

onMounted(() => {
  if (!props.showInitials) {
    initials.value = '';
  }
  if (props.initials) {
    initials.value = props.initials;
  } else {
    if (props.firstName && props.firstName.length > 0) {
      initials.value += props.firstName[0].toUpperCase();
    }
    if (props.lastName && props.lastName.length > 0) {
      initials.value += props.lastName[0].toUpperCase();
    }
  }
});

const initialsTextSize = computed(() => {
  if (props.size === '2.375rem') {
    return '1rem';
  }
  if (props.size.includes('rem')) {
    return parseInt(props.size) / 2 + 'rem';
  }
  return parseInt(props.size) / 2 + 'px';
});
</script>

<template>
  <span
    class="relative"
    :class="
      'inline-flex items-center justify-center h-[' + props.size + '] w-[' + props.size + '] rounded-full bg-gray-600'
    "
  >
    <!-- set the user's profile image if image is given, i.e. not null. Otherwise, just use userInitials if given -->
    <!-- h-[2.375rem] w-[2.375rem] -->
    <div v-if="props.image">
      <img
        :class="
          'inline-block h-[' + props.size + '] w-[' + props.size + '] rounded-full ring-2 ring-white dark:ring-gray-800'
        "
        :src="props.image"
        alt="profile image"
      />
    </div>
    <div
      v-else-if="initials"
      class="flex items-centers justify-center text-center overflow-hidden rounded-full"
      :class="'h-[' + props.size + '] w-[' + props.size + ']'"
    >
      <span
        class="text-sm flex items-center justify-center font-medium overflow-hidden text-center text-white leading-none"
        :style="{
          'font-size': initialsTextSize,
          'line-height': initialsTextSize,
          height: props.size,
          width: props.size,
        }"
        >{{ initials }}</span
      >
    </div>
    <div v-else>
      <span
        translate="no"
        class="select-none no-translate material-symbols-outlined text-white"
        :style="{ 'font-size': props.size, 'line-height': props.size, height: props.size, width: props.size }"
      >
        person
      </span>
    </div>
    <div class="absolute top-0 right-0" v-if="showIngameLevel">
      <IngameProgressBadge :includeIngameProgressCardAsTooltip="includeIngameProgressCardAsTooltip" />
    </div>
  </span>
</template>
