<script setup lang="ts">
import { AlertError, ContextMenu } from '@/components';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useAlertStore, useAuthStore } from '@/stores';

import { type IStaticMethods } from 'preline/preline';
import { storeToRefs } from 'pinia';
import { getLevelFromXp } from '@/helper';

const authStore = useAuthStore();
const alertStore = useAlertStore();
const { userLevel } = storeToRefs(authStore);
const originalLevel = ref(null);
const mountingComplete = ref(false);
const contextMenu = ref(null);

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

watch(
  () => userLevel.value,
  () => {
    if (originalLevel.value === userLevel.value || !mountingComplete.value || userLevel.value === null) {
      return;
    }
    originalLevel.value = userLevel.value;
    console.log('Level up!');
    alertStore.xp(t('message.levelUp', userLevel.value), t('message.levelUpTitle'));
  },
  { immediate: true },
);

let longPressTimer = null;

// Utility function to check if there is selected text
const isTextSelected = () => {
  return window.getSelection().toString().length > 0;
};

// Show the custom context menu at the specified position
const showContextMenu = (x, y) => {
  contextMenu.value.showContextMenu(x, y);
};

// Handle right-click (desktop) only if text is selected
const handleContextMenu = (event) => {
  if (isTextSelected()) {
    event.preventDefault(); // Prevent default context menu
    showContextMenu(event.clientX, event.clientY);
  } else {
    hideContextMenu(); // Hide custom menu if shown
  }
};

// Handle long-press start (touchstart) on mobile
const handleLongPressStart = (event) => {
  longPressTimer = setTimeout(() => {
    if (isTextSelected()) {
      const touch = event.touches[0];
      showContextMenu(touch.clientX, touch.clientY);
    }
  }, 500); // 500ms delay for long press
};

// Cancel long press if touch is released early (touchend)
const handleLongPressEnd = () => {
  clearTimeout(longPressTimer);
};

// Hide the custom context menu
const hideContextMenu = () => {
  // contextMenu.value?.hideContextMenu();
};

// Add event listeners for desktop and mobile on mount, remove on unmount
onMounted(() => {
  // Desktop: Show on right-click if text is selected
  document.addEventListener('contextmenu', handleContextMenu);
  // Desktop and mobile: Hide menu on any new click or tap
  document.addEventListener('mousedown', hideContextMenu);

  // Mobile: Start and end long press detection
  document.addEventListener('touchstart', handleLongPressStart);
  document.addEventListener('touchend', handleLongPressEnd);

  // Load Material Symbols Outlined font and add class when loaded (avoiding FOUT)
  document.fonts.load("1em 'Material Symbols Outlined'").then(() => {
    document.documentElement.classList.add('font-loaded');
  });
});

onBeforeUnmount(() => {
  document.removeEventListener('contextmenu', handleContextMenu);
  document.removeEventListener('mousedown', hideContextMenu);

  document.removeEventListener('touchstart', handleLongPressStart);
  document.removeEventListener('touchend', handleLongPressEnd);
});
</script>

<template>
  <div class="hidden">
    <!-- this enforces preloads and avoids "not in use"-warnings -->
    <span class="material-symbols-outlined" style="display: none">exercise</span>
    <span class="material-symbols-rounded" style="display: none">exercise</span>
  </div>
  <div
    class="app-container flex min-h-full h-full max-h-full w-full min-w-full max-w-full overflow-hidden"
    @mousedown="hideContextMenu"
    @touchstart="hideContextMenu"
  >
    <div class="absolute min-w-fit top-0 right-0 mt-4 mr-4 z-[120]">
      <AlertError />
    </div>
    <div class="flex max-h-full h-full min-h-full min-w-full w-full max-w-full overflow-hidden">
      <router-view />
    </div>
    <div class="z-[110]">
      <ContextMenu ref="contextMenu" />
    </div>
  </div>
</template>

<style>
@import 'icon_fonts.css';
</style>
