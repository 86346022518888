<script setup lang="ts">
import TextEditor from '@/views/courses/TextEditor.vue';
import TextFinishedButton from '@/components/didactics/text/TextFinishedButton.vue';
import DOMPurify from 'dompurify';
import { ref, watch } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';

const props = defineProps({
  contentItem: {
    type: Object,
    required: true,
  },
  boxReducedWidth: {
    type: Number,
    required: true,
  },
  germanContainerWidth: {
    type: Number,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
  isEditing: {
    type: Boolean,
    required: true,
  },
});

const alertStore = useAlertStore();

const unsavedChanges = ref(false);
const contentEditor = ref(null);

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.isEditing) return;
      setTimeout(() => {
        saveText();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

const saveText = async () => {
  if (!props.isEditing) return;
  if (!contentEditor.value) return;
  if (!props.contentItem) return;
  await (
    await getApiClient()
  ).courseSectionItems
    .updateSectionContentItemWithTextContent(props.contentItem.id, {
      content: contentEditor.value.getHtmlContent(),
    })
    .then(() => {
      unsavedChanges.value = false;
      contentEditor.value.resetEmitState();
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave test', 'Error', error);
      throw new Error('Failed to autosave text');
    });
};

const sanitize = (content: string) => {
  return DOMPurify.sanitize(content);
};
</script>

<template>
  <!--       :ref="contentEditors[textItemIndex(index)]" -->

  <TextEditor
    ref="contentEditor"
    :content="sanitize(<string>contentItem.text_item.content)"
    :allowList="true"
    :allowTable="true"
    :allowEdit="<boolean>props.isEditing"
    @unsavedChanges="unsavedChanges = true"
    @changesCleared="unsavedChanges = false"
    :width="
      props.germanContainerWidth -
      (props.contentItem.text_item.designation !== 'GENERAL' ? props.boxReducedWidth : 0) +
      'px'
    "
  />

  <div class="absolute bottom-0.5 end-3">
    <TextFinishedButton
      v-if="!props.isEditing"
      :contentItemId="contentItem.id"
      :sectionIndex="<number>props.sectionIndex"
    />
  </div>
</template>

<style scoped></style>
