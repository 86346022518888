<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { HSTooltip } from 'preline';
import DropDownListItem from '@/components/DropDownListItem.vue';
import { v4 as uuidv4 } from 'uuid';

const emit = defineEmits(['createNewPairOfTermsExercise']);

const popoverIsOpen = ref(false);
const popover = ref(null);
const container = ref(null);
const uuid = uuidv4();

onMounted(() => {
  // wait 200 ms then log
  setTimeout(async () => {
    await HSTooltip.autoInit();
  }, 200);

  popover.value = new HSTooltip(document.querySelector(`#select-new-vocab-popover-${props.sectionId}-${uuid}`));
  console.log(popover.value);

  document.addEventListener('click', handleClickOutside);
});

const props = defineProps({
  sectionId: {
    type: String,
    required: true,
  },
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const handleClickOutside = (event: Event) => {
  if (container.value && !!popover.value && !container.value.contains(event.target)) {
    popoverIsOpen.value = false;
    popover.value.hide();
  }
};

const togglePopover = () => {
  if (!popover.value) return;
  popoverIsOpen.value = !popoverIsOpen.value;
  if (popoverIsOpen.value) {
    popover.value.show();
  } else {
    popover.value.hide();
  }
};

defineExpose({
  togglePopover,
});
</script>

<template>
  <!-- Base card -->
  <div class="relative h-full" ref="container">
    <span
      class="h-full cursor-pointer select-none flex-col flex border-2 border-gray-200 border-dashed bg-white rounded-xl text-center items-center justify-center"
      @click.prevent="togglePopover"
    >
      <span
        translate="no"
        class="material-symbols-outlined notranslate text-gray-400 dark:text-gray-500 text-7xl text-center"
        >dictionary</span
      >
      <span class="pe-1 font-medium text-gray-400 dark:text-neutral-200 text-sm pt-2 pb-1">Vokabelliste & -test</span>
      <span
        class="relative text-sm cursor-pointer font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600"
        >Erstellen</span
      >
    </span>
    <!-- End Base card -->

    <!-- Popover to select type of exercise to create -->
    <div
      :id="'select-new-vocab-popover-' + sectionId + '-' + uuid"
      class="hs-tooltip [--trigger:click] [--placement:left] inline-block absolute top-[-128px] md:top-[48px] left-[164px] md:left-[64px]"
    >
      <span
        class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
        role="tooltip"
      >
        <DropDownListItem
          header="Manuell"
          icon="hide_source"
          description="Mit leerer Liste starten und manuell Begriffe und Sätze hinzufügen"
          :center-items="true"
          @click.prevent="
            () => {
              emit('createEmptyVocabList');
              togglePopover();
            }
          "
          :limited-description-width="true"
        />
        <DropDownListItem
          header="Automatisch (alle Textabschnitte)"
          icon="all_inclusive"
          description="Vokabelliste automatisch generieren und manuell anpassen. Generieren aus: Komplettem Inhalt"
          :center-items="true"
          @click.prevent="
            () => {
              emit('createAutomatedVocabListFull');
              togglePopover();
            }
          "
          :limited-description-width="true"
        />
        <DropDownListItem
          header="Automatisch (nur vorheriger Textabschnitt)"
          icon="target"
          description="Vokabelliste automatisch generieren und manuell anpassen. Generieren aus: Nur dem vorherigen Textabschnitt"
          :center-items="true"
          @click.prevent="
            () => {
              emit('createAutomatedVocabListLast');
              togglePopover();
            }
          "
          :limited-description-width="true"
        />
      </span>
    </div>
    <!-- End Popover to select type of exercise to create -->
  </div>
</template>

<style scoped></style>
