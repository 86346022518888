<script setup lang="ts">
import { nextTick, onBeforeUnmount, onMounted, Ref, ref, watch } from 'vue';
import CategoryZone from '@/components/didactics/categorization/CategoryZone.vue';
import DraggableItem from '@/components/didactics/categorization/DraggableItem.vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore, useCourseStore } from '@/stores';
import { CategorizationCategoryType, CategorizationItemType } from '@/helper/typing';
import CardPair from '@/components/didactics/pair_of_terms/CardPair.vue';

const emit = defineEmits(['viewFullHeightOn', 'viewFullHeightOff', 'scrollBy']);
const container = ref(null);
const categoryZoneRefs = ref([] as Ref<HTMLElement | null>[]);
const currentlyDraggingOver = ref([] as boolean[]);
const currentlyDraggingItemId = ref('');
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const fetchCompleted = ref(false);

const courseInteractionStore = useCourseInteractionStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const itemInteractionState = ref({});

import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';

const { t } = useI18n();

const props = defineProps({
  allowEdit: {
    type: Boolean,
    default: false,
  },
  isMaximized: {
    type: Boolean,
    default: false,
  },
  fixedMaximization: {
    type: Boolean,
    default: false,
  },
  contentItem: {
    type: Object,
    required: true,
  },
  // index: {
  //   type: Number,
  //   required: true,
  // },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(
    props.sectionIndex,
    props.contentItem.id,
  );
  // create local copy - we want to defer updates to wait for animations etc
  console.log(interactionState);
  if (!!interactionState && interactionState !== {}) {
    fetchCompleted.value = true;
    itemInteractionState.value = { ...interactionState };
    console.log(itemInteractionState.value);
  }
};

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    if (!props.allowEdit) {
      await nextTick();
      await fetchInteractionState();
    }
  },
  { immediate: true },
);

onMounted(() => {
  if (!props.fixedMaximization) {
    document.addEventListener('click', handleClickOutside);
  }

  fetchCategoriesAndItems().then(async () => {
    if (props.allowEdit) {
      fetchCompleted.value = true;
      return;
    }

    await fetchInteractionState();
  });
});

const fetchCategoriesAndItems = async () => {
  // reset categories
  categories.value.length = 0;

  // reset unplacedItems and categorized items
  unplacedItems.value.length = 0;
  console.log(JSON.stringify(categories.value));
  categories.value.forEach((category) => {
    category.items.length = 0;
  });

  // fetch categories
  console.log('# categories is: ', props.contentItem.categorization_exercise.categorization_exercise_categories.length);
  props.contentItem.categorization_exercise.categorization_exercise_categories.forEach((category: any) => {
    // if we are editing, assign all items their parent category
    categories.value.push({
      id: category.id,
      name: category.name,
      items: props.allowEdit ? category.categorization_items || [] : [],
    });

    // if we are not editing, assign all items to unplaced items
    if (!props.allowEdit) {
      category.categorization_items.forEach((item: any) => {
        // TODO: we assume unambiguous item - category relationships here. Check for it!
        unplacedItems.value.push({
          id: item.id,
          content: item.content,
          categoryId: category.id,
          isIncorrect: false,
        });
      });
    }
  });

  currentlyDraggingOver.value = Array(categories.value.length).fill(false);
  categories.value.forEach((pair: any) => {
    categoryZoneRefs.value.push(ref<HTMLElement | null>(null));
  });
};

onBeforeUnmount(() => {
  if (!props.fixedMaximization) {
    document.removeEventListener('click', handleClickOutside);
  }
});

const categories = ref<Array<CategorizationCategoryType>>([]);
const unplacedItems = ref<Array<CategorizationItemType>>([]);

// const items = ref([
//   {id: 'item1a', content: 'Begriff 1A', categoryId: 'category1', isIncorrect: false},
//   {id: 'item1b', content: 'Begriff 1B', categoryId: 'category1', isIncorrect: false},
//   {id: 'item2', content: 'Begriff 2', categoryId: 'category2', isIncorrect: false},
//   {id: 'item3', content: 'Begriff 3', categoryId: 'category3', isIncorrect: false},
// ]);

// Function to handle dropping items in categories
const handleDrop = async (categoryId: string) => {
  let itemId = currentlyDraggingItemId.value;
  console.log('received drop', itemId, categoryId);
  const itemIndex = unplacedItems.value.findIndex((item) => item.id === itemId);
  const item = unplacedItems.value[itemIndex];

  if (item) {
    const isCorrect = item.categoryId === categoryId;
    if (isCorrect) {
      // Place item in the correct category and remove from the waiting zone
      const category = categories.value.find((cat) => cat.id === categoryId);
      category.items.push(item);
      unplacedItems.value.splice(itemIndex, 1);
    } else {
      // Trigger bounce-back effect and red background for incorrect items
      item.isIncorrect = true;
      // wait 500 ms
      await new Promise((resolve) => setTimeout(resolve, 500)); // Match the bounce-back animation duration
      item.isIncorrect = false; // Reset after animation
    }
  }
  currentlyDraggingItemId.value = '';
};

const handleClickOutside = (event: Event) => {
  if (props.fixedMaximization) return;
  console.log('click outside');
  if (props.isMaximized && !!container.value && !container.value.contains(event.target)) {
    emit('viewFullHeightOff');
  }
};

const handleClickInside = () => {
  if (props.fixedMaximization) return;
  if (!props.isMaximized) {
    emit('viewFullHeightOn');
  }
};

const handleDraggingAtPosition = (itemId, position: { x: number; y: number }) => {
  currentlyDraggingItemId.value = itemId;
  for (let i = 0; i < categoryZoneRefs.value.length; i++) {
    const categoryZoneContainer = categoryZoneRefs.value[i]?.value?.[0];

    if (!categoryZoneContainer) {
      currentlyDraggingOver.value[i] = false;
      continue;
    }

    const rect = categoryZoneContainer.getBoundingClientRect();

    currentlyDraggingOver.value[i] =
      position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom;
  }
};

const handleDroppedItemAtPosition = (itemId: string, position: { x: number; y: number }) => {
  currentlyDraggingItemId.value = itemId;
  for (let i = 0; i < categoryZoneRefs.value.length; i++) {
    const categoryZoneContainer = categoryZoneRefs.value[i]?.value?.[0];
    if (!categoryZoneContainer) {
      continue;
    }

    const rect = categoryZoneContainer.getBoundingClientRect();

    if (position.x >= rect.left && position.x <= rect.right && position.y >= rect.top && position.y <= rect.bottom) {
      handleDrop(categories.value[i].id);
    }
  }
  currentlyDraggingOver.value = Array(categories.value.length).fill(false);
};

const reopenExercise = async () => {
  courseInteractionStore.reopenSectionContentItem(props.sectionIndex, props.contentItem.id).then((response) => {
    if (!response) return; // nothing has happened as the item was already started
    itemInteractionState.value = response;
  });

  // reset all items to unplaced items
  categories.value.forEach((category) => {
    category.items.forEach((item) => {
      unplacedItems.value.push({
        id: item.id,
        content: item.content,
        categoryId: category.id,
        isIncorrect: false,
      });
    });
    category.items.length = 0;
  });
};

watch(
  () => unplacedItems.value.length,
  async (newLength) => {
    if (newLength > 0) return; // nothing to do

    // no unplaced items left, so exercise complete
    await new Promise((resolve) => setTimeout(resolve, 300));
    courseInteractionStore
      .setSectionContentItemCompletedIfNotAlready(props.sectionIndex, props.contentItem.id)
      .then(async (response) => {
        console.log(response);
        response.notifications?.forEach((notification) => {
          alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
        });
        itemInteractionState.value = response.section_content_item_interaction;
        await authStore.fetchUserXp();
      });
  },
);
</script>

<template>
  <div
    ref="container"
    class="relative w-full p-0.5 overflow-hidden flex-col flex"
    :class="{
      'h-fit': isMaximized,
      'h-[295px]': !isMaximized,
    }"
    @click.prevent="
      (event) => {
        handleClickInside();
        event.stopPropagation();
      }
    "
    :key="categories.length"
  >
    <div
      class="w-full flex-col flex"
      :class="{
        'opacity-25':
          !props.allowEdit &&
          (!fetchCompleted ||
            (itemInteractionState?.completed_at != null && itemInteractionState?.reopened_at == null)),
      }"
    >
      <h2 class="text font-semibold pt-2 text-center">Übung: Kategorisieren</h2>

      <!-- Display categories as drop zones -->
      <span class="pt-2 pb-1 text-center text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400">
        Kategorien
      </span>
      <div class="flex flex-wrap justify-center gap-1">
        <div
          v-for="(category, index) in categories"
          :ref="categoryZoneRefs[index]"
          class="w-[calc(50%-4px)] md:w-52 min-h-36 md:min-h-52 h-fit"
        >
          <CategoryZone
            :key="category.id"
            :category="category"
            @dropItem="handleDrop"
            :isEditing="props.allowEdit"
            :isDraggingOver="currentlyDraggingOver[index]"
            @deleteCategory="
              async (categoryId) => {
                courseStore
                  .removeCategorizationExerciseCategory(props.contentItem.section_id, props.contentItem.id, categoryId)
                  .then(async () => {
                    alertStore.success('Kategorie entfernt');
                  })
                  .catch((error) => {
                    alertStore.error('Fehler beim Entfernen der Kategorie: ' + error);
                  });
              }
            "
            @changeCategory="
              async (categoryId, newContent) => {
                courseStore
                  .updateCategorizationExerciseCategory(
                    props.contentItem.section_id,
                    props.contentItem.id,
                    categoryId,
                    newContent,
                  )
                  .then(async () => {})
                  .catch((error) => {
                    alertStore.error('Fehler beim Aktualisieren der Kategorie: ' + error);
                  });
              }
            "
          >
            <!-- Display items already placed in this category -->
            <DraggableItem
              v-for="item in category.items"
              :key="item.id"
              :item="item"
              :isInCategory="true"
              :isEditing="props.allowEdit"
              :icon="props.allowEdit ? 'delete' : null"
              @deleteItem="
                async (itemId) => {
                  courseStore
                    .removeCategorizationExerciseItem(
                      props.contentItem.section_id,
                      props.contentItem.id,
                      category.id,
                      itemId,
                    )
                    .then(async () => {
                      alertStore.success('Item entfernt');
                    })
                    .catch((error) => {
                      alertStore.error('Fehler beim Entfernen des Items: ' + error);
                    });
                }
              "
              @changeItem="
                async (itemId, newContent) => {
                  courseStore
                    .updateCategorizationExerciseItem(
                      props.contentItem.section_id,
                      props.contentItem.id,
                      category.id,
                      itemId,
                      newContent,
                    )
                    .then(async () => {})
                    .catch((error) => {
                      alertStore.error('Fehler beim Aktualisieren des Items: ' + error);
                    });
                }
              "
              @currentDraggingPosition="handleDraggingAtPosition"
              @droppedItemAtPosition="handleDroppedItemAtPosition"
              @scrollBy="(distance) => emit('scrollBy', distance)"
            />
            <DraggableItem
              v-if="props.allowEdit"
              :item="{
                id: 'add-item',
                content: 'Item hinzufügen',
                categoryId: '',
                isIncorrect: false,
              }"
              color="blue"
              icon="add"
              :onClick="
                async () => {
                  courseStore
                    .addItemToCategorizationExerciseCategory(
                      props.contentItem.section_id,
                      props.contentItem.id,
                      category.id,
                    )
                    .then(async () => {
                      alertStore.success('Item hinzugefügt');
                    })
                    .catch((error) => {
                      alertStore.error('Fehler beim Hinzufügen des Items: ' + error);
                      throw Error('Error adding categorization exercise item');
                    });
                }
              "
            />
          </CategoryZone>
        </div>
        <div class="w-[calc(50%-4px)] md:w-52 min-h-36 md:min-h-52 h-fit">
          <CategoryZone
            v-if="props.allowEdit"
            :category="{
              name: 'Kategorie hinzufügen',
            }"
            color="blue"
            icon="add"
            :onClick="
              () => {
                courseStore
                  .addCategorizationExerciseCategory(props.contentItem.section_id, props.contentItem.id)
                  .then(() => {
                    alertStore.success('Kategorie hinzugefügt');
                  })
                  .catch((error) => {
                    alertStore.error('Fehler beim Hinzufügen der Kategorie: ' + error);
                    throw Error('Error adding category');
                  });
              }
            "
          />
        </div>
      </div>

      <!-- Waiting Zone for unplaced items -->
      <span
        v-show="unplacedItems.length > 0"
        class="pt-6 pb-1 text-center text-xs uppercase tracking-wide text-gray-500 dark:text-gray-400"
      >
        Items
      </span>
      <div class="flex flex-wrap gap-1 justify-center">
        <DraggableItem
          v-for="item in unplacedItems"
          :key="item.id"
          :item="item"
          :isInCategory="false"
          :isIncorrect="item.isIncorrect"
          @currentDraggingPosition="handleDraggingAtPosition"
          @droppedItemAtPosition="handleDroppedItemAtPosition"
          @scrollBy="(distance) => emit('scrollBy', distance)"
          @startDraggingItem="currentlyDraggingItemId = $event"
        />
      </div>
    </div>
    <div
      class="absolute z-10 select-none top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      v-show="!fetchCompleted"
    >
      <LoadingSpinnerLarge />
    </div>
    <div
      v-if="
        fetchCompleted &&
        !props.allowEdit &&
        itemInteractionState?.completed_at != null &&
        itemInteractionState?.reopened_at == null
      "
      class="absolute z-10 cursor-pointer group select-none text-teal-500 text-2xl bg-teal-200/10 top-0 start-0 items-center justify-center flex w-full h-full p-0.5 overflow-hidden"
      @click="reopenExercise"
    >
      <span class="block group-hover:hidden">
        {{ t('message.exerciseAlreadyCompleted') }}
      </span>
      <span class="hidden group-hover:flex items-center hover:text-teal-600">
        {{ t('message.exercisePlayAgain') }}
        <span translate="no" class="material-symbols-outlined notranslate text-4xl pl-1">exercise</span>
      </span>
    </div>
  </div>
</template>

<style scoped>
/* Additional styling here if needed */
</style>
