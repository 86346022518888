import { nextTick } from 'vue';

export async function adjustHeight(el: HTMLTextAreaElement, minRows: number = 1) {
  await nextTick(async () => {});

  if (el.rows > minRows) {
    el.rows -= Math.min(el.rows - minRows, 12);
  }
  // add rows until scrollHeight is reached
  while (el.scrollHeight > el.clientHeight) {
    el.rows += 1;
  }

  // el.style.height = `${el.scrollHeight}px`; // Set the new height
}

export async function updateMinTextareaHeight(textareaElement: EventTarget | null, minRows: number = 1) {
  // console.log('updateMinTextareaHeight', textareaElement, minRows);
  if (!textareaElement) {
    return;
  }
  await adjustHeight(textareaElement as HTMLTextAreaElement, minRows);
}
