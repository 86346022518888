<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

const emit = defineEmits(['close', 'moveToEndOfStack']);

const props = defineProps({
  text: {
    type: [String, Array],
    required: true,
  },
  isList: {
    type: Boolean,
    default: false,
  },
  isAtEndOfStack: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div
    @click.prevent="
      (event) => {
        if (props.isAtEndOfStack) {
          emit('close');
          return;
        }
        emit('moveToEndOfStack');
        event.stopPropagation();
      }
    "
    class="w-full h-full flex"
  >
    <div class="relative w-full h-full px-2 pb-2 pt-6">
      <span
        translate="no"
        @click.prevent="
          (event) => {
            emit('close');
            event.stopPropagation();
          }
        "
        class="absolute top-1 end-1 material-symbols-outlined notranslate cursor-pointer text-gray-400 hover:text-gray-500"
      >
        close
      </span>
      <ul v-if="props.isList" class="px-2 py-2">
        <li v-for="item in props.text" :key="item" class="mt-3 list-disc list-inside">
          {{ item }}
        </li>
      </ul>
      <span v-else>
        {{ props.text }}
      </span>
    </div>
  </div>
</template>

<style scoped></style>
