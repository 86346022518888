import {
  Layout,
  SignIn,
  SignUp,
  ConfirmEmail,
  ChangePassword,
  ResetPasswordRequest,
  AcceptInvite,
} from '@/views/account';

export default {
  path: '/account',
  component: Layout,
  meta: { forceNewRouterKey: 'account-subs' },
  children: [
    { path: '', redirect: 'login' },
    { path: 'invite/accept', component: AcceptInvite },
    { path: 'sign-in', component: SignIn },
    { path: 'sign-up', component: SignUp },
    { path: 'sign-up/confirm', component: ConfirmEmail },
    { path: 'reset-password', component: ResetPasswordRequest },
    { path: 'change-password', component: ChangePassword },
  ],
};
