<!-- VocabListEntry.vue -->
<script setup lang="ts">
import { defineProps, ref } from 'vue';
import { FlexRender } from '@tanstack/vue-table';
import { debounce } from 'lodash';
import { is } from '@vee-validate/rules';

const row = defineModel('row');
const emit = defineEmits(['isDragging', 'scrollBy', 'currentDraggingPosition', 'droppedAtPosition']);
const isDragging = ref(false);
const cardRef = ref(null);
const initialOffset = ref({ x: 0, y: 0 });
const initialHeight = ref(0);
const initialWidth = ref(0);
const touchOrCursorX = ref(undefined);
const touchOrCursorY = ref(undefined);
const scrollInterval = ref<ReturnType<typeof setInterval> | null>(null);

// dragging = desktop
const signalStartDragging = () => {
  if (isDragging.value) {
    return;
  }
  isDragging.value = true;
  emit('isDragging', isDragging.value, row.value.original['id']);
};

const handleDragging = (event: Event) => {
  signalStartDragging();
  if (event.clientX !== 0) touchOrCursorX.value = event.clientX;
  if (event.clientY !== 0) touchOrCursorY.value = event.clientY;
  // note: at the end of drag, 0/0 is sent for a short time. We do not want this, as this leads to short up scroll while drag is resetting.
  debounceAutoScroll(10);
};

const signalEndDragging = () => {
  console.log('reset to undefined');
  touchOrCursorX.value = undefined;
  touchOrCursorY.value = undefined;
  stopAutoScroll();
  isDragging.value = false;
  emit('isDragging', isDragging.value, null);
};

// touch = mobile
const handleTouchStart = (event: Event) => {
  const touch = event.touches[0];
  const rect = cardRef.value.getBoundingClientRect();
  initialOffset.value.x = touch.clientX - rect.left;
  initialOffset.value.y = touch.clientY - rect.top;
  initialHeight.value = rect.height;
  initialWidth.value = rect.width;

  signalStartDragging();
};

const handleTouchEnd = (event: Event) => {
  // this also signal end of dropping. Do not send signal drop end before sending drop position as this would reset ID
  // of dragged item in parent (not necessary at all, but in any case send position first)
  emit('droppedAtPosition', { x: touchOrCursorX.value, y: touchOrCursorY.value });
  cardRef.value.style.position = 'static';

  signalEndDragging(); // resets currently dragged item, so do this after sending drop position
};

const handleTouchMove = (event: Event) => {
  // Prevent the default scroll behavior; needs @touchmove instead of v-touch:drag for access to event
  event.preventDefault();

  // Get the current touch position
  const touch = event.touches[0];
  touchOrCursorX.value = touch.clientX;
  touchOrCursorY.value = touch.clientY;

  // Trigger auto-scroll if near the top or bottom of the viewport
  debounceAutoScroll();

  // Set the element's position to follow the touch point directly
  cardRef.value.style.position = 'fixed';
  cardRef.value.style.left = `${touchOrCursorX.value - initialOffset.value.x}px`;
  cardRef.value.style.top = `${touchOrCursorY.value - initialOffset.value.y}px`;
  cardRef.value.style.height = `${initialHeight.value}px`;
  cardRef.value.style.width = `${initialWidth.value}px`;

  debounceSignalCurrentPosition();
};

const signalCurrentPosition = () => {
  emit('currentDraggingPosition', { x: touchOrCursorX.value, y: touchOrCursorY.value });
};

const debounceSignalCurrentPosition = debounce(signalCurrentPosition, 50);

// Auto-scrolling when the drags near screen top/bottom
const autoScroll = (speed: number = 5) => {
  // If close to the top, scroll up
  if (touchOrCursorY.value < 170) {
    startAutoScroll(-speed); // Scroll up by 5px
  }
  // If close to the bottom, scroll down
  else if (touchOrCursorY.value > window.innerHeight - 70) {
    startAutoScroll(speed); // Scroll down by 5px
  } else {
    stopAutoScroll(); // Stop scrolling if in the middle area
  }
};

const debounceAutoScroll = debounce(autoScroll, 2);

const startAutoScroll = (distance: number) => {
  if (scrollInterval.value) return; // Prevent multiple intervals
  scrollInterval.value = setInterval(() => {
    emit('scrollBy', distance);
  }, 20);
};

const stopAutoScroll = () => {
  if (scrollInterval.value) {
    clearInterval(scrollInterval.value);
    scrollInterval.value = null;
  }
};
</script>

<template>
  <tr
    ref="cardRef"
    class=""
    :class="{
      'bg-gray-100 dark:bg-neutral-800 overflow-visible z-40': isDragging,
      'overflow-visible': !isDragging,
    }"
    draggable="true"
    @drag.prevent="handleDragging"
    @dragend.prevent="signalEndDragging"
  >
    <td
      v-for="cell in row.getVisibleCells()"
      :key="cell.id"
      class="pl-4 pr-10 md:py-2 text-start items-start size-px text-xs md:text-sm text-gray-800 dark:text-neutral-200"
      :style="{ width: `${cell.column.getSize()}px` }"
    >
      <div class="inline-flex fallback-break" v-if="cell.column.id === 'term'">
        <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
        <span
          class="'hidden -pl-4 text-gray-400 hover:text-gray-800 flex items-center'"
          v-touch:press="handleTouchStart"
          v-touch:release="handleTouchEnd"
          @touchmove="handleTouchMove"
        >
          <span
            translate="no"
            class="material-symbols-outlined notranslate pl-1 text-gray-400 -mb-2 text-lg cursor-move"
            >drag_indicator</span
          >
        </span>
      </div>
      <div v-else class="fallback-break" :style="{ width: `${cell.column.getSize()}px` }">
        <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
      </div>
    </td>
  </tr>
</template>

<style scoped>
/* Custom class to apply normal word breaks with fallback to break-all */
.fallback-break {
  overflow-wrap: break-word; /* Ensures long words are broken if needed */
  word-break: break-word; /* Standard word-breaking behavior */
}

.fallback-break::after {
  content: ''; /* Hack to trigger fallback to break-all if necessary */
  word-break: break-all; /* Fallback behavior to break in the middle of words */
}
</style>
