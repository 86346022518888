import { VocabItem, VocabItemUpdate } from '@/apiclient';

export const VocabItemDefault: VocabItemUpdate = {
  term: '',
  explanation: null,
  remarks: null,
  translations: {},
  audio_url: null,
  image_url: null,
};
