<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick, onBeforeMount } from 'vue';
import { getApiClient } from '@/apiclient/client';
import { useAlertStore } from '@/stores';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const alertStore = useAlertStore();

const fetchFinished = ref(false);

const approved = ref(false);
const isDragging = ref(false);

const allowedMediaTypes = ref([]);
const allowedMediaFormats = ref([]);
const allowedMediaSizes = ref([]);

const emit = defineEmits(['uploadedItem']);

const props = defineProps({
  borderRounded: {
    type: String,
    default: 'rounded-xl',
  },
  alwaysMaximized: {
    type: Boolean,
    default: true,
  },
  allowMaximize: {
    type: Boolean,
    default: true,
  },
  uploadFile: {
    type: Function,
    required: true,
  },
  uploadInProgress: {
    type: Boolean,
    default: false,
  },
  minimal: {
    type: Boolean,
    default: false,
  },
  allowImage: {
    type: Boolean,
    default: true,
  },
  allowAudio: {
    type: Boolean,
    default: true,
  },
  allowVideo: {
    type: Boolean,
    default: true,
  },
  headerText: {
    type: String,
    default: 'Medien',
  },
  heightPxWhenMinimized: {
    type: [String, null],
    default: null,
  },
  widthPxWhenMinimized: {
    type: [String, null],
    default: null,
  },
  darkBackground: {
    type: Boolean,
    default: false,
  },
  textWhenMinimized: {
    type: String,
    default: 'Datei hier ablegen',
  },
});

const hashtagOverlayId = computed(() => `#${props.overlayId}`);
const showMaximized = ref(false);
const idSuffix = ref('');

onBeforeMount(() => {
  idSuffix.value = uuidv4();
});

onMounted(async () => {
  let typesAndFormats = await (await getApiClient()).media.getAllowedSectionMediaTypes();
  let sizes = await (await getApiClient()).media.getAllowedSectionMediaFileSizes();

  if (!props.allowImage) {
    delete typesAndFormats.image;
  }
  if (!props.allowAudio) {
    delete typesAndFormats.audio;
  }
  if (!props.allowVideo) {
    delete typesAndFormats.video;
  }

  allowedMediaTypes.value = Object.keys(typesAndFormats);
  allowedMediaFormats.value = typesAndFormats;
  allowedMediaSizes.value = sizes;

  fetchFinished.value = true;

  if (props.alwaysMaximized) {
    showMaximized.value = true;
  }
});

const handleDragOver = (event) => {
  event.preventDefault();
  showMaximized.value = true;
  event.dataTransfer.dropEffect = 'copy';
  isDragging.value = true;
};

const handleDragLeave = (event) => {
  isDragging.value = false;
  if (!props.alwaysMaximized) {
    showMaximized.value = false;
  }
};

const debounceHandleDragOver = debounce(handleDragOver, 1);
const debounceHandleDragLeave = debounce(handleDragLeave, 1);

const toggleMaximized = () => {
  if (!props.allowMaximize) {
    return;
  }
  if (props.alwaysMaximized) {
    return;
  }
  showMaximized.value = !showMaximized.value;
};

const handleDrop = (event) => {
  console.log('drop');
  event.preventDefault();
  isDragging.value = false;
  props.uploadFile(event);
};
</script>

<template>
  <div
    class="flex flex-col overflow-hidden dark:bg-neutral-800 dark:border-neutral-700"
    :class="{
      'pointer-events-none opacity-50': props.uploadInProgress,
      'cursor-pointer': !props.alwaysMaximized,
    }"
    @click="toggleMaximized"
    :style="{
      height: props.heightPxWhenMinimized ? props.heightPxWhenMinimized + 'px' : 'auto',
      width: props.widthPxWhenMinimized ? props.widthPxWhenMinimized + 'px' : 'auto',
    }"
  >
    <!-- Drag 'n Drop -->
    <div
      v-if="!props.uploadInProgress"
      class="flex w-full h-full justify-center overflow-hidden border-2 border-gray-200 transition-all duration-300"
      :class="{
        'p-4 pt-[54px] md:pt-6 lg:pt-6 h-56': showMaximized,
        'p-1 pt-2 h-20': !showMaximized,
        'bg-gray-50 dark:bg-neutral-700': isDragging && !darkBackground,
        'bg-gray-500 dark:bg-neutral-700': isDragging && darkBackground,
        'bg-white dark:bg-neutral-800 border-dashed': !isDragging && !darkBackground,
        'bg-gray-600 dark:bg-neutral-800 border-dashed': !isDragging && darkBackground,
        [props.borderRounded]: true,
      }"
      @dragover.prevent="debounceHandleDragOver"
      @dragleave.prevent="debounceHandleDragLeave"
      @drop.prevent="handleDrop"
    >
      <div
        class="text-center text-gray-400 transition-all duration-300"
        :class="{
          'scale-105': isDragging,
        }"
      >
        <svg
          class="w-16 mx-auto"
          :width="showMaximized ? '70' : '35'"
          :height="showMaximized ? '46' : '23'"
          viewBox="0 0 70 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="{
            hidden: minimal && !showMaximized,
          }"
        >
          <path
            d="M6.05172 9.36853L17.2131 7.5083V41.3608L12.3018 42.3947C9.01306 43.0871 5.79705 40.9434 5.17081 37.6414L1.14319 16.4049C0.515988 13.0978 2.73148 9.92191 6.05172 9.36853Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="3"
            class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
          />
          <path
            d="M63.9483 9.36853L52.7869 7.5083V41.3608L57.6982 42.3947C60.9869 43.0871 64.203 40.9434 64.8292 37.6414L68.8568 16.4049C69.484 13.0978 67.2685 9.92191 63.9483 9.36853Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="3"
            class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
          />
          <rect
            x="17.0656"
            y="1.62305"
            width="35.8689"
            height="42.7541"
            rx="5"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="3"
            class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
          />
          <path
            d="M47.9344 44.3772H22.0655C19.3041 44.3772 17.0656 42.1386 17.0656 39.3772L17.0656 35.9161L29.4724 22.7682L38.9825 33.7121C39.7832 34.6335 41.2154 34.629 42.0102 33.7025L47.2456 27.5996L52.9344 33.7209V39.3772C52.9344 42.1386 50.6958 44.3772 47.9344 44.3772Z"
            stroke="currentColor"
            stroke-width="3"
            class="stroke-gray-400 dark:stroke-neutral-500"
          />
          <circle
            cx="39.5902"
            cy="14.9672"
            r="4.16393"
            stroke="currentColor"
            stroke-width="3"
            class="stroke-gray-400 dark:stroke-neutral-500"
          />
        </svg>

        <div
          class="justify-center text-sm pt-4"
          :class="{
            'flex flex-wrap h-full w-full': minimal && !showMaximized,
            hidden: !minimal || showMaximized,
            'text-gray-600': !darkBackground,
            'text-white': darkBackground,
          }"
        >
          <label :for="`hs-pro-epdupb-minimal-${idSuffix}`" class="relative cursor-pointer">
            <span class="text-base">
              <svg
                class="w-16 mx-auto -mt-1 pb-1"
                :width="showMaximized ? '70' : '35'"
                :height="showMaximized ? '46' : '23'"
                viewBox="0 0 70 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :class="{
                  hidden: showMaximized,
                }"
              >
                <path
                  d="M6.05172 9.36853L17.2131 7.5083V41.3608L12.3018 42.3947C9.01306 43.0871 5.79705 40.9434 5.17081 37.6414L1.14319 16.4049C0.515988 13.0978 2.73148 9.92191 6.05172 9.36853Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="3"
                  class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
                />
                <path
                  d="M63.9483 9.36853L52.7869 7.5083V41.3608L57.6982 42.3947C60.9869 43.0871 64.203 40.9434 64.8292 37.6414L68.8568 16.4049C69.484 13.0978 67.2685 9.92191 63.9483 9.36853Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="3"
                  class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
                />
                <rect
                  x="17.0656"
                  y="1.62305"
                  width="35.8689"
                  height="42.7541"
                  rx="5"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="3"
                  class="fill-white stroke-gray-400 dark:fill-neutral-800 dark:stroke-neutral-500"
                />
                <path
                  d="M47.9344 44.3772H22.0655C19.3041 44.3772 17.0656 42.1386 17.0656 39.3772L17.0656 35.9161L29.4724 22.7682L38.9825 33.7121C39.7832 34.6335 41.2154 34.629 42.0102 33.7025L47.2456 27.5996L52.9344 33.7209V39.3772C52.9344 42.1386 50.6958 44.3772 47.9344 44.3772Z"
                  stroke="currentColor"
                  stroke-width="3"
                  class="stroke-gray-400 dark:stroke-neutral-500"
                />
                <circle
                  cx="39.5902"
                  cy="14.9672"
                  r="4.16393"
                  stroke="currentColor"
                  stroke-width="3"
                  class="stroke-gray-400 dark:stroke-neutral-500"
                />
              </svg>

              {{ textWhenMinimized }}
            </span>
            <input
              :id="`hs-pro-epdupb-minimal-${idSuffix}`"
              type="file"
              class="sr-only"
              name="hs-pro-deuuf"
              multiple
              @change="props.uploadFile"
            />
          </label>
        </div>

        <div
          class="flex flex-wrap justify-center text-sm"
          :class="{
            hidden: minimal && !showMaximized,
            'mt-0.5': !showMaximized,
            'mt-2  leading-6': showMaximized,
            'text-gray-600': !darkBackground,
            'text-white': darkBackground,
          }"
        >
          <span class="font-medium text-gray-400 dark:text-neutral-200"> {{ headerText }}: Drag'n'Drop /&nbsp;</span>
          <label
            :for="`hs-pro-epdupb-${idSuffix}`"
            class="relative cursor-pointer font-semibold text-blue-600 hover:text-blue-700 rounded-lg decoration-2 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 dark:bg-neutral-800 dark:text-blue-500 dark:hover:text-blue-600"
          >
            <span class="">PC durchsuchen</span>
            <input
              :id="`hs-pro-epdupb-${idSuffix}`"
              type="file"
              class="sr-only"
              name="hs-pro-deuuf"
              multiple
              @change="props.uploadFile"
            />
          </label>
        </div>

        <div class="gap-y-1" v-show="showMaximized">
          <p
            v-for="mediaType in allowedMediaTypes"
            class="mt-1 hidden md:inline-flex text-xs text-gray-400 dark:text-neutral-400 lg:whitespace-nowrap"
          >
            <span class="font-semibold text-gray-600 text-xs">{{ mediaType }}:</span>
            &nbsp;{{ allowedMediaFormats[mediaType].join(' ').replaceAll(mediaType + '/', '') }}
            <span class="hidden lg:block text-xs">&nbsp;({{ allowedMediaSizes[mediaType] / 1024 / 1024 }}MB)</span>
          </p>
        </div>
      </div>
    </div>
    <div v-else class="p-4 h-56 flex justify-center items-center rounded-xl overflow-hidden border-2 border-gray-200">
      <LoadingSpinnerLarge />
    </div>
  </div>
  <!-- End Drag 'n Drop -->
</template>
