<script setup lang="ts">
import { getApiClient } from '@/apiclient/client';
import TextEditor from '@/views/courses/TextEditor.vue';
import { onBeforeUnmount, onMounted, ref, watch, nextTick } from 'vue';
import { debounce } from 'lodash';
import ChapterTableOfContents from '@/views/courses/ChapterTableOfContents.vue';
import Tooltip from '@/components/Tooltip.vue';
import Badge from '@/components/Badge.vue';
import { useAlertStore, useCourseStore } from '@/stores';
import { computed } from 'vue';
import ProgressButton from '@/components/ProgressButton.vue';
import RemoveButton from '@/components/RemoveButton.vue';
import UserConfirmationModal from '@/components/UserConfirmationModal.vue';
import LearningObjectives from '@/components/inputs/LearningObjectives.vue';
import { useI18n } from 'vue-i18n';
import MercuuBrandLogo from '@/components/MercuuBrandLogo.vue';

const { t } = useI18n();

const emit = defineEmits([
  'scrollTo',
  'toNextSection',
  'toPreviousSection',
  'toNextPage',
  'toPreviousPage',
  'onToggleIsEditing',
  'onAddPage',
]);
const titleEditor = ref(null);
const subtitleEditor = ref(null);
const learningObjectives = ref(null);
const userConformationModalDeleteSection = ref(null);
const courseStore = useCourseStore();
const alertStore = useAlertStore();
const leaveInvalidPageTimeout = ref(null);

const unsavedChanges = ref(false);

const props = defineProps({
  allowEditing: {
    type: Boolean,
    required: false,
    default: true,
  },
  allowToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  disableToggleEditing: {
    type: Boolean,
    required: false,
    default: false,
  },
  chapter: {
    type: Object,
    required: true,
  },
  section: {
    type: Object,
    required: true,
  },
  numVisibleSections: {
    type: Number,
    required: true,
  },
  pageIndex: {
    type: Number,
    required: true,
  },
  numPages: {
    type: Number,
    required: true,
  },
  computedHeight: {
    type: Number,
    required: true,
  },
});

const headerContainer = ref<HTMLElement | null>(null);
const fullHeight = ref(0);
const minHeight = ref(70);
const initialHeightMeasured = ref(false);
const isExpanded = ref(true);
const isFullExpanded = ref(true);
const HYSTERESIS = 10; // 10px buffer zone

const invalidPage = computed(() => {
  return props.pageIndex > props.numPages - 1 && props.numPages > 0;
});

const adjustHeight = async () => {
  await nextTick();
  if (headerContainer.value) {
    const height = headerContainer.value.offsetHeight;
    fullHeight.value = height + 16; // add 16px for the padding
    initialHeightMeasured.value = true;
  }
};

watch(
  () => [props.section?.learning_objectives, props.section?.title],
  async () => {
    await nextTick();
    adjustHeight();
  },
  { deep: true },
);

onMounted(async () => {
  await nextTick();
  setTimeout(adjustHeight, 100);
  setTimeout(adjustHeight, 500);
  window.addEventListener('resize', debounce(adjustHeight, 200));
  if (!props.section) {
    throw new Error('Section not found');
  }
  console.log('# learning objectives: ' + props.section.learning_objectives?.length);
  setLearningObjectives(props.section.learning_objectives);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustHeight);
  if (unsavedChanges) {
    saveTitleAndLearningObjectives();
  }
  if (props.allowEditing && !isPublished.value) {
    alertStore.info(
      'Bitte beachten Sie, dass die Kurseinheit (Section) ' +
        (props.chapter.index + 1) +
        '.' +
        (props.section.index + 1) +
        ' noch nicht veröffentlicht ist. ' +
        'Für Ihre Schüler:innen ist sie daher noch nicht sichtbar!',
      'Kurseinheit (Section) ist verborgen',
    );
  }
});

const isPublished = computed(() => {
  return props.section?.published_at != null;
});

function setLearningObjectives(sectionLearningObjectives: { description: string; importance: number }[]) {
  if (!sectionLearningObjectives) {
    return;
  }
  if (!learningObjectives.value) {
    console.warn('null ref in learningObjectives');
    return;
  }
  if (!props.section) {
    return;
  }
  // note: slice to avoid reactivity issues or in-place changes when field deleted
  learningObjectives.value.setLearningObjectives(sectionLearningObjectives.slice(), false);
}

const publishSection = (publish: boolean) => {
  console.log('publishing: ' + publish);
  if (!props.section) {
    return;
  }
  courseStore
    .publishSection(props.section.id, publish)
    .then(() => {
      alertStore.success(
        publish
          ? 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteinen ist jetzt für die Kursteilnehmer:innen sichtbar.'
          : 'Abschnitt ' +
              (props.chapter.index + 1) +
              '.' +
              (props.section.index + 1) +
              ' mit allen Lernbausteinen ist jetzt vor den Kursteilnehmer:innen verborgen.',
      );
    })
    .catch((error: any) => {
      alertStore.error('Fehler beim Veröffentlichen/ Verbergen: ' + error);
      throw Error('Error publishing section', error);
    });
};

watch(
  () => unsavedChanges.value,
  (newValue) => {
    if (newValue) {
      if (!props.allowEditing) return;
      setTimeout(() => {
        saveTitleAndLearningObjectives();
        unsavedChanges.value = false;
      }, 3000);
    }
  },
);

const saveTitleAndLearningObjectives = async () => {
  if (!props.allowEditing) return;
  if (!titleEditor.value) return;
  courseStore
    .updateSection(props.section.id, {
      title: titleEditor.value.getRawTextContent(),
      learningObjectives: learningObjectives.value.getLearningObjectives(),
    })
    .then(() => {
      unsavedChanges.value = false;
      // alertStore.success('Titel und Lernziele gespeichert', 'Gespeichert');
      titleEditor.value?.resetEmitState();
    })
    .catch((error) => {
      console.error(error);
      alertStore.error('Failed to autosave title', 'Error', error);
      throw new Error('Failed to autosave title');
    });
};

const onDeleteSectionIfEmpty = async () => {
  if (props.numPages > 0) {
    alertStore.error(
      'Löschen nicht möglich, da noch Lernbausteine vorhanden sind. Bitte löschen Sie zuerst alle Lernbausteine.',
      'Unzulässig',
    );
    return;
  }
  if (!userConformationModalDeleteSection.value) {
    return;
  }
  await userConformationModalDeleteSection.value.promptUserConformation();
};

const deleteSection = async () => {
  alertStore.info('Noch nicht implementiert');
};

const screenIsMdOrLarger = computed(() => {
  return window.innerWidth > 768;
});

// This models hysteresis
const shouldShowExpanded = computed(() => {
  if (isExpanded.value) {
    // When expanded, only collapse if we go significantly below threshold
    return props.computedHeight > minHeight.value + 12 - HYSTERESIS || screenIsMdOrLarger.value;
  } else {
    // When collapsed, only expand if we go significantly above threshold
    return props.computedHeight > minHeight.value + 12 + HYSTERESIS || screenIsMdOrLarger.value;
  }
});

const shouldShowFullExpanded = computed(() => {
  if (isFullExpanded.value) {
    return props.computedHeight > fullHeight.value - 12 - HYSTERESIS || screenIsMdOrLarger.value;
  }
  return props.computedHeight > fullHeight.value - 12 + HYSTERESIS || screenIsMdOrLarger.value;
});

// Watch for changes and update the expansion state
watch(shouldShowExpanded, (newValue) => {
  isExpanded.value = newValue;
});

watch(shouldShowFullExpanded, (newValue) => {
  isFullExpanded.value = newValue;
});

watch(invalidPage, (newValue) => {
  if (newValue) {
    leaveInvalidPageTimeout.value = setTimeout(() => {
      emit('toPreviousPage');
    }, 1000);
  } else if (leaveInvalidPageTimeout.value) {
    clearTimeout(leaveInvalidPageTimeout.value);
    leaveInvalidPageTimeout.value = null;
  }
});

defineExpose({
  titleEditor,
  subtitleEditor,
  fullHeight,
  minHeight,
});
</script>

<template>
  <div
    ref="headerContainer"
    class="w-full relative mx-auto w-full flex flex-col justify-end overflow-hidden transition-all duration-200 ease-out"
    :class="{
      'pt-4': isFullExpanded,
      'pt-0': !isFullExpanded,
    }"
  >
    <div
      class="w-full transition-[color] duration-300 flex space-y-5 md:space-y-8 h-fit px-4 pt-6 lg:pt-10 pb-4 lg:pb-10 sm:px-6 lg:px-8 shadow-sm dark:bg-neutral-900 dark:border-gray-700"
      :class="{
        'rounded-xl border border-gray-200 bg-white': isExpanded,
        'rounded-none border-b border-gray-200 bg-blue-50': !isExpanded,
      }"
    >
      <div class="w-full h-fit flex space-y-3 justify-center">
        <div class="w-full flex h-fit space-y-3 justify-center">
          <h2 class="w-full flex-col h-fit flex text-4xl text-center font-bold md:text-5xl dark:text-white">
            <div
              class="inline-flex w-full relative justify-between items-center text-base pb-6"
              v-show="props.allowToggleEditing"
            >
              <!-- Delete section -->
              <div>
                <button
                  v-show="props.allowEditing"
                  class="h-10 w-10 flex items-center text-center justify-center bg-red-600 hover:bg-red-700 shadow-md text-white rounded-[4px] text-sm dark:text-gray-400 dark:hover:text-gray-200 font-medium"
                  :class="{ 'opacity-50': numPages > 0 }"
                  @click.prevent="onDeleteSectionIfEmpty"
                >
                  <span translate="no" class="material-symbols-outlined notranslate text-3xl">delete</span>
                </button>
              </div>

              <!-- Publish -->
              <div v-show="props.allowEditing" class="inline-flex justify-end items-center">
                <div class="font-normal text-gray-500 dark:text-gray-200">
                  Status
                  <Tooltip
                    :placement-top="false"
                    message="Klicken zum Veröffentlichen des Kursabschnitts.
                            Veröffentlichte Abschnitte inkl. aller Lernbausteine sind für alle Nutzer:innen sichtbar, die
                            Zugriff auf den entsprechenden Kurs haben - für alle anderen natürlich nicht!"
                  />
                </div>
                <div class="group cursor-pointer" @click.prevent="publishSection(!isPublished)">
                  <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="!isPublished">
                    <Badge text="Unpublished draft" color="gray" :showDot="true" fontSize="text-sm" />
                  </div>
                  <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="!isPublished">
                    <Badge text="Publish draft" color="blue" :showDot="false" fontSize="text-sm" icon="publish" />
                  </div>
                  <div class="ml-4 pb-0.5 block group-hover:hidden" v-if="isPublished">
                    <Badge text="Section is live" color="green" :showDot="true" :pulse="true" fontSize="text-sm" />
                  </div>
                  <div class="ml-4 pb-0.5 hidden group-hover:block" v-if="isPublished">
                    <Badge
                      text="Hide section"
                      color="blue"
                      :showDot="false"
                      :pulse="true"
                      icon="hide_source"
                      fontSize="text-sm"
                    />
                  </div>
                </div>
              </div>

              <span class="absolute font-normal end-12 text-gray-500">Bearbeiten </span>
              <input
                :checked="props.allowEditing"
                @change="emit('onToggleIsEditing', $event.target.checked)"
                :invalidPage="props.disableToggleEditing"
                type="checkbox"
                id="hs-xs-switch-edit-chapter"
                class="relative w-[35px] h-[21px] bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-blue-600 invalidPage:opacity-50 invalidPage:pointer-events-none checked:bg-none checked:text-blue-600 checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-600 dark:checked:border-blue-600 dark:focus:ring-offset-gray-600 before:inline-block before:w-4 before:h-4 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400 dark:checked:before:bg-blue-200"
              />
            </div>

            <span class="w-full justify-between items-center inline-flex text-gray-300 font-bold text-3xl">
              <span
                v-if="props.section.index > 0"
                @click="emit('toPreviousSection')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_left</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
              <span class="inline-flex">
                <span class="">{{ props.chapter.index + 1 }}.{{ props.section.index + 1 }}</span>
              </span>
              <span
                v-if="props.section.index < props.numVisibleSections - 1"
                @click="emit('toNextSection')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
            </span>
            <TextEditor
              ref="titleEditor"
              :content="props.section.title"
              :allowFormatting="false"
              @unsavedChanges="unsavedChanges = true"
              @changesCleared="unsavedChanges = false"
              :allow-edit="props.allowEditing"
              editor-classes="pb-1"
            />

            <p class="w-full text-xs md:text-sm text-justify text-gray-800 dark:text-gray-200 font-semibold pt-8 mb-3">
              Lernziele
            </p>
            <div class="w-full space-y-3 text-gray-500 font-normal text-left">
              <LearningObjectives
                ref="learningObjectives"
                :maxNumber="5"
                :editable="props.allowEditing"
                @requestCompute="emit('onExtractLearningObjectives')"
                @change="unsavedChanges = true"
              />
            </div>

            <span
              class="w-full pt-8 justify-between items-center inline-flex text-gray-300 font-bold text-2xl md:text-3xl"
            >
              <span
                v-if="props.pageIndex > 0"
                @click="emit('toPreviousPage')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_left</span
                >
              </span>
              <span v-else class="w-[36px]"></span>

              <span class="inline-flex items-center">
                <span v-if="!!invalidPage" class="inline-flex">
                  <span
                    class="animate-spin inline-block w-8 h-8 border-[3px] border-blue-600 border-current border-t-transparent text-blue-600 rounded-full mr-6"
                  />
                </span>
                <span v-else-if="props.numPages > 0" class="inline-flex">
                  <span class="">Lernbaustein {{ props.pageIndex + 1 }} von {{ props.numPages }}</span>
                  <span class="pl-4"></span>
                </span>
                <span v-else class="text-xl pr-5"> Noch kein Lernbaustein vorhanden </span>
                <ProgressButton
                  v-show="props.allowEditing"
                  :text="t('message.addParagraph')"
                  icon="add"
                  iconSize="text-3xl"
                  :roundedFull="true"
                  @click="emit('onAddPage')"
                />
              </span>

              <span
                v-if="props.pageIndex < props.numPages - 1"
                @click="emit('toNextPage')"
                class="h-8 w-8 hover:bg-gray-50 flex items-center justify-center text-center border border-gray-300 hover:border-gray-500 rounded-lg text-gray-300 hover:text-gray-500 cursor-pointer"
              >
                <span translate="no" class="no-translate select-none material-symbols-outlined text-4xl"
                  >chevron_right</span
                >
              </span>
              <span v-else class="w-[36px]"></span>
            </span>
          </h2>
        </div>
      </div>
    </div>
  </div>

  <UserConfirmationModal
    ref="userConformationModalDeleteSection"
    prompt_message=""
    approve_message="Ja, löschen"
    discard_message="Nein, zurück"
    approve_color="bg-red-600 hover:bg-red-700"
    :overlayId="'confirmDeleteSection' + props.section.id"
    @approved="deleteSection"
  >
  </UserConfirmationModal>
</template>

<style scoped></style>
