import { CaseInteraction, CaseInteractionList, Evaluation } from '@/views/case-interactions';
import { LoggedInRoot } from '@/views/root';

export default {
  path: '/case-interactions',
  component: LoggedInRoot,
  children: [
    {
      path: '/case-interactions/:caseInteractionId',
      component: CaseInteraction,
      meta: { forceNewRouterKey: 'case-interaction-main' },
    },
    {
      path: '/case-interactions/:caseInteractionId/evaluation',
      component: Evaluation,
      meta: { forceNewRouterKey: 'case-interaction-evaluation' },
    },
    {
      path: '/users/:userId/case-interactions',
      component: CaseInteractionList,
      meta: { forceNewRouterKey: 'case-interaction-list' },
    },
  ],
};
