<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useAlertStore } from '@/stores';

const props = defineProps({
  position: { type: Object, required: true },
  audioUrl: { type: String, required: true },
  autoplay: { type: Boolean, required: false, default: false },
  bodyClickCallback: { type: Function, required: false, default: null },
  termToDisplay: { type: String, required: false, default: '' },
});

const alertStore = useAlertStore();

const emit = defineEmits(['close']);
const audioPlayer = ref(null);

onMounted(async () => {
  // wait 200 ms
  await new Promise((resolve) => setTimeout(resolve, 200));
  if (!audioPlayer.value) {
    return;
  }
  audioPlayer.value.play().catch((error) => {
    alertStore.error('Failed to auto-play audio', 'Error', error);
  });
});
</script>

<template>
  <div
    :style="{ top: `${position.y}px`, left: `${position.x}px` }"
    class="fixed bg-white shadow-md rounded-lg border border-gray-200 z-50 text-xs md:text-sm"
    @click.prevent="
      (event) => {
        if (props.bodyClickCallback) {
          props.bodyClickCallback(event);
          return;
        }
        emit('close', event);
      }
    "
  >
    <div class="relative w-full h-full px-2 pb-2 pt-8">
      <audio ref="audioPlayer" controls controlsList="nodownload" oncontextmenu="return false;" class="h-12">
        <source :src="props.audioUrl" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      <span
        translate="no"
        @click.prevent="emit('close')"
        class="absolute top-1 end-1 material-symbols-outlined notranslate cursor-pointer text-gray-400 hover:text-gray-500"
      >
        close
      </span>
      <span v-show="!!props.termToDisplay" class="absolute top-1 start-1 italic">
        "{{ props.termToDisplay }}" (Grundform)
      </span>
    </div>
  </div>
</template>

<style scoped></style>
