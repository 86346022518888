<script setup lang="ts">
import SimpleStatsCard from '@/components/dashboard/SimpleStatsCard.vue';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { debounce } from 'lodash';
import { getApiClient } from '@/apiclient/client';

const userVocabStats = ref({});

const outerVocabDashboardStatsHeaderContainer = ref<HTMLElement | null>(null);
const fullHeight = ref(184);

const adjustHeight = () => {
  if (outerVocabDashboardStatsHeaderContainer.value) {
    fullHeight.value = outerVocabDashboardStatsHeaderContainer.value.offsetHeight;
  }
};

const debouncedAdjustHeight = debounce(adjustHeight, 200);

onBeforeUnmount(() => {
  window.removeEventListener('resize', debouncedAdjustHeight);
});

onMounted(async () => {
  // await new Promise((resolve) => setTimeout(resolve, 10));
  console.log('VocabDashboardStatsHeader mounted: ', outerVocabDashboardStatsHeaderContainer.value);
  console.log('height is:', outerVocabDashboardStatsHeaderContainer.value?.offsetHeight);
  await nextTick(async () => {
    await new Promise((resolve) => setTimeout(resolve, 10));
    adjustHeight();
    window.addEventListener('resize', debouncedAdjustHeight);
  });

  userVocabStats.value = await (await getApiClient()).users.getCurrentUserVocabTestStats();
  console.log('userVocabStats:', JSON.stringify(userVocabStats.value));
});

defineExpose({
  fullHeight,
  refetchStats: async () => {
    userVocabStats.value = await (await getApiClient()).users.getCurrentUserVocabTestStats();
  },
});
</script>

<template>
  <!-- Stats Grid -->
  <!-- TODO i18n -->
  <div
    ref="outerVocabDashboardStatsHeaderContainer"
    class="grid select-none overflow-visible h-fit grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 md:gap-3 xl:gap-3"
  >
    <div class="row-span-2 hidden xl:block">
      <SimpleStatsCard
        title="Wortschatz (vocabulary)"
        :value="userVocabStats.number_of_unique_vocabs_tested"
        :unit="userVocabStats.number_of_unique_vocabs_tested == 1 ? 'word' : 'words'"
        recentChangeUnit=""
        :recent-change="
          userVocabStats.number_of_unique_vocabs_tested -
          userVocabStats.number_of_unique_vocabs_tested_until_one_week_ago
        "
        :showCentered="true"
        :increasedFontSize="true"
      />
      <!-- TODO anzahl element in wortschatzkiste hinzufügen ? (sofern verschieden von elementen in unique tested)-->
    </div>
    <div class="block xl:hidden">
      <SimpleStatsCard
        title="Wortschatz (vocabulary)"
        :value="userVocabStats.number_of_unique_vocabs_tested"
        :unit="userVocabStats.number_of_unique_vocabs_tested == 1 ? 'word' : 'words'"
        recentChangeUnit=""
        :recent-change="
          userVocabStats.number_of_unique_vocabs_tested -
          userVocabStats.number_of_unique_vocabs_tested_until_one_week_ago
        "
      />
    </div>
    <SimpleStatsCard
      title="Current score"
      :value="Math.round(userVocabStats.current_score_last_100 * 100)"
      unit="%"
      :recentChange="Math.round((userVocabStats.current_score_last_100 - userVocabStats.previous_score_last_100) * 100)"
    />
    <SimpleStatsCard
      title="All-time best (daily)"
      :value="Math.round(userVocabStats.all_time_best_daily * 100)"
      unit="%"
    />
    <div class="hidden md:block">
      <SimpleStatsCard
        title="Tests taken per week"
        :value="userVocabStats.num_tests_last_week"
        :unit="userVocabStats.num_tests_last_week == 1 ? 'test' : 'tests'"
        recentChangeUnit=""
        :recent-change="userVocabStats.num_tests_last_week - userVocabStats.num_tests_week_before"
      />
    </div>
    <SimpleStatsCard
      title="Current streak"
      :value="userVocabStats.current_streak_days"
      :unit="userVocabStats.current_streak_days == 1 ? 'day' : 'days'"
    />
    <SimpleStatsCard
      title="Longest streak"
      :value="userVocabStats.longest_streak_days"
      :unit="userVocabStats.current_streak_days == 1 ? 'day' : 'days'"
    />
    <SimpleStatsCard
      title="Ever tested"
      :value="userVocabStats.number_of_vocabs_tested"
      :unit="userVocabStats.number_of_vocabs_tested == 1 ? 'word' : 'words'"
      recentChangeUnit=""
      :recent-change="
        userVocabStats.number_of_vocabs_tested - userVocabStats.number_of_vocabs_tested_until_one_week_before
      "
    />
    <!-- <SimpleStatsCard
      title="Wortschatz (vocabulary)"
      :value="userVocabStats.number_of_unique_vocabs_tested"
      :unit="userVocabStats.number_of_unique_vocabs_tested == 1 ? 'word' : 'words'"
      recentChangeUnit=""
      :recent-change="
        userVocabStats.number_of_unique_vocabs_tested - userVocabStats.number_of_unique_vocabs_tested_until_one_week_ago
      "
    /> -->
  </div>
  <!-- End Stats Grid -->
</template>

<style scoped></style>
