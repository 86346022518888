export function mapTitle(text: string) {
  if (!text) {
    return '';
  }
  text = text.replace(/PROF_DR/g, 'Prof. Dr.');
  text = text.replace(/DR/g, 'Dr.');
  return text;
}

export function mapJobStatus(job: string) {
  if (!job) {
    return '';
  }
  return job.charAt(0).toUpperCase() + job.slice(1).toLowerCase();
}

export function formatDate(date: string) {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
}

export function unobfuscateUserName(message: string, first_name: string, last_name: string) {
  if (!message) {
    return '';
  }
  return message.replace('Yvä', first_name).replace('Xöl', last_name);
}

export function toRoman(num: number): string {
  const romanNumerals: [number, string][] = [
    [1000, 'M'],
    [900, 'CM'],
    [500, 'D'],
    [400, 'CD'],
    [100, 'C'],
    [90, 'XC'],
    [50, 'L'],
    [40, 'XL'],
    [10, 'X'],
    [9, 'IX'],
    [5, 'V'],
    [4, 'IV'],
    [1, 'I'],
  ];
  let result = '';

  for (const [value, numeral] of romanNumerals) {
    while (num >= value) {
      result += numeral;
      num -= value;
    }
  }
  return result;
}

export function headingToId(heading: string) {
  // Replace special characters with their corresponding CSS escaped characters
  let selector = heading.replace(/[^a-zA-Z0-9-]/g, (match) => {
    return match.charCodeAt(0).toString(16).toUpperCase() + ' ';
  });

  selector = selector.trim();
  selector = selector.replace(/\s+/g, '-');
  selector = 'section-' + selector; // Add a prefix to avoid starting with a number

  return selector;
}
