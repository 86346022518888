<script setup lang="ts">
import { computed, nextTick, onMounted, ref } from 'vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';
import IngameProgressCard from '@/views/home/IngameProgressCard.vue';
import ProfileImage from '@/components/ProfileImage.vue';
import { useAuthStore, useCaseStore } from '@/stores';
import CourseInteractionList from '@/views/courses/CourseInteractionList.vue';
import { router } from '@/router';
import { getApiClient } from '@/apiclient/client';

const caseInteractions = ref([]);
import { CaseInteraction } from '@/apiclient';
import CaseHighlightList from '@/components/CaseHighlightList.vue';
import VocabDashboardStatsHeader from '@/components/didactics/VocabDashboardStatsHeader.vue';
import IconWithUnreadMark from '@/components/IconWithUnreadMark.vue';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const { dailyCasePlayed } = storeToRefs(authStore);
const caseStore = useCaseStore();
const { allCases } = storeToRefs(caseStore);
const dailyVocabTestPlayed = ref(false);

const fetchCompleted = ref(false);
const showWelcome = ref(true);
const animationClass = ref('fly-in');
const showCompletion = ref(false);
const clickedDailyPatient = ref(false);
const clickedDailyVocabTest = ref(false);
const completion = ref('');
const possibleCompletions = ['bei casuu ❤️', 'bei casuu \uD83D\uDCAA', 'zurück ❤️', 'zurück 🏋️‍♂️', 'im Spiel 🎮'];

const emit = defineEmits(['takeDailyQuickTest']);

const casesOfMyCaseInteractions = computed(() => {
  let cases = caseInteractions.value.map((caseInteraction: CaseInteraction) => {
    return caseInteraction.case;
  });
  return cases.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
});

async function loadCaseInteractionsOfUser() {
  const client = await getApiClient();
  caseInteractions.value = await client.users
    .listCaseInteractionsOfUser(authStore.userId)
    .then((caseInteractions: CaseInteraction[]) => {
      return caseInteractions;
    })
    .catch((error) => {
      console.error('Error loading case interactions of user: ' + error);
      return [];
    })
    .finally(() => {});
}

async function playDailyCase() {
  if (allCases.value.length === 0) {
    await caseStore.fetchAllCases();
  }
  await nextTick();
  const randomCase = allCases.value[Math.floor(Math.random() * allCases.value.length)];
  await router.push('/cases/' + randomCase.id);
}

onMounted(async () => {
  await nextTick();
  console.log('UserDashboard mounted');

  // set random completion
  completion.value = possibleCompletions[Math.floor(Math.random() * possibleCompletions.length)];

  setTimeout(() => {
    showCompletion.value = true;
  }, 1200);

  setTimeout(() => {
    animationClass.value = 'fly-in-highlight';
  }, 1400);

  setTimeout(() => {
    animationClass.value = 'fly-out';
  }, 2000);

  // Remove the element after animation ends
  setTimeout(() => {
    showWelcome.value = false;
  }, 2600); // 500ms for fly-in + 500ms hold + 500ms fly-out

  await authStore.fetchUserDetails(); // wo dont know if case played else, etc.

  await loadCaseInteractionsOfUser().finally(() => {
    fetchCompleted.value = true;
  });
});
</script>

<template>
  <div class="relative w-full h-full overflow-y-auto flex px-2">
    <div
      v-if="fetchCompleted"
      class="relative w-full h-fit lg:w-[1000px] bg-white rounded-xl shadow-sm mx-auto flex-col flex gap-y-8 my-4 px-4 lg:px-6 py-4"
    >
      <!-- header: progress + profile image -->
      <div class="inline-flex items-center justify-start lg:justify-end gap-x-4 top-5 right-4 sm:right-7">
        <div class="z-0 pt-14">
          <IngameProgressCard />
        </div>
        <div class="z-10 -mt-8 -ml-16 group relative cursor-pointer" @click="() => router.push({ name: 'Profile' })">
          <ProfileImage
            :image="authStore.userProfileImageLarge"
            :initials="authStore.userInitials"
            :showIngameLevel="false"
            size="148px"
          />
          <span
            class="transition-opacity opacity-0 group-hover:opacity-80 duration-300 flex text-white items-center justify-center text-2xl text-center top-0 start-0 absolute rounded-full h-[148px] w-[148px] bg-gray-500 cursor-pointer"
          >
            Profil bearbeiten
          </span>
        </div>
      </div>

      <!-- my news -->
      <!-- TODO: link forum news and incoming teacher mail -->
      <div class="flex-col flex justify-start space-y-2 mt-4">
        <h2 class="text-2xl font-bold dark:text-white">Meine<a class="text-blue-600"> News</a></h2>
        <div class="flex-col flex gap-y-8">
          <!-- patient case played? -->
          <div v-show="!dailyCasePlayed" class="flex-col flex" @click="playDailyCase">
            <div class="inline-flex items-center">
              <p class="pt-6 pl-2 pr-4">
                <IconWithUnreadMark :mark-unread="true" :animate-ping="true" />
              </p>
              <p class="text-base text-gray-500 dark:text-gray-400 w-full text-justify">
                Ein Patient wartet auf dich! 🚑 🚨
              </p>
            </div>
            <p
              class="group inline-flex items-center cursor-pointer text-blue-600 hover:text-blue-700"
              :class="{ 'animate-separate': clickedDailyPatient }"
            >
              <span translate="no" class="no-translate select-none material-symbols-outlined"> chevron_right </span>
              Jetzt spielen
            </p>
          </div>

          <!-- vocab test played? -->
          <div
            v-show="!dailyVocabTestPlayed"
            class="flex-col flex"
            @click="
              () => {
                console.log('MainHeader: forwarding event takeDailyQuickTest');
                dailyVocabTestPlayed = true;
                emit('takeDailyQuickTest');
              }
            "
          >
            <div class="inline-flex items-center">
              <p class="pt-6 pl-2 pr-4">
                <IconWithUnreadMark :mark-unread="true" :animate-ping="true" />
              </p>
              <p class="text-base text-gray-500 dark:text-gray-400 w-full text-justify">
                Nimm dir einen Moment, um deine Vokabeln und Redemittel zu wiederholen! 📚👓
              </p>
            </div>
            <p
              class="group inline-flex items-center cursor-pointer text-blue-600 hover:text-blue-700"
              :class="{ 'animate-separate': clickedDailyPatient }"
            >
              <span translate="no" class="no-translate select-none material-symbols-outlined"> chevron_right </span>
              Jetzt üben
            </p>
          </div>

          <!-- else: nothing to doe -->
          <p
            v-show="dailyCasePlayed && dailyVocabTestPlayed"
            class="text-base italic text-gray-500 dark:text-gray-400 w-full text-justify"
          >
            Keine Neuigkeiten. Sprich weiter mit virtuellen und echten Patienten und Freunden und Kollegen so viel du
            kannst! 💪
          </p>
        </div>
      </div>

      <!-- vocab -->
      <!-- TODO: link forum news and incoming teacher mail -->
      <div class="flex-col flex justify-start space-y-2 mt-4">
        <h2 class="text-2xl font-bold dark:text-white">Meine<a class="text-blue-600"> Redemittel</a></h2>
        <p class="text-base pt-6 text-italic text-gray-500 dark:text-gray-400 w-full text-justify">
          <VocabDashboardStatsHeader />
        </p>
        <p class="text-base text-gray-500 dark:text-gray-400 w-full text-justify pt-4">
          Jene neue Vokabel, der du in deinem Test begegnest oder die du in deiner Wortschatzkiste sammelst, zählt zu
          deinem Wortschatz. Also Vokabeln sammeln und Tests machen! 💪👓
        </p>
      </div>

      <!-- my course progress -->
      <div class="flex-col flex justify-start space-y-2 mt-4">
        <h2 class="text-2xl font-bold dark:text-white">Meine<a class="text-blue-600"> Kurse</a></h2>
        <p class="text-base pt-6 text-gray-500 dark:text-gray-400 w-full text-justify">
          <CourseInteractionList :inline-overflowing-x="true" />
        </p>
      </div>

      <!-- my last patients -->
      <div class="flex-col flex justify-start space-y-2 mt-4">
        <h2 class="text-2xl font-bold dark:text-white">Meine<a class="text-blue-600"> Patienten</a></h2>
        <p class="text-base pt-6 text-gray-500 dark:text-gray-400 w-full text-justify">
          <CaseHighlightList :cases="casesOfMyCaseInteractions" :inline-overflowing-x="true" />
        </p>
      </div>
    </div>
    <!-- end contents -->

    <!-- loading spinner -->
    <div class="absolute items-center justify-center h-full w-full flex" v-else>
      <LoadingSpinnerLarge />
    </div>

    <!-- welcome animation -->
    <div
      v-if="showWelcome"
      :class="animationClass"
      class="z-20 absolute w-full mt-40 flex justify-start ml-6 lg:-ml-32 lg:justify-center text-4xl md:text-5xl text-gray-800 font-normal"
    >
      Willkommen
      <span class="relative inline-flex">
        <span v-if="showCompletion" class="absolute -right-27 inline-flex font-normal"
          >&nbsp;<span class="text-blue-600 font-normal inline-flex whitespace-nowrap">{{ completion }}</span></span
        >
      </span>
    </div>
  </div>
</template>

<style scoped>
/* Fly-in animation */
@keyframes fly-in {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes fly-in-highlight {
  0% {
    transform: translateX(0%);
    scale: 100%;
  }
  50% {
    transform: translateX(0%);
    scale: 102%;
  }
  100% {
    transform: translateX(0%);
    scale: 100%;
  }
}

/* Fly-out animation */
@keyframes fly-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

/* Animation classes */
.fly-in {
  animation: fly-in 1s ease-out forwards;
}

.fly-in-highlight {
  animation: fly-in-highlight 0.3s forwards;
}

.fly-out {
  animation: fly-out 0.6s ease-in forwards;
}

@keyframes highlight {
  0% {
    scale: 100%;
  }
  50% {
    scale: 101%;
  }
  100% {
    scale: 100%;
  }
}

.highlight-in {
  animation: highlight 0.5s ease-in-out;
}
</style>
