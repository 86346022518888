<script setup lang="ts">
import PatientCarousel from '@/components/CaseCarousel.vue';
import { nextTick, onMounted, reactive, ref } from 'vue';
import { useCaseStore } from '@/stores';
import MainHeader from '@/components/headers/MainHeader.vue';
import SocialIcon from '@/views/about-us/SocialIcon.vue';
import PartnerCarousel from '@/views/landing/PartnerCarousel.vue';
import BlogpostCarousel from '@/views/landing/BlogpostCarousel.vue';
import DarkModeToggle from '@/components/DarkModeToggle.vue';

import { mountState } from '@/helper';
import PublicFooter from '@/views/landing/PublicFooter.vue';
import LoadingSpinnerLarge from '@/components/LoadingSpinnerLarge.vue';

const partnerCarousel = ref(null);
const blogpostCarousel = ref(null);
const isLoadingCases = ref(true);
const isLoadingCarousels = ref(true);
const skipWaitingForPageMount = ref(false);
const caseStore = useCaseStore();
const completeHeading = ref('Casuu');
const cases = ref([] as object[]);
const headingCompletions = ['Casual', 'Case-based', 'Casuu'];

onMounted(async () => {
  // heading completions
  let i = 0;
  setInterval(() => {
    completeHeading.value = headingCompletions[i];
    i++;
    if (i === headingCompletions.length) {
      i = 0;
    }
  }, 2000);

  setTimeout(() => {
    skipWaitingForPageMount.value = true;
  }, 1200);

  // cases.value = await caseStore.getTrendingCases();
  isLoadingCases.value = false;
});

const carousels = reactive({
  splide1: null,
  splide2: null,
});

const onCarouselMounted = (splide: any) => {
  if (!carousels.splide1) {
    carousels.splide1 = splide;
  } else if (!carousels.splide2) {
    carousels.splide2 = splide;
  }

  if (carousels.splide1 && carousels.splide2) {
    carousels.splide1.sync(carousels.splide2);
    console.log('Synchronization successful');
    isLoadingCarousels.value = false;
  }
};
</script>

<template>
  <div
    v-show="mountState.portionMounted <= 0.8 && !skipWaitingForPageMount"
    class="flex flex-col h-screen w-screen overflow-y-auto bg-gray-50 dark:bg-neutral-800 text-center items-center px-4 sm:px-6 lg:px-8 pt-29"
  >
    <h1 class="block font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl dark:text-gray-200 mt-[68px]">
      Medical & language training<br />
      <div class="bg-clip-text bg-blue-600 text-transparent">
        {{ completeHeading }}
      </div>
    </h1>
    <div class="mt-28">
      <LoadingSpinnerLarge />
    </div>
  </div>
  <div
    v-show="mountState.portionMounted > 0.8 || skipWaitingForPageMount"
    class="flex flex-col h-screen overflow-y-auto overflow-x-hidden bg-gray-50 dark:bg-neutral-800"
  >
    <header
      class="flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full border-b border-gray-200 dark:border-none bg-white text-sm py-3 md:py-0 dark:bg-neutral-900"
    >
      <MainHeader
        :showCommunityLink="true"
        :showRootLink="true"
        :showCoursesDropdown="true"
        :showPatientCaseDropdown="false"
        :showChallengesAndEventsLink="false"
        :showVocabDropdown="false"
        :showAboutUsLink="true"
        :showSignInOrEditProfile="true"
      />
    </header>

    <div
      class="relative flex-grow before:absolute before:top-0 before:left-1/2 before:bg-[url('../svg/component/polygon.svg')] before:bg-no-repeat before:bg-top before:bg-cover before:w-full before:h-full before:-z-[1] before:transform before:-translate-x-1/2 dark:before:bg-[url('../svg/component/polygon-dark.svg')]"
    >
      <div class="max-w-full overflow-x-hidden mx-auto px-4 sm:px-6 lg:px-8 pt-24">
        <!-- Title -->

        <div class="mt-5 max-w-6xl text-center mx-auto">
          <h1 class="block font-bold text-gray-800 text-4xl md:text-5xl lg:text-6xl dark:text-gray-200">
            Medical & language training<br />
            <div class="bg-clip-text bg-blue-600 text-transparent">
              {{ completeHeading }}
            </div>
          </h1>
        </div>
        <!-- End Title -->

        <div class="mt-5 max-w-4xl text-center mx-auto">
          <p class="text-lg text-gray-600 dark:text-gray-400 text-center">
            casuu bietet virtuelle Trainingsszenarien für die fachsprachliche Aus- und Weiterbildung Ihrer
            nicht-muttersprachlichen Ärzt:innen und Pflegekräfte. So realistisch, fordernd, variabel und nah an Ihrer
            Patientenklientel wie die Ausbildung on-the-job. Hands on - aber virtuell.
          </p>
        </div>
        <!--        <div class="mt-5 max-w-4xl text-center mx-auto">-->
        <!--          <p class="text-lg text-gray-600 dark:text-gray-400 text-center">-->
        <!--            casuu ist-->
        <!--            <a href="/about-us" class="hover:text-gray-400">ein Team aus der Medizin und ärztlich geführt</a> - wir-->
        <!--            kennen Ihre Anforderungen. Sprechen Sie uns an!-->
        <!--          </p>-->
        <!--        </div>-->

        <video
          class="mt-5 mb-3 md:max-w-2xl rounded-lg shadow-lg mx-auto"
          controls
          controlsList="nodownload"
          oncontextmenu="return false;"
          src="https://assets-prd.data.casuu.health/Kurzvorstellung%20casuu%20v2.mp4"
        />

        <!-- Buttons -->
        <router-link to="/about-us">
          <div class="mt-5 w-full inline-flex justify-center">
            <a
              class="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
            >
              Team kontaktieren
              <svg class="w-3 h-3" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
            </a>
          </div>
        </router-link>

        <div class="flex-col flex items-center text-center space-y-5 mt-24 lg:mt-32 md:-mb-[400px]">
          <h2 class="text-3xl font-bold dark:text-white max-w-4xl">
            Unser Training begleitet Einarbeitung und Sprachkurse<a class="text-blue-600"
              >. Aber startet schon vorher</a
            >
          </h2>
          <!-- Mockups -->
          <p class="text-lg text-gray-600 dark:text-gray-400 mt-5 max-w-4xl text-justify">
            Auf Station im Pausenraum. Nach dem Sprachkurs im Feierabend. Vorab im Heimatland. Ihre Mitarbeiter:innen
            sammeln "hands on experience" im Umgang mit Patient:innen. In geschützter Umgebung und ihrem Tempo. Und
            schon vor ihrem ersten Tag in Deutschland und in Ihrer Klinik.
          </p>
          <div class="gap-x-48 -pb-[200px] pt-3 hidden md:grid grid-rows-2 place-content-center min-w-fit">
            <div
              class="row-start-1 p-1.5 max-h-[200px] mt-[200px] min-w-fit max-w-fit bg-gray-800 rounded-3xl shadow-[0_2.75rem_5.5rem_-3.5rem_rgb(45_55_75_/_20%),_0_2rem_4rem_-2rem_rgb(45_55_75_/_30%),_inset_0_-0.1875rem_0.3125rem_0_rgb(45_55_75_/_20%)] dark:bg-neutral-600 dark:shadow-[0_2.75rem_5.5rem_-3.5rem_rgb(0_0_0_/_20%),_0_2rem_4rem_-2rem_rgb(0_0_0_/_30%),_inset_0_-0.1875rem_0.3125rem_0_rgb(0_0_0_/_20%)]"
            >
              <img
                class="rounded-[1.25rem]"
                style="height: 100%"
                src="@/assets/images/landing/mobile.png"
                alt="casuu auf dem Smartphone"
              />
            </div>
            <div
              class="row-start-1 p-1.5 h-[400px] min-w-fit max-w-fit bg-gray-800 rounded-2xl shadow-[0_2.75rem_5.5rem_-3.5rem_rgb(45_55_75_/_20%),_0_2rem_4rem_-2rem_rgb(45_55_75_/_30%),_inset_0_-0.1875rem_0.3125rem_0_rgb(45_55_75_/_20%)] dark:bg-neutral-600 dark:shadow-[0_2.75rem_5.5rem_-3.5rem_rgb(0_0_0_/_20%),_0_2rem_4rem_-2rem_rgb(0_0_0_/_30%),_inset_0_-0.1875rem_0.3125rem_0_rgb(0_0_0_/_20%)]"
            >
              <img
                class="rounded-[0.75rem] h-full w-full object-cover object-right-top"
                src="@/assets/images/landing/desktop.png"
                alt="casuu auf dem PC"
              />
            </div>
            <div class="row-start-2 col-start-2 max-h-[5px] flex justify-center">
              <div
                class="bg-gray-800 min-w-[10px] shadow-[0_2.75rem_5.5rem_-3.5rem_rgb(45_55_75_/_20%),_0_2rem_4rem_-2rem_rgb(45_55_75_/_30%),_inset_0_-0.1875rem_0.3125rem_0_rgb(45_55_75_/_20%)] dark:bg-neutral-600 dark:shadow-[0_2.75rem_5.5rem_-3.5rem_rgb(0_0_0_/_20%),_0_2rem_4rem_-2rem_rgb(0_0_0_/_30%),_inset_0_-0.1875rem_0.3125rem_0_rgb(0_0_0_/_20%)]"
              ></div>
            </div>
          </div>
          <!-- End mockups -->
        </div>

        <div class="flex-col flex items-center text-center space-y-5 mt-24 lg:mt-32">
          <h2 class="text-3xl font-bold dark:text-white">
            Ihre Patient:innen<a class="text-blue-600">. Genau Ihre Patient:innen</a>
          </h2>
          <p class="text-lg text-gray-600 dark:text-gray-400 max-w-4xl text-justify">
            Medizin auf Deutsch - spielend gemeistert. Patient:innenfälle von Aufnahme und Übergabe bis Dokumentation.
            Vorgefertigt und bereit zum Üben.
            <a class="text-gray-600 hover:text-gray-400" href="/new-case"
              >Oder erstellen Sie genau die Fälle, mit den Ihre neuen Mitarbeiter:innen arbeiten werden. Von Krankheit
              über Prozedur bis Sorgen und Akzent. Eben <span class="italic">genau</span> Ihre Patient:innen.</a
            >
          </p>
        </div>
        <div class="max-w-xl md:max-w-4xl mx-auto">
          <PatientCarousel v-if="!isLoadingCases" :cases="cases" />
        </div>

        <div class="flex-col flex items-center text-center space-y-5 mt-24 lg:mt-32">
          <h2 class="text-3xl font-bold dark:text-white">
            Für Kliniken und Ausbilder<a class="text-blue-600">. Kurse von A bis Z</a>
          </h2>
          <p class="text-lg text-gray-600 dark:text-gray-400 max-w-4xl text-justify">
            Von A wie Aufnahmegespräch bis Z wie Zusammenarbeit im interprofessionellen Team: Unsere Kursmaterialien
            beginnen mit sprachlichen Grundlagen und führen bis zu inhaltlichen Standards verschiedener Routinen. Alle
            Inhalte üben ihre Mitarbeiter:innen direkt mit den virtuellen Patient:innen und Kolleg:innen. Natürlich
            decken wir auch Angehörigengespräche, Übergaben, Dokumentation und Vieles mehr ab.
          </p>
        </div>

        <div class="flex-col flex items-center text-center space-y-5 mt-24 lg:mt-32">
          <h2 class="text-3xl font-bold dark:text-white">
            Ihre Routinen. Ihre Dokumentationsbögen<a class="text-blue-600">. Ihre Trainings</a>
          </h2>
          <p class="text-lg text-gray-600 dark:text-gray-400 max-w-4xl text-justify">
            Nutzen Sie unsere Kurse als Vorlagen und gestalten Sie im Handumdrehen Ihre eigenen, spezifischen Trainings:
            Mit
            <span class="italic">genau</span> Ihren Dokumentationsbögen, Ihren prozeduralen Standards, Ihren klinischen
            Routinen.
          </p>
        </div>

        <div class="mx-auto text-center mt-24 lg:mt-32" v-show="!isLoadingCarousels">
          <!-- Content -->
          <div class="space-y-5 md:space-y-8">
            <div class="space-y-5">
              <h2 class="text-2xl font-bold md:text-3xl dark:text-white">
                Im <span class="text-blue-600">Focus</span>
              </h2>

              <div class="max-w-xl md:max-w-4xl mx-auto pt-3">
                <BlogpostCarousel ref="blogpostCarousel" @carouselMounted="onCarouselMounted" />
              </div>
            </div>
          </div>
        </div>

        <div class="mx-auto text-center items-center mt-24 lg:mt-32">
          <!-- Content -->
          <div class="space-y-5 md:space-y-8 items-center">
            <div class="space-y-5 items-center">
              <h2 class="text-2xl font-bold md:text-3xl dark:text-white">
                Von Vision zu Wirklichkeit<span class="text-blue-600">. Unsere Partner und Förderer</span>
              </h2>

              <p class="mx-auto text-lg text-gray-600 dark:text-gray-400 max-w-4xl text-center">
                Engagierte Unterstützung bei unserer Mission:<br /><a
                  class="text-gray-600 hover:text-gray-400"
                  href="/patient-advisory-board"
                  >Die beste Kommunikation zwischen Patient:innen und medizinischem Personal</a
                >
              </p>

              <div class="inline-flex overflow-x-auto gap-4 md:gap-8 pt-3 pb-10 lg:pb-14 items-center">
                <PartnerCarousel ref="partnerCarousel" @carouselMounted="onCarouselMounted" direction="rtl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PublicFooter />
  </div>
</template>
