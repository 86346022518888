<script setup>
import CourseHighlightCard from '@/components/CourseHighlightCard.vue';
import { router } from '@/router';
import { computed, nextTick, onMounted, ref } from 'vue';
import { HSStaticMethods, HSTooltip } from 'preline';

const props = defineProps({
  courses: {
    type: Array,
    default: () => [],
  },
  inlineOverflowingX: {
    type: Boolean,
    default: false,
  },
});

const imgs = ref([]);

onMounted(async () => {
  // load dummy course images
  for (let i = 0; i < 13; i++) {
    // console.log('Importing: ', `@/assets/images/courses/0${i}.png`);
    let num = i < 10 ? `0${i}` : i;
    import(`../assets/images/courses/${num}.png`).then((img) => {
      imgs.value.push(img.default);
    });
  }
  console.log('Loaded course highlight card images: ', imgs.value.length, ' images loaded.');
});
</script>

<template>
  <div
    class="min-h-full h-full max-h-full mx-auto overflow-visible"
    :class="{
      'flex max-w-[85rem] px-4 py-2 sm:px-6 lg:px-8 lg:py-2 ': !props.inlineOverflowingX,
      'inline-flex min-w-fit': props.inlineOverflowingX,
    }"
  >
    <!-- Grid -->
    <div
      class="min-h-full h-full max-h-full gap-6 overflow-visible"
      :class="{
        'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 min-w-full': !props.inlineOverflowingX,
        'inline-flex min-w-fit relative': props.inlineOverflowingX,
      }"
    >
      <div v-for="(course_, index) in courses" :class="{ 'w-[250px] md:w-[400px]': props.inlineOverflowingX }">
        <CourseHighlightCard
          :course="course_"
          :imgPath="!!course_.id ? (index < 13 ? imgs[index] : null) : null"
          @createNewCourse="$emit('createNewCourse')"
        />
      </div>
    </div>
    <!-- End Grid -->
  </div>
</template>

<style scoped></style>
