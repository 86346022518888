<script setup>
import { computed } from 'vue';

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: null,
  },
  color: {
    type: String,
    default: 'gray',
  },
  showDot: {
    type: Boolean,
    default: false,
  },
  pulse: {
    type: Boolean,
    default: false,
  },
  fontSize: {
    type: String,
    default: 'text-xs',
  },
  additionalClasses: {
    type: String,
    default: '',
  },
});

const stylingClasses = computed(() => [
  'bg-' + props.color + '-100',
  'text-' + props.color + '-800',
  'dark:bg-neutral-500/20',
  'dark:text-neutral-500',
  props.fontSize,
  props.additionalClasses,
]);

const dotColorClass = computed(() => {
  return ['bg-' + props.color + '-800', 'dark:bg-' + props.color + '-200', props.pulse ? 'animate-pulse' : ''];
});
</script>

<template>
  <span
    class="py-1 ps-1.5 pe-2.5 inline-flex select-none items-center gap-x-1.5 font-medium rounded-full overflow-hidden"
    :class="stylingClasses"
  >
    <span v-if="props.showDot" class="size-1.5 shrink-0 inline-block rounded-full" :class="dotColorClass"></span>
    <span
      v-if="props.icon"
      translate="no"
      class="material-symbols-outlined notranslate -my-1 text-center overflow-hidden"
      >{{ props.icon }}</span
    >
    {{ props.text }}
  </span>
</template>
