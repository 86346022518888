import { mergeAttributes, Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';

import ClozeInput from '../ClozeInput.vue';

export default Node.create({
  name: 'clozeInputSpan',

  group: 'inline',

  inline: true,

  draggable: true,

  // Allows marks to be applied
  marks: '_',

  addAttributes() {
    return {
      solutionText: {
        default: '',
      },
      hintText: {
        default: '',
      },
      attemptText: {
        default: '',
      },
    };
  },

  // parseHTML() {
  //   return [
  //     {
  //       tag: 'cloze-input-span',
  //     },
  //   ];
  // },

  parseHTML() {
    return [
      {
        tag: 'cloze-input-span',
        getAttrs: (node) => ({
          solutionText: node.getAttribute('solutionText') || '',
          hintText: node.getAttribute('hintText') || '',
        }),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['cloze-input-span', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return VueNodeViewRenderer(ClozeInput);
  },

  addCommands() {
    return {
      toggleClozeInputSpan:
        () =>
        ({ commands, editor }) => {
          const success = commands.tryRemoveEnclosingClozeInputSpan();
          if (success) {
            return true; // Successfully removed the node
          }
          // Nothing removed, so create

          // Wrap the selected text if no node was removed
          const { from, to } = editor.state.selection;
          const selectedText = editor.state.doc.textBetween(from, to, ' '); // Extract text
          return commands.insertContentAt(
            { from, to },
            {
              type: 'clozeInputSpan',
              attrs: {
                solutionText: selectedText,
                hintText: '',
              },
            },
          );
        },

      tryRemoveEnclosingClozeInputSpan:
        () =>
        ({ commands, state }) => {
          const { $from } = state.selection; // Get resolved position

          // Check if the current position's parent is an inline node and contains children
          const parent = $from.node($from.depth);
          if (!parent) return false;

          let pos = $from.start($from.depth); // Start position of the parent node

          // Iterate over child nodes of the parent
          for (let i = 0; i < parent.childCount; i++) {
            const child = parent.child(i); // Get child node
            const childStart = pos; // Start position of the child node
            const childEnd = childStart + child.nodeSize; // End position of the child node
            pos += child.nodeSize; // Update position for the next child

            // Check if the selection is inside the current child
            if (child.type.name === 'clozeInputSpan' && $from.pos >= childStart && $from.pos <= childEnd) {
              // Replace the clozeInputSpan node with its text content
              const textContent = child.attrs.solutionText || child.textContent || '';
              return commands.insertContentAt({ from: childStart, to: childEnd }, textContent);
            }
          }

          return false; // Not inside a clozeInputSpan
        },

      resetAllAttempts:
        () =>
        ({ state, dispatch }) => {
          const { tr } = state;

          state.doc.descendants((node, pos) => {
            if (node.type.name === 'clozeInputSpan') {
              tr.setNodeMarkup(pos, undefined, {
                ...node.attrs,
                attemptText: '', // Reset the attemptText
              });
            }
          });

          if (dispatch) dispatch(tr);
          return true;
        },
    };
  },
});
