<!-- DONE: Prio 1: Neuen Fall anlagen -->
<!-- TODO: Prio 2: EA ward round = zufälligen Fall wählen und starten -->

<script setup lang="ts">
import { router } from '@/router';
import DropDownListItem from '../DropDownListItem.vue';
import { useAlertStore, useAuthStore, useCaseStore } from '@/stores';
import { nextTick, ref } from 'vue';
import { storeToRefs } from 'pinia';

const authStore = useAuthStore();
const alertStore = useAlertStore();
const caseStore = useCaseStore();

const { allCases } = storeToRefs(caseStore);
const { dailyCasePlayed } = storeToRefs(authStore);

const emit = defineEmits(['closeDropdowns']);

async function createNewCase() {
  await router.push('/new-case');

  await router.go(0);
  // reload page - otherwise, this https://github.com/nuxt/nuxt/issues/13309 is fired when new case is hit after case
  // is started. Error is fired only in this case. Not when on home window without started case.
  // TODO: fix this and let the user return to the case window from the new case window thru the browser back button
}

async function toEmergencyAdmission() {
  await router.push('/wartebereich-zna');
  emit('closeDropdowns');
  await nextTick();
  await router.go(0);
}

async function onMyCasesClick() {
  await router.isReady();
  emit('closeDropdowns');
  await nextTick();
  await router.push('/users/' + authStore.userId + '/case-interactions');
}

async function playDailyCase() {
  if (allCases.value.length === 0) {
    await caseStore.fetchAllCases();
  }
  emit('closeDropdowns');
  await nextTick();
  const randomCase = allCases.value[Math.floor(Math.random() * allCases.value.length)];
  await router.push('/cases/' + randomCase.id);
}
</script>

<template>
  <div
    class="hs-dropdown-menu transition-[opacity,margin] duration-300 hs-dropdown-open:opacity-100 opacity-0 hidden z-10 top-full min-w-[15rem] bg-white md:shadow-2xl rounded-lg py-2 md:p-4 space-y-0.5 mt-2 dark:bg-neutral-800 dark:divide-gray-700 before:absolute before:-top-5 before:left-0 before:h-5"
  >
    <div class="md:grid grid-cols-1 gap-4">
      <div class="flex flex-col mx-1 md:mx-0">
        <DropDownListItem
          :header="$t('message.emergencyAdmissionCase')"
          :description="$t('message.emergencyAdmissionCaseDescription')"
          :onClick="toEmergencyAdmission"
          icon="ecg_heart"
        />

        <DropDownListItem
          :header="$t('message.myCases')"
          :description="$t('message.myCasesDescription')"
          :onClick="onMyCasesClick"
          icon="person"
        />

        <DropDownListItem
          :header="$t('message.caseDaily')"
          :description="$t('message.caseDailyDescription')"
          :onClick="playDailyCase"
          icon="timer_5"
          :markUnread="!dailyCasePlayed"
        />

        <DropDownListItem
          :header="$t('message.createNewCase')"
          :description="$t('message.createNewCaseDescription')"
          :onClick="createNewCase"
          icon="person_add"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
