import { Layout, AskUserDetails } from '@/views/onboarding';

export default {
  path: '/onboarding',
  component: Layout,
  meta: { forceNewRouterKey: 'onboarding-subs' },
  children: [
    {
      path: '',
      component: AskUserDetails,
    },
  ],
};
