<script>
import { storeToRefs } from 'pinia';

import { useCaseInteractionStore } from '@/stores/caseInteraction.store';
import { useAlertStore, usePatientInteractionStore } from '@/stores';
import { nextTick, onMounted, reactive, ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faDoorOpen,
  faDoorClosed,
  faPencil,
  faLightbulb,
  faThumbsUp,
  faRadiation,
  faNotesMedical,
  faVial,
} from '@fortawesome/free-solid-svg-icons';
import { HSTooltip, HSStaticMethods } from 'preline';

export default {
  components: { FontAwesomeIcon },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    reset: {
      // signals that the parent component wants to reset the transcription to await new input (e.g. submitted)
      default: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    placeholderMessage: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    labelAbbreviation: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'vial',
    },
  },
  setup(props) {
    const caseInteractionStore = useCaseInteractionStore();
    const alertStore = useAlertStore();
    const patientInteractionStore = usePatientInteractionStore();
    const {
      currentCaseInteractionLanguage,
      solved: isSolved,
      patientInteractionFinished,
    } = storeToRefs(caseInteractionStore);

    const inputText = ref('');
    const tooltipWrappingDiv = ref(null);

    const endingInteraction = ref(false);
    const openingReport = ref(false);
    const openingSolveModal = ref(false);

    const state = reactive({
      tooltip: null,
      tooltipFixed: false,
      tooltipOpen: false,
    });

    onMounted(async () => {
      // wait 200 ms
      await new Promise((resolve) => setTimeout(resolve, 200));
      HSTooltip.autoInit();
      await nextTick();

      console.log('main-id follows');
      console.log('main-id: ', props.id);

      state.tooltip = new HSTooltip(document.getElementById(`${props.id}-tooltip`));
      const tooltipWrappingDiv = document.getElementById(`${props.id}-tooltipWrappingDiv`);

      console.log('tooltip: ', state.tooltip);
      console.log('tooltipWrappingDiv: ', tooltipWrappingDiv);

      if (state.tooltip) {
        state.tooltip.on('show', (instance) => {
          console.log('show');
          state.tooltipOpen = true;
          tooltipWrappingDiv.focus();
        });
        state.tooltip.on('hide', (instance) => {
          state.tooltipOpen = false;
          console.log('hide');
        });
      } else {
        console.warn('no tooltip element found');
      }

      console.log('labOrder textarea is: ', tooltipWrappingDiv.value);
    });
    return {
      currentCaseInteractionLanguage,
      alertStore,
      caseInteractionStore,
      patientInteractionStore,
      inputText,
      tooltipWrappingDiv,
      state,
      endingInteraction,
      patientInteractionFinished,
      openingReport,
      openingSolveModal,
      isSolved,
    };
  },
  methods: {
    faThumbsUp() {
      return faThumbsUp;
    },
    faLightbulb() {
      return faLightbulb;
    },
    faPencil() {
      return faPencil;
    },
    getIcon() {
      if (this.icon === 'vial') {
        return faVial;
      } else if (this.icon === 'radiation') {
        return faRadiation;
      } else if (this.icon === 'notesMedical') {
        return faNotesMedical;
      } else {
        return faVial;
      }
    },
    faDoorClosed() {
      return faDoorClosed;
    },
    faDoorOpen() {
      return faDoorOpen;
    },
    async hideTooltip() {
      console.log('hideTooltip');
      if (this.state.tooltipFixed) {
        return;
      }
      await this.state.tooltip.hide();
    },
    async fixTooltip() {
      console.log('fixTooltip');
      this.state.tooltipFixed = true;
    },
    async releaseAndHideTooltip() {
      console.log('releaseAndHideTooptip');
      await this.releaseTooltip();
      await this.state.tooltip.hide();
    },
    async releaseTooltip() {
      console.log('releaseTooltip');
      this.state.tooltipFixed = false;
    },
    async openOrClose(event) {
      console.log('openOrClose');
      console.log('tooltipOpen: ', this.state.tooltipOpen);
      console.log('event: ', event);
      if (this.state.tooltipOpen) {
        console.log('openOrClose: close');
        await this.state.tooltip.hide();
        return;
      }
      console.log('openOrSubmit: open');
      if (this.disabled) {
        console.log('blocked: disabled');
        return;
      }
      await this.state.tooltip.show();
    },
    async onTranslateVocab() {
      await this.releaseAndHideTooltip();
      this.$emit('onTranslateVocab');
    },
    async onExplainVocab() {
      await this.releaseAndHideTooltip();
      this.$emit('onExplainVocab');
    },
  },
};
</script>

<template>
  <div
    class="inline-flex justify-center items-center mt-1 pt-1 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
    @focusout="hideTooltip"
  >
    <!-- Popover -->
    <div :id="`${id}-tooltip`" class="hs-tooltip inline-block [--trigger:none]">
      <button
        type="button"
        @click.prevent="openOrClose"
        class="flex-shrink-0 justify-center items-center h-12 w-12 md:h-16 md:w-16 lg:h-16 lg:w-20"
      >
        <div class="items-center bhover:scale-100">
          <!--                          <span class="hidden lg:block">-->
          <div translate="no" class="material-symbols-outlined notranslate text-4xl md:-mt-2">
            {{ icon }}
          </div>

          <div class="items-center hidden lg:block -mt-2 text-sm select-none">{{ label }}</div>
          <div class="items-center hidden md:block -mt-2 lg:hidden text-sm select-none">
            {{ !!labelAbbreviation ? labelAbbreviation : label }}
          </div>
        </div>
      </button>

      <div
        @mouseenter="fixTooltip"
        @mouseleave="releaseTooltip"
        @focusout="releaseAndHideTooltip"
        :id="`${id}-tooltipWrappingDiv`"
        :ref="tooltipWrappingDiv"
        role="tooltip"
        class="hs-tooltip-content z-40 flex-col justify-center hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400"
      >
        <!-- Patienten verlassen -->
        <div class="flex-col flex">
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-gray-800 rounded-lg border border-transparent disabled:opacity-50 disabled:pointer-events-none dark:text-blue-600 dark:hover:bg-blue-800/30 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            @mousedown.capture="onTranslateVocab"
          >
            <span translate="no" class="material-symbols-outlined notranslate"> dictionary </span>
            <div class="grow">
              <p class="font-medium text-gray-800 dark:text-gray-200">Fallbezogene Vokabeln übersetzen üben</p>
            </div>
          </button>
          <button
            type="button"
            class="py-3 px-4 inline-flex items-center gap-x-2 text-sm text-gray-600 hover:bg-gray-50 hover:text-gray-800 rounded-lg border border-transparent disabled:opacity-50 disabled:pointer-events-none dark:text-blue-600 dark:hover:bg-blue-800/30 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            @mousedown.capture="onExplainVocab"
          >
            <span translate="no" class="material-symbols-outlined notranslate"> sign_language </span>
            <div class="grow">
              <p class="font-medium text-gray-800 dark:text-gray-200">Fallbezogene Fachtermini erläutern üben</p>
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- End Popover -->
  </div>
</template>

<style></style>
