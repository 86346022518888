import { defineStore } from 'pinia';

export const useMobileInteractionsStore = defineStore({
  id: 'mobileInteractions',
  state: () => ({
    showMobileAudioInput: false,
    activeTextareaId: null as string | null,
  }),
  actions: {
    showAudioInput(textareaId: string) {
      // Close any existing audio input before opening new one
      this.showMobileAudioInput = false;
      setTimeout(() => {
        this.activeTextareaId = textareaId;
        this.showMobileAudioInput = true;
      }, 300); // Match transition duration
    },
    hideAudioInput() {
      this.showMobileAudioInput = false;
    },
  },
});
