<script setup lang="ts">
import { ref, onBeforeUnmount, watch, onMounted } from 'vue';
import { HSDropdown } from 'preline';

const props = defineProps({
  currentChapter: {
    type: Object,
    required: true,
  },
  currentChapterTitle: {
    type: String,
    required: true,
  },
  currentChapterTitleShortened: {
    type: String,
    required: true,
  },
  chapters: {
    type: Array,
    required: true,
  },
  idPrefix: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['goToChapter', 'dropdownOpen']);

const chapterDropdownMenu = ref<HTMLElement | null>(null);
const hasMoreToScroll = ref(false);
const someDropdownOpen = ref(false);
const chapterDropdown = ref(null);

const checkForMoreContent = () => {
  if (!chapterDropdownMenu.value) return;
  const { scrollHeight, clientHeight, scrollTop } = chapterDropdownMenu.value;
  hasMoreToScroll.value = scrollHeight - clientHeight - scrollTop > 10;
};

const scrollToBottom = () => {
  if (!chapterDropdownMenu.value) return;
  chapterDropdownMenu.value.scrollTo({
    top: chapterDropdownMenu.value.scrollHeight,
    behavior: 'smooth',
  });
};

watch(
  () => chapterDropdownMenu.value,
  async (el) => {
    if (el) {
      el.addEventListener('scroll', checkForMoreContent);
      await new Promise((resolve) => setTimeout(resolve, 100));
      checkForMoreContent();
    }
  },
);

onBeforeUnmount(() => {
  if (chapterDropdownMenu.value) {
    chapterDropdownMenu.value.removeEventListener('scroll', checkForMoreContent);
  }
});

const initializeDropdown = () => {
  const dropdownId = `${props.idPrefix}-chapters-dropdown`;
  let el = new HSDropdown(document.getElementById(dropdownId));
  chapterDropdown.value = el;
  if (el) {
    el.on('open', () => {
      console.log('open');
      someDropdownOpen.value = true;
      emit('dropdownOpen', true);
    });
    el.on('close', async () => {
      await new Promise((resolve) => setTimeout(resolve, 300));
      someDropdownOpen.value = false;
      emit('dropdownOpen', false);
    });
  }
};

// Initialize on mount and watch for changes
onMounted(async () => {
  await new Promise((resolve) => setTimeout(resolve, 200));
  initializeDropdown();
});
</script>

<template>
  <div
    class="hs-dropdown group [--strategy:absolute] [--adaptive:none] [--scope:window] md:[--trigger:click] [--placement:bottom-left] items-center text-xs md:text-sm z-[150]"
    :id="`${idPrefix}-chapters-dropdown`"
  >
    <button
      type="button"
      class="hs-dropdown-toggle z-40 py-1.5 px-2 inline-flex items-center gap-x-2 font-medium rounded-lg border border-gray-200 shadow-sm group-hover:text-blue-600 hs-dropdown-open:text-blue-600 group-hover:bg-gray-50 text-gray-500 focus:outline-none disabled:opacity-50 disabled:pointer-events-none dark:border-gray-700 dark:text-white dark:group-hover:bg-gray-800"
      :id="`${idPrefix}-chapters-dropdown-trigger`"
    >
      <span class="text-gray-300 text-semibold">
        {{ currentChapter.index + 1 }}
      </span>
      <span class="hidden md:block">
        {{ currentChapterTitle }}
      </span>
      <span class="block md:hidden">
        {{ currentChapterTitleShortened }}
      </span>
      <span
        translate="no"
        class="select-none material-symbols-outlined no-translate -mx-1 -my-1 text-lg"
        style="
          font-variation-settings:
            'wght' 700,
            'opsz' 96;
        "
      >
        more_vert
      </span>
    </button>

    <div
      class="hs-dropdown-menu absolute transition-[opacity, margin] duration-300 mt-12 overflow-visible hs-dropdown-open:mt-2 hs-dropdown-open:opacity-100 hs-dropdown-open:block min-w-[70vw] md:min-w-fit w-fit z-[150] opacity-0 duration-300"
      :aria-labelledby="`${idPrefix}-chapters-dropdown-trigger`"
    >
      <div
        class="shadow-md rounded-lg p-2 -ml-[100px] mr-[100px] md:ml-0 md:mr-0 bg-white mb-2 dark:bg-neutral-800 dark:border dark:border-gray-700 dark:divide-gray-700 overflow-hidden"
      >
        <div class="mt-1 flex-col flex z-[100] max-h-[500px] overflow-y-scroll" ref="chapterDropdownMenu">
          <div
            v-for="chapter in chapters"
            class="py-1.5 px-2 inline-flex items-start gap-x-2 z-[100] cursor-pointer rounded-lg hover:bg-gray-50"
            @click="emit('goToChapter', chapter.index)"
          >
            <span
              class="text-semibold"
              :class="{
                'text-blue-600/50': chapter.index === currentChapter.index,
                'text-gray-300': chapter.index !== currentChapter.index,
              }"
            >
              {{ chapter.index + 1 }}
            </span>
            <span :class="{ 'text-blue-600': chapter.index === currentChapter.index }">
              {{ chapter.title }}
            </span>
          </div>
        </div>

        <div
          v-show="hasMoreToScroll"
          class="absolute bottom-0 z-[110] -ml-[100px] mr-[100px] md:ml-0 md:mr-0 left-0 right-0 flex justify-center bg-gradient-to-t from-white rounded-lg via-white pb-1 pt-3 cursor-pointer"
          @click.stop.prevent="scrollToBottom"
          @mousedown.prevent
          @touchstart.prevent
          @touchmove.prevent
          @touchend.stop.prevent="scrollToBottom"
        >
          <span
            translate="no"
            class="no-translate select-none material-symbols-outlined text-gray-500 hover:text-gray-700"
          >
            expand_more
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
