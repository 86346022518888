<script setup lang="ts">
import { useCourseInteractionStore, useCourseStore } from '@/stores';
import { storeToRefs } from 'pinia';

const courseStore = useCourseStore();
const {
  currentCourse,
  currentCourseTitle,
  currentCourseDescription,
  currentCourseChapters,
  numberOfChapters,
  numberOfCases,
} = storeToRefs(courseStore);
const courseInteractionStore = useCourseInteractionStore();
const {
  isEnrolled,
  numChaptersCompleted,
  numSectionsPerChapter,
  numSectionsStartedPerChapter,
  numSectionsCompletedPerChapter,
  fetchingProgress,
} = storeToRefs(courseInteractionStore);

const props = defineProps({
  sortedChapters: {
    type: Array,
    required: true,
  },
  numberOfChapters: {
    type: Number,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  initialFetchCompleted: {
    type: Boolean,
    required: true,
  },
  chapterCompletedProgress: {
    type: Function,
    required: true,
  },
  chapterStartedProgress: {
    type: Function,
    required: true,
  },
  titlePerson: {
    type: Function,
    required: true,
  },
  titlePersonProfileImage: {
    type: Function,
    required: true,
  },
  titlePersonFirstName: {
    type: Function,
    required: true,
  },
  titlePersonLastName: {
    type: Function,
    required: true,
  },
  chapterIsCompleted: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(['onStartChapter']);
</script>

<template>
  <div class="flex-col flex h-full w-full overflow-x-auto gap-y-2">
    <div
      v-for="chapter in props.sortedChapters"
      class="group px-1.5 py-2 w-full rounded-xl shadow-sm border cursor-pointer"
      :class="{
        'bg-white hover:bg-gray-50 border-gray-200':
          !initialFetchCompleted || !props.chapterIsCompleted?.[chapter.index],
        'bg-teal-50 hover:bg-teal-100/50 border-teal-500':
          initialFetchCompleted && props.chapterIsCompleted?.[chapter.index],
      }"
      :key="chapter.id"
      @click="emit('onStartChapter', chapter.index)"
    >
      <div class="inline-flex items-start gap-x-2 w-full">
        <!-- first column -->
        <div class="font-bold text-gray-300 px-2 md:px-6 mt-[2px] text-xs">
          {{ chapter.index + 1 }}
        </div>

        <!-- middle column -->
        <div class="inline-flex gap-x-2 w-full justify-between">
          <div class="flex-col flex gap-y-2 w-full">
            <div
              class="block text-sm decoration-2 group-hover:underline dark:text-blue-600 cursor-pointer"
              :class="{
                'text-blue-600': !initialFetchCompleted || !props.chapterIsCompleted?.[chapter.index],
                'text-gray-800': initialFetchCompleted && props.chapterIsCompleted?.[chapter.index],
              }"
            >
              {{ chapter.title }}
            </div>

            <div
              class="row-start-1 col-start-1 grid w-full h-[3px] bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700"
            >
              <div
                class="row-start-1 col-start-1 flex flex-col justify-center overflow-hidden bg-blue-200 text-xs text-white text-center whitespace-nowrap rounded-[4px]"
                :style="'width: ' + props.chapterStartedProgress(chapter.index) + '%'"
                role="progressbar"
                :aria-valuenow="props.chapterStartedProgress(chapter.index)"
                aria-valuemin="0"
                aria-valuemax="100"
              />
              <div
                class="row-start-1 col-start-1 flex flex-col justify-center overflow-hidden text-xs text-white text-center whitespace-nowrap rounded-[4px]"
                :class="{
                  'bg-blue-600': !initialFetchCompleted || !props.chapterIsCompleted?.[chapter.index],
                  'bg-teal-600': initialFetchCompleted && props.chapterIsCompleted?.[chapter.index],
                }"
                :style="'width: ' + props.chapterCompletedProgress(chapter.index) + '%'"
                role="progressbar"
                :aria-valuenow="props.chapterCompletedProgress(chapter.index)"
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>

            <div
              class="text-sm"
              :class="{
                'text-gray-800': !initialFetchCompleted || !props.chapterIsCompleted?.[chapter.index],
                'text-gray-600': initialFetchCompleted && props.chapterIsCompleted?.[chapter.index],
              }"
            >
              {{ chapter.description }}
            </div>
          </div>

          <!-- Last Column 68 44-->
          <div
            class="relative flex flex-wrap gap-1"
            :class="{
              'w-[68px]': chapter.exemplary_cases.length >= 3,
              'w-[44px]': chapter.exemplary_cases.length == 2,
              'w-[24px]': chapter.exemplary_cases.length == 1,
              'w-[0px]': chapter.exemplary_cases.length == 0,
            }"
          >
            <div
              v-for="(case_, idx) in chapter.exemplary_cases"
              :key="idx"
              class="absolute h-6 w-6 rounded-full dark:ring-gray-800"
              :class="{
                'top-0 left-0': idx === 0,
                'top-0 left-[16px]': idx === 1,
                'top-0 left-[32px]': idx === 2,
                'top-[20px] left-[8px]': idx === 3,
                'top-[20px] left-[24px]': idx === 4,
              }"
            >
              <img
                v-if="!!props.titlePerson(case_)"
                :src="props.titlePersonProfileImage(case_)"
                alt="Image Description"
                class="h-full w-full rounded-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
