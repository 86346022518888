import { ERWaitingRoom } from '@/views/er-waiting-room';
import { LoggedInRoot } from '@/views/root';
import { Profile, UserDashboard } from '@/views/home';
import { CourseChapter, CourseSection, CourseDetails, CourseInteractionList, CourseLibrary } from '@/views/courses';
import { CreateNewCase } from '@/views/new-case';
import VocabDashboard from '@/components/didactics/VocabDashboard.vue';
import DailyVocabExercise from '@/components/didactics/vocab/DailyVocabExercise.vue';
import BarthelSKK from '@/components/customer_forms/BarthelSKK.vue';
import CategorizationExercise from '@/components/didactics/categorization/CategorizationExercise.vue';
import BarthelAsklepios from '@/components/customer_forms/BarthelAsklepios.vue';
import Cloze from '@/components/didactics/cloze/Cloze.vue';

export default {
  path: '/home',
  component: LoggedInRoot,
  children: [
    {
      path: '',
      component: UserDashboard,
      meta: { forceNewRouterKey: 'home' },
    },
    {
      path: '/profile',
      component: Profile,
      name: 'Profile',
      meta: { forceNewRouterKey: 'profile' },
    },
    {
      name: 'new-or-edit-case',
      path: '/new-case',
      meta: { forceNewRouterKey: 'new-case' },
      component: CreateNewCase,
      props: (route: {
        query: {
          isCopy: string;
          isEdit: string;
          isViewOnly: string;
          fromCaseId: string;
          inCaseListId: string;
        };
      }) => ({
        fromCaseId: route.query.fromCaseId,
        editCase: route.query.isEdit === 'true',
        copyCase: route.query.isCopy === 'true',
        viewOnly: route.query.isViewOnly === 'true',
        caseListId: route.query.inCaseListId,
      }),
    },
    // course library
    { path: '/courses', component: CourseLibrary, name: 'CourseLibrary', meta: { forceNewRouterKey: 'courses-subs' } },
    { path: '/course/:courseId', component: CourseDetails, meta: { forceNewRouterKey: 'courses-subs' } },
    { path: '/chapter/:chapterId', component: CourseChapter, meta: { forceNewRouterKey: 'courses-subs' } },
    { path: '/section/:sectionId', component: CourseSection, meta: { forceNewRouterKey: 'courses-subs' } },
    { path: '/my-courses', component: CourseInteractionList, meta: { forceNewRouterKey: 'courses-subs' } },
    // cases
    { path: '/wartebereich-zna', component: ERWaitingRoom, meta: { forceNewRouterKey: 'zna' } },
    // vocab
    {
      path: '/vocab-dashboard',
      component: VocabDashboard,
      name: 'VocabDashboard',
      meta: { forceNewRouterKey: 'VocabDashboard' },
      props: (route: {
        query: {
          startInPracticeMode: string;
          hideTabularNav: string;
        };
      }) => ({
        startInPracticeMode: route.query.startInPracticeMode === 'true',
        hideTabularNav: route.query.hideTabularNav === 'true',
      }),
    },
    // {
    //   path: '/dev',
    //   component: Test,
    // },
    // {
    //   path: '/dev2',
    //   component: Cloze,
    // },
  ],
};
