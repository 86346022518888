<!--TODO
TODO: Send input to DB
TODO: Buttons for examination (Sthethoskop-Icon?) und Blutabnahme (Nadel-Icon?) neben "Senden"
TODO v1: scene desc from AI. Patient chat w/ AI
- v1.b: patient avatar
TODO v2: try therapy. Get updated scene desc as feedback.
TODO v3: user input suggestions? (toggle on/off?)

-->

<script setup>
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import {
  useAttendingInteractionStore,
  useAuthStore,
  useCaseInteractionStore,
  usePatientInteractionStore,
} from '@/stores';

import { unobfuscateUserName } from '@/helper';
import { useThirdPersonInteractionStore } from '@/stores/thirdPersonInteraction.store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import AudioPlayerTTS from '@/components/AudioPlayerTTS.vue';
import InteractionInputToggleGroup from '@/components/inputs/InteractionInputToggleGroup.vue';

const authStore = useAuthStore();
const patientInteractionStore = usePatientInteractionStore();
const attendingInteractionStore = useAttendingInteractionStore();
const thirdPersonInteractionStore = useThirdPersonInteractionStore();
const caseInteractionStore = useCaseInteractionStore();
const { user, userFirstName, userLastName, textToSpeechEnabled } = storeToRefs(authStore);
const { chatMessages, chatIsStreaming, descIsStreaming } = storeToRefs(patientInteractionStore);
const {
  allPersons,
  selectedPerson,
  selectedPersonIndex,
  selectedPersonsLastChatMessage,
  selectedPersonsVoice,
  unselectedPersons,
  currentlyReadingChatMessageText,
  someChatIsStreaming,
} = storeToRefs(caseInteractionStore);

const lastChatMessage = computed(() => {
  return chatMessages.value[chatMessages.value.length - 1];
});

const playingAudio = ref(false);
const mountAudioPlayerTTS = ref(true);

const emit = defineEmits(['personSelected']);

const remountAudioPlayerTTS = () => {
  mountAudioPlayerTTS.value = false;
  nextTick(() => {
    mountAudioPlayerTTS.value = true;
  });
};

const props = defineProps({
  showSubtitles: {
    type: Boolean,
    default: true,
  },
  inputText: {
    type: String,
    default: '',
  },
  totalHeight: {
    type: String,
    default: '100%',
  },
});

onMounted(async () => {
  await nextTick();
  emit('personSelected', selectedPerson.value);
  console.log('Selected person by mount: ' + selectedPerson.value);
});

watch(
  () => props.inputText,
  async (inputText) => {
    console.debug('User input: ' + inputText + ' for selected person ' + selectedPerson.value);
    if (!selectedPerson.value) {
      console.log('No selected person');
      return;
    }
    if (selectedPerson.value.type === 'PATIENT') {
      console.log('Saying to patient');
      await patientInteractionStore.say(inputText);
    } else if (selectedPerson.value.type === 'ATTENDING') {
      console.log('Saying to attending');
      await attendingInteractionStore.say(inputText);
    } else if (selectedPerson.value.type === 'THIRD_PERSON') {
      console.log('Saying to third person');
      await thirdPersonInteractionStore.say(selectedPerson.value.thirdPersonIndex, inputText);
    } else {
      console.log('Unknown person type');
    }
  },
);

const currentlyReadingChatMessageTextWithTrailingSpecialCharacter = computed(() => {
  if (currentlyReadingChatMessageText.value === '') {
    return '';
  }
  let modifiedString = currentlyReadingChatMessageText.value.replace(/"/, '');

  // if testString ends on a special character or a special character and a white space, add this in the front
  // Regular expression to match a special character followed by optional white space at the end of the string
  const regex = /([\.,?!])\s*$/;

  // Test if the originalText ends with the special character(s) pattern
  const match = currentlyReadingChatMessageText.value.match(regex);
  if (match) {
    // Extract the special character
    const specialCharacter = match[1];

    // Remove the special character and whitespace from the end of the string
    modifiedString = modifiedString.slice(0, -match[0].length);

    // remove leading whitespace
    modifiedString = modifiedString.trim();

    // Prepend the special character to the beginning of the string
    return specialCharacter + modifiedString;
  } else {
    return modifiedString;
  }
});

function selectPerson(index) {
  if (!!playingAudio) {
    console.log('Cannot select person while playing audio');
    return;
  }
  currentlyReadingChatMessageText.value = '';
  caseInteractionStore.selectPerson(index);
}

const moreThanOnePerson = computed(() => {
  return allPersons.value.length > 1;
});

watch(
  () => selectedPersonIndex.value,
  (newIndex) => {
    console.log('New selected person: ', selectedPerson.value, ' at index ', newIndex);
    emit('personSelected', selectedPerson.value);
  },
  { immediate: true },
);

const VISIBLE_WORD_WINDOW = 5; // Show more words at once

const visibleSubtitleWords = computed(() => {
  const store = useCaseInteractionStore();
  const currentIndex = store.currentWordIndex;
  if (currentIndex === -1) return [];

  // Calculate window start - show words in groups
  const windowStart = Math.floor(currentIndex / VISIBLE_WORD_WINDOW) * VISIBLE_WORD_WINDOW;
  return store.subtitleWords.slice(windowStart, windowStart + VISIBLE_WORD_WINDOW);
});

const relativeCurrentWordIndex = computed(() => {
  const store = useCaseInteractionStore();
  const currentIndex = store.currentWordIndex;
  if (currentIndex === -1) return -1;

  // Calculate relative index within current window
  return currentIndex % VISIBLE_WORD_WINDOW;
});

const handleWordChange = (index) => {
  const store = useCaseInteractionStore();
  store.setCurrentWordIndex(index);
};
</script>

<template>
  <div
    class="grid grid-cols-10 grid-rows-10 overflow-hidden"
    :style="{
      height: props.totalHeight,
    }"
  >
    <div
      v-show="moreThanOnePerson"
      class="col-start-1 col-span-10 md:col-span-8 2xl:col-span-7 row-start-1 row-span-2 border border-gray-200 rounded-xl mx-6 my-1 gap-x-2 overflow-hidden min-h-[12vH] max-h-[12vH] inline-grid grid-rows-1"
    >
      <!-- TODO: make this container full height of parent, cave viewport on mobile -->
      <div
        class="row-start-1"
        style="width: 100%; height: 100%; position: relative"
        v-for="person in unselectedPersons"
      >
        <span
          v-show="person.involvement === 'ON_THE_PHONE'"
          class="absolute top-4 end-14 z-10 sm:end-14 md:end-16 lg:end-20 xl:end-26 2xl:end-32 inline-flex items-center py-2 px-2 rounded-xl text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white"
        >
          <FontAwesomeIcon :icon="faPhone" size="xl" key="notIsLargeScreen" />
        </span>
        <img
          @click="selectPerson(person.index)"
          v-if="!!person.profileImageLarge"
          class="absolute rounded-xl top-0 inset-x-0 transition-transform duration-500 ease-in-out object-cover"
          :class="!!playingAudio ? 'opacity-30' : 'hover:scale-125'"
          :src="person.profileImageLarge"
          alt="person profile image"
          style="width: 100%; height: 100%; object-fit: contain"
        />
      </div>
    </div>
    <div
      class="flex w-full min-w-full col-start-1 col-span-10 2xl:col-start-1 2xl:col-span-8 px-6 rounded-xl"
      :class="[
        {
          'row-start-2 md:row-start-3 row-span-8 md:row-span-7': moreThanOnePerson,
          'row-start-1 md:row-start-2 row-span-9 md:row-span-8': !moreThanOnePerson,
        },
        'items-center justify-center md:mt-0 ',
      ]"
    >
      <!--            <ProfileImage-->
      <!--              :image="patientInteractionStore.patientProfileImageLarge"-->
      <!--              :initials="patientInteractionStore.patientInitials"-->
      <!--              size="3rem"-->
      <!--            />-->
      <div class="flex w-full rounded-xl justify-center" :class="{ 'mt-12': allPersons.length === 1 }">
        <div class="flex w-full justify-center">
          <span
            v-if="!!selectedPerson"
            v-show="selectedPerson.involvement === 'ON_THE_PHONE'"
            class="absolute top-4 end-4 md:top-8 md:end-16 lg:end-32 2xl:end-48 z-10 inline-flex items-center py-2 px-2 rounded-xl text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white"
          >
            <span class="hidden lg:block">
              <FontAwesomeIcon :icon="faPhone" size="3x" key="isLargeScreen" />
            </span>
            <span class="block lg:hidden">
              <FontAwesomeIcon :icon="faPhone" size="xl" key="notIsLargeScreen" />
            </span>
          </span>

          <!--        Playing audio: {{playingAudio}}.-->
          <!--        {{ !!playingAudio ? '125' : '100' }}-->
          <!--        <img-->
          <!--            v-if="!!selectedPerson"-->
          <!--            class="absolute rounded-xl top-0 left-1/2 -translate-x-1/2 transition-transform duration-500 ease-in-out object-cover"-->
          <!--            :class="'scale-' + (!!playingAudio ? '125' : '100')"-->
          <!--            :src="selectedPerson.profileImageLarge"-->
          <!--            alt="patient profile image"-->
          <!--            style="width: auto; height: auto"-->
          <!--        />-->
          <div class="flex-col flex">
            <div class="top-0 z-[11] pb-2 w-full justify-start">
              <InteractionInputToggleGroup
                :showChatInputToggle="false"
                :showSubtitlesToggle="false"
                :showLanguageLevelSelect="true"
                :bgTransparent="true"
                :showMinimalVersionWhenSmallScreen="false"
              />
            </div>
            <img
              v-show="!!playingAudio"
              v-if="!!selectedPerson"
              class="scale-105 z-10 rounded-xl top-0 left-1/2 transition-transform duration-500 ease-in-out object-cover"
              :src="selectedPerson.profileImageLarge"
              alt="patient profile image"
            />
            <img
              v-show="!playingAudio"
              v-if="!!selectedPerson"
              class="rounded-xl z-0 top-0 left-1/2 transition-transform duration-500 ease-in-out object-cover"
              :src="selectedPerson.profileImageLarge"
              alt="patient profile image"
            />
          </div>
        </div>
      </div>
      <!-- hover:scale-105 -->
    </div>

    <div
      class="z-10 relative col-start-1 col-span-10 2xl:col-start-1 2xl:col-span-8 w-full md:w-2/3 lg:w-1/2 mx-auto pb-4 -mt-4 row-span-1 flex items-start justify-center transition-all duration-300"
      :class="[{ 'row-end-10': moreThanOnePerson, 'row-end-10': !moreThanOnePerson }, '']"
      :style="{
        opacity: props.showSubtitles ? '1' : '0',
      }"
    >
      <div
        v-if="textToSpeechEnabled"
        class="flex text-white text-2xl px-2 min-w-full w-full h-14 min-h-14 items-center md:rounded-lg"
        :class="caseInteractionStore.isReplaying ? 'bg-gray-500/50 blurred-backdrop-behind' : 'bg-gray-500/20'"
      >
        <div class="overflow-hidden text-center w-full">
          <TransitionGroup name="subtitle-words" tag="div" class="flex flex-wrap justify-center gap-x-1">
            <div
              :key="'word-group-' + Math.floor(caseInteractionStore.currentWordIndex / VISIBLE_WORD_WINDOW)"
              class="flex flex-wrap justify-center gap-x-1"
            >
              <span
                v-for="(word, index) in visibleSubtitleWords"
                :key="word.startTime"
                class="inline-block transition-all duration-200 rounded-lg"
                :class="{
                  'text-blue-500 scale-125 font-semibold mx-2': index === relativeCurrentWordIndex,
                  'text-white': index !== relativeCurrentWordIndex,
                }"
              >
                {{ word.word }}
              </span>
            </div>
          </TransitionGroup>
        </div>

        <AudioPlayerTTS
          v-if="!!selectedPersonsLastChatMessage && mountAudioPlayerTTS"
          :message="
            unobfuscateUserName(
              selectedPersonsLastChatMessage.content['processed_model_output'],
              userFirstName,
              userLastName,
            )
          "
          :messageIsComplete="!someChatIsStreaming"
          :messageId="selectedPersonsLastChatMessage.id"
          :voice="selectedPersonsVoice"
          @playingAudio="playingAudio = $event"
          @playbackFinished="remountAudioPlayerTTS"
          @wordChange="handleWordChange"
        />
      </div>
      <div v-else class="overflow-y-auto flex-col-reverse">
        <div class="translate-z-0">
          <div class="text-center text-md text-gray-800">
            {{
              !!lastChatMessage
                ? unobfuscateUserName(lastChatMessage.content['processed_model_output'], userFirstName, userLastName)
                : ''
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.subtitle-words-move {
  transition: all 0.3s ease;
}
.subtitle-words-enter-active,
.subtitle-words-leave-active {
  transition: all 0.3s ease;
}
.subtitle-words-enter-from,
.subtitle-words-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

@media (max-width: 768px) {
  .blurred-backdrop-behind {
    backdrop-filter: blur(8px);
  }
}
</style>
