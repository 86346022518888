<script setup lang="ts">
import { computed, ref } from 'vue';
import { useAlertStore, useAuthStore, useCourseInteractionStore } from '@/stores';
import { storeToRefs } from 'pinia';
import { nextTick, onMounted, watch } from 'vue';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
  contentItemId: {
    type: String,
    required: true,
  },
  sectionIndex: {
    type: Number,
    required: true,
  },
});

const courseInteractionStore = useCourseInteractionStore();
const alertStore = useAlertStore();
const authStore = useAuthStore();
const { fetchCompletedAndChapterSet: courseInteractionFetchCompleted } = storeToRefs(courseInteractionStore);
const itemInteractionState = ref({});
const fetchCompleted = ref(false);

const textCompleted = computed(() => {
  return itemInteractionState.value?.completed_at != null;
});

const markComplete = async () => {
  courseInteractionStore
    .setSectionContentItemCompletedIfNotAlready(props.sectionIndex, props.contentItemId)
    .then(async (response) => {
      if (!response) return;
      console.log(response);
      response.notifications?.forEach((notification) => {
        alertStore.xp(t(notification.message), t('message.receivedXP', notification.xp));
      });
      itemInteractionState.value = response.section_content_item_interaction;
      await authStore.fetchUserXp();
    });
};

onMounted(async () => {
  await fetchInteractionState();
});

watch(
  () => courseInteractionFetchCompleted.value,
  async () => {
    await nextTick();
    await fetchInteractionState();
  },
  { immediate: true },
);

const fetchInteractionState = async () => {
  let interactionState = await courseInteractionStore.getSectionContentItemInteractionState(
    props.sectionIndex,
    props.contentItemId,
  );
  // create local copy - we want to defer updates to wait for animations etc
  console.log(interactionState);
  if (!!interactionState && interactionState !== {}) {
    fetchCompleted.value = true;
    itemInteractionState.value = { ...interactionState };
    console.log(itemInteractionState.value);
  }
};
</script>

<template>
  <button
    v-if="fetchCompleted"
    type="button"
    class="group select-none py-2 px-3 text-xs md:text-sm inline-flex items-center gap-x-0.5 md:gap-x-2 font-medium rounded-lg shadow-sm disabled:opacity-50 disabled:pointer-events-none dark:text-white"
    @click.capture="markComplete"
    :class="{
      'bg-blue-600 cursor-pointer text-white hover:bg-blue-700 dark:border-gray-700 dark:bg-neutral-900 dark:hover:bg-gray-800':
        !textCompleted,
      'text-teal-500 bg-teal-200/10 border-gray-200 border-2 cursor-default dark:border-teal-700 dark:bg-teal-900 ':
        textCompleted,
    }"
  >
    <span v-show="!textCompleted" class="material-symbols-outlined block group-hover:hidden"> circle </span>
    <span v-show="!textCompleted" class="material-symbols-outlined hidden group-hover:block"> task_alt </span>
    <span v-show="textCompleted" translate="no" class="material-symbols-outlined notranslate"> task_alt </span>
    {{ textCompleted ? 'Gelesen' : 'Gelesen' }}
  </button>
</template>

<style scoped></style>
