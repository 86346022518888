<script>
import { ref } from 'vue';

export default {
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    reset: {
      // signals that the parent component wants to reset the transcription to await new input (e.g. submitted)
      default: false,
      type: Boolean,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    placeholderMessage: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    showIcon: {
      type: String,
      default: 'chevronRight',
    },
    beforeIcon: {
      type: String,
      default: '',
    },
    autoShowTarget: {
      type: Boolean,
      default: false,
    },
    autoCloseTarget: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const targetIsOpen = ref(false);

    return {
      targetIsOpen,
    };
  },
  methods: {
    async toggleTarget() {
      if (this.disabled) {
        return;
      }
      this.targetIsOpen = !this.targetIsOpen;
      this.$emit('showTarget', this.targetIsOpen);
    },
    async forceShowTarget() {
      this.$emit('showTarget', this.targetIsOpen);
    },
    async forceCloseTarget() {
      this.targetIsOpen = false;
      this.$emit('showTarget', this.targetIsOpen);
    },
  },
  // if auto show changes from false to true, show. Same for auto close.
  watch: {
    autoShowTarget: {
      immediate: true,
      handler(value) {
        this.forceShowTarget();
      },
    },
    autoCloseTarget: {
      immediate: true,
      handler(value) {
        this.forceCloseTarget();
      },
    },
  },
};
</script>

<template>
  <div
    class="inline-flex justify-center z-50 items-center mt-1 pt-1 -ms-px rounded-s-lg md:rounded-none md:first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
  >
    <!-- Popover -->
    <div :id="`${id}-tooltip`" class="hs-tooltip inline-block [--trigger:none]">
      <button
        type="button"
        @click.prevent="toggleTarget"
        class="hs-tooltip-toggle flex-shrink-0 justify-center items-center h-12 w-12 md:h-16 md:w-16 lg:h-16 lg:w-20"
      >
        <div class="items-center hover:scale-100">
          <div class="inline-flex items-center">
            <div
              v-if="!!beforeIcon"
              translate="no"
              class="material-symbols-outlined notranslate ml-1 -mr-3 text-3xl md:-mt-2 transition-all transform duration-300 ease-in-out"
            >
              {{ beforeIcon }}
            </div>
            <div
              translate="no"
              class="material-symbols-outlined notranslate text-4xl md:-mt-2 transition-all transform duration-300 ease-in-out"
              :class="{
                '-rotate-180': targetIsOpen && showIcon === 'chevron_left',
                'rotate-180': targetIsOpen && showIcon !== 'chevron_left',
                'rotate-0': !targetIsOpen,
              }"
            >
              {{ showIcon }}
            </div>
          </div>
          <div class="items-center -mt-2 hidden md:block text-sm">{{ label }}</div>
        </div>
      </button>
    </div>
    <!-- End Popover -->
  </div>
</template>

<style></style>
