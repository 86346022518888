/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormStructuredExerciseFeedbackCreate } from '../models/FormStructuredExerciseFeedbackCreate';
import type { FormStructuredExerciseFeedbackResponse } from '../models/FormStructuredExerciseFeedbackResponse';
import type { McQuestionAttempt } from '../models/McQuestionAttempt';
import type { McQuestionFeedbackResponse } from '../models/McQuestionFeedbackResponse';
import type { OpenQuestionAttempt } from '../models/OpenQuestionAttempt';
import type { OpenQuestionFeedbackResponse } from '../models/OpenQuestionFeedbackResponse';
import type { SectionContentItemInteractionUpdate } from '../models/SectionContentItemInteractionUpdate';
import type { UpdateSectionContentItemInteractionResponse } from '../models/UpdateSectionContentItemInteractionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SectionItemInteractionsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Update Section Content Item Interaction
   * @param itemInteractionId
   * @param requestBody
   * @returns UpdateSectionContentItemInteractionResponse Successful Response
   * @throws ApiError
   */
  public updateSectionContentItemInteraction(
    itemInteractionId: string,
    requestBody: SectionContentItemInteractionUpdate,
  ): CancelablePromise<UpdateSectionContentItemInteractionResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/section-item-interactions/item_interaction_id}',
      query: {
        item_interaction_id: itemInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Form Structured Exercise Item
   * @param itemInteractionId
   * @param requestBody
   * @returns FormStructuredExerciseFeedbackResponse Successful Response
   * @throws ApiError
   */
  public evaluateFormStructuredExerciseItem(
    itemInteractionId: string,
    requestBody: FormStructuredExerciseFeedbackCreate,
  ): CancelablePromise<FormStructuredExerciseFeedbackResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/section-item-interactions/form-structured-exercise/{item_interaction_id}/evaluate',
      path: {
        item_interaction_id: itemInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Mc Question
   * @param itemInteractionId
   * @param requestBody
   * @returns McQuestionFeedbackResponse Successful Response
   * @throws ApiError
   */
  public evaluateMcQuestion(
    itemInteractionId: string,
    requestBody: McQuestionAttempt,
  ): CancelablePromise<McQuestionFeedbackResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/section-item-interactions/resource-mc/{item_interaction_id}/evaluate',
      path: {
        item_interaction_id: itemInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Evaluate Open Question
   * @param itemInteractionId
   * @param requestBody
   * @returns OpenQuestionFeedbackResponse Successful Response
   * @throws ApiError
   */
  public evaluateOpenQuestion(
    itemInteractionId: string,
    requestBody: OpenQuestionAttempt,
  ): CancelablePromise<OpenQuestionFeedbackResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/section-item-interactions/resource-open/{item_interaction_id}/evaluate',
      path: {
        item_interaction_id: itemInteractionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
